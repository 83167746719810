const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = "https://webtest.gunzillagames.com/api";

const vacanciesUrl = `${API_BASE_URL}/job-descriptions`;
const leadershipUrl = `${API_BASE_URL}/leaderships`;
const homePageUrl = `${API_BASE_URL}/home-page`;
const newsUrl = `${API_BASE_URL}/articles`;
const gamesUrl = `${API_BASE_URL}/games`;
const contactUsUrl = `${API_BASE_URL}/contact-us-page`;
const aboutGalleryUrl = `${API_BASE_URL}/about-gallery`;
const legalPageUrl = `${API_BASE_URL}/legal-page`;
const privacyPageUrl = `${API_BASE_URL}/privacy-page`;
const cookieyPageUrl = `${API_BASE_URL}/cookie-policy`;
const locationsUrl = `${API_BASE_URL}/locations`;
const rolesUrl = `${API_BASE_URL}/jd-roles`;
const timelineUrl = `${API_BASE_URL}/timeline-page`;
const timelineNewUrl = `${API_BASE_URL}/timeline-new-page`;
const careersPageUrl = `${API_BASE_URL}/careers-page`;
const newsPageUrl = `${API_BASE_URL}/news-page`;
const internshipPageUrl = `${API_BASE_URL}/internship-page`;

export function fetchVacancies() {
  return fetch(vacanciesUrl + "?_limit=1000")
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchLeadership() {
  return fetch(leadershipUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchHomePage() {
  return fetch(homePageUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchNews() {
  return fetch(newsUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchGames() {
  return fetch(gamesUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchContactUsPage() {
  return fetch(contactUsUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchAboutGallery() {
  return fetch(aboutGalleryUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchLegalPage() {
  return fetch(legalPageUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchPrivacyPage() {
  return fetch(privacyPageUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchCookiePage() {
  return fetch(cookieyPageUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchRoles() {
  return fetch(rolesUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchLocations() {
  return fetch(locationsUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchTimeline() {
  return fetch(timelineUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchNewTimeline() {
  return fetch(timelineNewUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchCareersPage() {
  return fetch(careersPageUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchNewsPage() {
  return fetch(newsPageUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}

export function fetchInternshipPage() {
  return fetch(internshipPageUrl)
    .then((resp) => resp.json())
    .catch((e) => e); // error handling - 80 lvl
}
