import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import localization from '../../localization';
import whiteGameLogo from '../../assets/images/otg-cinema-slide-content/logo-white.png';
import './OtgCinemaHomeSlide.css';

export const OtgCinemaHomeSlide = ({ lang }) => {
  return (
    <div className="otg-cinema-slide">
      <div className="red-bg-overlay-holder" />
      <div className="red-bg-overlay-lining-holder" />
      <div className="bg-image-holder" />
      <div className="content">
        <div className="flex-wrapper">
          <img src={whiteGameLogo} className="black-logo" />
          <div className={`title ${lang === 'ua' ? 'ua' : ''}`}>
            <span>SWITCHER</span>
            {/* <span>{localization[lang].OTG_CINEMA_HOME_SLIDE_OUT_NOW}</span> */}
          </div>
        </div>

        <Link to={`/${lang}/cinema/`} className="redirection-button-wrap-link">
          <div className="inner-border-1" />
          <div className="inner-border-2" />
          <div className="inner-border-3" />
          <div className="inner-border-4" />

          <div className="redirection-button">
            {localization[lang].OTG_CINEMA_HOME_SLIDE_SEE_MORE}
          </div>
        </Link>
      </div>
    </div>
  );
};
