import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import store from '../shared/store';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Leadership from '../components/Leadership';
import * as actions from '../shared/actions';

class LeadershipPage extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    store.dispatch(actions.fetchLeadership());
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <Leadership isAllEmployees />
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LeadershipPage);
