import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Header2 from '../components/Header2';
import Footer from '../components/Footer';
import Fade from 'react-reveal/Fade';
import ReactMarkdown from 'react-markdown';
import bg from '../assets/images/bg_about_game@2x.png';
import { translation } from '../tools';
import ScrollAnimation from 'react-animate-on-scroll';
import MetaTags from 'react-meta-tags';
import * as actions from "../shared/actions";
import store from "../shared/store";
import { OTGPreviewSection } from "../components/OTGPreviewSection";
import { Loader } from "../components/Loader";
import localization from '../localization';

class GamesPage extends Component {

    state = {
        deviceWidth: window.innerWidth,
        isPreviewSectionLeftSideContentFadeIn: false,
        isAboutSectionRightSideContentFadeIn: false,
        gameAboutSectionHeight: undefined,

        gamePreviewSectionBackgroundVerticalOffset: 0,
        gamePreviewSectionContentVerticalOffset: 0,

        gameAboutSectionImageVerticalOffset: 0,
        gameAboutSectionRightSideVerticalContentOffset: 0,
    };

    // METHODS THAT THROWS LIKE FUNCTIONS-LISTENERS IN eventLister FOR REMOVE LISTENERS ON UNMOUNT
    aboutSectionRightSideContentFadeIn = () => {
        if (window.scrollY > this.state.gameAboutSectionHeight / 3) {
            window.removeEventListener('scroll', this.aboutSectionRightSideContentFadeIn);
            this.setState({
                ...this.state,
                isAboutSectionRightSideContentFadeIn: true,
            })
        }
    };

    desktopParallaxHandle = () => {
            this.setState({
                ...this.state,
                gamePreviewSectionContentVerticalOffset:  - (window.scrollY / 60),

                gameAboutSectionImageVerticalOffset:  7 - (window.scrollY / 100),
                gameAboutSectionRightSideVerticalContentOffset: (window.scrollY / 50) - 15,
            })
    };

    allDevicesParalaxHandle = () => {
        this.setState({
            ...this.state,
            gamePreviewSectionBackgroundVerticalOffset: (window.scrollY / 1.75),
        })
    }

    resizeHandle = () => {
        if (this.state.deviceWidth > 992) window.addEventListener('scroll', this.desktopParallaxHandle);
        else window.removeEventListener('scroll', this.desktopParallaxHandle);

        this.setState({
            ...this.state,
            deviceWidth: window.innerWidth,
        })
    };

    //MOUNT AND UNMOUT LOGIC
    componentDidMount() {
        store.dispatch(actions.fetchGames());

        window.addEventListener('scroll', this.aboutSectionRightSideContentFadeIn);

        window.addEventListener('scroll', this.allDevicesParalaxHandle);
        if (this.state.deviceWidth > 992) window.addEventListener('scroll', this.desktopParallaxHandle);

        window.addEventListener('resize', this.resizeHandle);

        this.setState({...this.state, gameAboutSectionHeight: document.querySelector('.game-about-section').offsetHeight})
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.aboutSectionRightSideContentFadeIn);

        window.removeEventListener('scroll', this.allDevicesParalaxHandle);
        window.removeEventListener('scroll', this.desktopParallaxHandle);

        window.removeEventListener('scroll', this.resizeHandle);
    };

    render() {
        const { games, lang, fontsLoadingCheck, isCinemaType } = this.props

        //after fontsLoadingCheck(spinner disable) we start the fade in animation in first section.
        //also check that we already rewrite 'isPreviewSectionLeftSideContentFadeIn' key for infinity
        // rerender preventing
        if (fontsLoadingCheck && !this.state.isPreviewSectionLeftSideContentFadeIn) {
            this.setState({...this.state, isPreviewSectionLeftSideContentFadeIn: true});
        }

        return (
            <React.Fragment>
                <Header />
                <MetaTags>
                {games.meta_title ? 
                    <>

                        <title>{'Off The Grid | Gunzilla Games'}</title>
                        {/*<title>{translation(games, "meta_title", lang)}</title>*/}
                        <meta id="meta-title" name="title" content={translation(games, "meta_title", lang)} />
                        <meta id="og-title" property="og:title" content={translation(games, "meta_title", lang)} />
                        <meta id="twitter-title" property={translation(games, "meta_title", lang)} /> 
                    </>
                    :
                    <>
                        <title>{translation(games, "title", lang)}</title>
                        <meta id="meta-title" name="title" content={translation(games, "title", lang)} />
                        <meta id="og-title" property="og:title" content={translation(games, "title", lang)} />
                        <meta id="twitter-title" property={translation(games, "title", lang)} /> 
                    </>
                }
                {games.meta_description ?
                    <>
                        <meta id="meta-description" name="description" content={translation(games, "meta_description", lang)} />
                        <meta id="og-description" property="og:description" content={translation(games, "meta_description", lang)} /> 
                        <meta id="twitter-description" property={translation(games, "meta_description", lang)} /> 
                    </>
                    :
                    <>
                        <meta id="meta-description" name="description" content={translation(games, "title", lang)} />
                        <meta id="og-description" property="og:description" content={translation(games, "title", lang)} /> 
                        <meta id="twitter-description" property={translation(games, "title", lang)} /> 
                    </>
                }
                <link rel="canonical" />
                <meta id="og-url" property="og:url" content={window.location.href} />
                <meta id="twitter-url" property="twitter:url" content={window.location.href} />
                </MetaTags>
                 {/*<Header2 />*/}
                <Loader loaded={fontsLoadingCheck} />
                {/*---OLD LAYOUT DISABLING START---*/}
                {/*<React.Fragment>*/}
                {/*    <section className="our-game">*/}
                {/*        <div className="container">*/}
                {/*            <div className="row">*/}
                {/*                <div className="col-md-12">*/}
                {/*                    <h1>{translation(this.props.games, "title", this.props.lang)}</h1>*/}
                {/*                    <ScrollAnimation animateIn='animate__fadeInUp' animateOut='animate__fadeOutDown' animateOnce={true} >*/}
                {/*                        <ReactMarkdown*/}
                {/*                            className={`text`}*/}
                {/*                            source={translation(this.props.games, "description", this.props.lang)}*/}
                {/*                            escapeHtml={false}*/}
                {/*                        />*/}
                {/*                    </ScrollAnimation>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </section>*/}
                {/*    <section className="our-game-bg">*/}
                {/*        <div className="container-fluid">*/}
                {/*            <div className="row">*/}
                {/*                <div className="col-md-12">*/}
                {/*                    <img className="image-responsive" src={bg} />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </section>*/}
                {/*</React.Fragment>*/}
                {/*---OLD LAYOUT DISABLING END---*/}
                <OTGPreviewSection
                    forwardedClass={!isCinemaType ?'inOTGPage' : 'cinemaType'}
                    redirectionMode='outer'
                    backgroundTranslateValue={this.state.gamePreviewSectionBackgroundVerticalOffset}
                    contentTranslateValue={this.state.gamePreviewSectionContentVerticalOffset}
                    isContentFadeIn={this.state.isPreviewSectionLeftSideContentFadeIn}
                    isCinemaType={isCinemaType}
                    lang={lang}
                />
                <div className='game-about-section'>
                    <div className='content'>
                        <div
                            style={{transform: `translateY(${this.state.gameAboutSectionImageVerticalOffset}%)`}}
                            className={`about-image ${isCinemaType ? 'cinemaType' : ''}`}
                        />
                        <div
                            style={
                                this.state.deviceWidth > 992
                                    ? {transform: `translateY(calc(-50% + ${this.state.gameAboutSectionRightSideVerticalContentOffset}%))`}
                                    : {transform: 'initial'}
                            }
                            className='about-description-size-holder'
                        >
                            <div
                                className={`title about-description-text ${(this.state.isAboutSectionRightSideContentFadeIn && fontsLoadingCheck) ? 'fadeIn' : null} ${isCinemaType ? 'cinemaType' : ''}`}
                            >
                                { !isCinemaType 
                                     ? localization[lang].OTG_HEADING
                                     : <div>
                                          <div className={`title-top-part ${lang === 'ua' ? 'ua' : ''}`}>{ localization[lang].OTG_CINEMA_TITLE_FIRST_PART }</div>
                                          Off The Grid Cinema
                                       </div>
                                }
                            </div>
                            <div
                                className={`about-description-text ${(this.state.isAboutSectionRightSideContentFadeIn && fontsLoadingCheck) ? 'fadeIn' : null}`}
                            >
                                { !isCinemaType ? <>
                                <p>{localization[lang].OTG_DESCRIPTION_1 }</p>
                                <p>{localization[lang].OTG_DESCRIPTION_2 }</p>
                                <p>{localization[lang].OTG_DESCRIPTION_3 }</p>
                                <p className='green'>{localization[lang].OTG_DESCRIPTION_4 }</p></>: localization[lang].OTG_CINEMA_DESCRIPTION }
                            </div>
                            <a href={!isCinemaType ? 'https://gameoffthegrid.com' : 'https://cinema.gameoffthegrid.com'} target='_blank'>
                                <div
                                    style={{
                                        background: 'black'
                                    }}
                                    className={`redirect-button ${(this.state.isAboutSectionRightSideContentFadeIn && fontsLoadingCheck) ? 'fadeIn' : null}`}
                                >
                                    { !isCinemaType ? localization[lang].OTG_WATCH_TRAILER : localization[lang].OTG_GO_TO_WEBSITE }
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <Fade>
                    <Footer />
                </Fade>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    games: state.games,
    lang: state.lang,
    fontsLoadingCheck: state.fontsLoadingCheck,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(GamesPage);
