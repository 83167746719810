import React, { useState } from 'react';
import { translation } from '../tools';
import ReactMarkdown from 'react-markdown';
import ScrollAnimation from 'react-animate-on-scroll';

const Image = ({gallery, index, lang}) => {
    const url = "/api" + gallery[index].image.url;
    const title = translation(gallery[index], "title", lang);
    const [ visible, setVisible ] = useState(false);
    const [ currentIndex, setCurrentIndex ] = useState(index);

    const handleNextSlide = (e)=>{
        e.persist();
        e.stopPropagation(); 

        if(currentIndex+1 >= gallery.length){
    
        } else {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevSlide = (e)=>{
        e.persist();
        e.stopPropagation(); 

        if(currentIndex-1 < 0){

        } else {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const openPopup = (e)=>{
        e.persist(); 
        e.stopPropagation(); 
        setVisible(true);
        document.body.style.top = `-${window.scrollY}px`;
        document.body.classList.add("popup-is-opened");
    }
    const closePopup = (e)=>{
        e.persist(); 
        e.stopPropagation(); 
        const scrollY = -parseInt(document.body.style.top);
        document.body.classList.remove("popup-is-opened");
        window.scrollTo(0, scrollY);
        setVisible(false);
    }
    const prevent = (e)=>{
        e.persist();
        e.stopPropagation(); 
    }

    return(
        <>
            <img className="main-picture" src={url} alt={title} onClick={openPopup} />
            <div className={`popup ${visible}`} onClick={closePopup}>
                <div className={`prev ${!(currentIndex <= 0)}`} onClick={handlePrevSlide}><div></div></div>
                <div className={`next ${!(currentIndex >= gallery.length-1)}`} onClick={handleNextSlide}><div></div></div>
                <div className="big-picture">
                    <div className="big-picture-wrapper">
                        <img className="main-picture" src={"/api" + gallery[currentIndex].image.url} alt={title} />
                        {gallery.length && gallery.length > 1 && 
                            <div className="dotes">
                                {gallery.map((value, index)=>(<div className={`dot ${index == currentIndex}`} key={index}></div>))}
                            </div>
                        }
                    </div>
                    {title &&
                        <div className="description">
                            {translation(gallery[currentIndex], "title", lang)}
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

const Gallery = (props) => {
    const { gallery, lang } = props;
    const [ currentIndex, setCurrentIndex ] = useState(0);
    const [ currentSlide, setCurrentSlide ] = useState(0);

    const SLIDE_SIZE = 4;
    let slides = [];

    for (let i = 0, j = gallery.length; i < j; i+=SLIDE_SIZE) {
        slides.push(gallery.slice(i, i+SLIDE_SIZE));
    }

    const handleNextSlide = ()=>{
        if(currentSlide+1 >= slides.length){
    
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };

    const handlePrevSlide = ()=>{
        if(currentSlide-1 < 0){

        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const handleSetPicture = (index) => {
        console.log("currentIndex: ", currentIndex);
        setCurrentIndex(index);
    };

    return(
        <React.Fragment>
            <div className="gallery">
                <div className={'main-picture-frame'} style={{left: -100*currentIndex+"%"}}>
                    {gallery.map((image, index)=>{
                        if(image.image){
                            return(
                                <React.Fragment key={index}>
                                    <Image index={index} lang={lang} gallery={gallery} />
                                </React.Fragment>
                            )
                        }
                    })}
                </div>
            </div>
            <div className="thumbnails">
                <div className={'main-picture-frame'} style={{left: `calc(${-currentSlide*100}% - ${13.5*currentSlide+13.5}px)`,}}>
                    {gallery.map((image, index)=>{
                        if(image.image){
                            return(
                                <img className={`main-picture ${index == currentIndex ? "active" : ""}`} src={"/api" + image.image.url} onClick={()=>handleSetPicture(index)} key={index} />
                            )
                        }
                    })}
                </div>
                <div className={`slide-prev ${currentSlide != 0}`} onClick={handlePrevSlide}></div>
                <div className={`slide-next ${currentSlide != slides.length-1}`} onClick={handleNextSlide}></div>
            </div>
        </React.Fragment>
    );
};

export default ({timeline, lang, index}) => {
    if(!timeline) return (<div></div>);
    console.log(timeline);
    const blocks = timeline.timeline_blocks;
    const TimelineDate = ({ date }) => (
        <div className={`timeline-year`}>
            {date}
        </div>
    );

    return (
        <React.Fragment>
            <div className="timeline">
                {blocks.map((block, index)=>{
                    return(
                        <div className="timeline-item" style={{zIndex: blocks.length - index}} key={index}>
                            {index == 0 && <TimelineDate date={block.year} />}
                            {blocks[index-1] && blocks[index-1].year !== block.year && <TimelineDate date={block.year} />}

                            <ScrollAnimation animateIn={`${index % 2 != 0 ? 'animate__fadeInRight' : 'animate__fadeInLeft'}`} animateOut={`${index % 2 != 0 ? 'animate__fadeOutLeft' : 'animate__fadeOutRight'}`} animateOnce={true} delay={100} duration={2}>    
                                <div className={`timeline-content timeline-card ${index % 2 == 0 ? "left" : "right"}`}>
                                    {block.gallery && <Gallery gallery={block.gallery} lang={lang} />}
                                    <div className="timeline-card-content">
                                        <h3>{translation(block, "title", lang)}</h3>
                                        <ReactMarkdown
                                            className={`cms markdown`}
                                            source={translation(block, "content", lang)}
                                            escapeHtml={false}
                                        />
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>
                    );
                })}
            </div>
            {timeline.info_picture && <img className="info_picture" src={"/api" + translation(timeline,"info_picture", lang).url} /> }
        </React.Fragment>
    );
};