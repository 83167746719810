import React from "react";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Switch,
  NavLink,
  Link,
  Redirect,
  useParams,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./shared/store";

import HomePage from "./containers/HomePage";
import CareerPage from "./containers/CareerPage";
import ContactUsPage from "./containers/ContactUsPage";
import NewsPage from "./containers/NewsPage";
import PressPage from "./containers/PressPage";
import GamesPage from "./containers/GamesPage";
import LegalPage from "./containers/LegalPage";
import CookiePage from "./containers/CookiePage";
import PrivacyPage from "./containers/PrivacyPage";
import InternshipPage from "./containers/InternshipPage";
import LeadershipPage from "./containers/LeadershipPage";
import { GunzPage } from "./containers/GunzPage";
import ScrollToTop from "./components/ScrollToTop";
import PageNotFound from "./containers/notFoundPage";
//Remove later
import "./fontsLoadStatusCheck";
import { usePageLoadRetryWithErrorHandling } from "./hooks";

function App(props) {
  // usePageLoadRetryWithErrorHandling();

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route path="/en/*">
              <InnerRoutes lang="en" />
            </Route>
            <Route path="/de/*">
              <InnerRoutes lang="de" />
            </Route>
            <Route path="/ua/*">
              <InnerRoutes lang="ua" />
            </Route>
            <Route path="/" exact>
              <Redirect from="*" to="/en/" />
            </Route>
            <Route path="/:oldUrl">
              <Redirection />
            </Route>
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  );
}

const Redirection = (props) => {
  const { oldUrl } = useParams();
  // need to handle 404 also
  return oldUrl ? (
    <Redirect from="*" to={`/en/${oldUrl}`} />
  ) : (
    <Redirect from="*" to={`/en/`} />
  );
};

function InnerRoutes(props) {
  const { lang } = props;
  return (
    <Switch>
      {/* <Route
        path={`/${lang}/leadership`}
        component={(propsRouter) => (
          <LeadershipPage {...propsRouter} lang={lang} />
        )}
      /> */}
      <Route
        path={`/${lang}/news/:slug?`}
        component={(propsRouter) => <NewsPage {...propsRouter} lang={lang} />}
      />
      <Route
        path={`/${lang}/press`}
        component={(propsRouter) => <PressPage {...propsRouter} lang={lang} />}
      />
      <Route
        path={`/${lang}/careers/:slug?`}
        component={(propsRouter) => <CareerPage {...propsRouter} lang={lang} />}
      />
      <Route
        path={`/${lang}/contact`}
        component={(propsRouter) => (
          <ContactUsPage {...propsRouter} lang={lang} />
        )}
      />
      <Route
        path={`/${lang}/legal`}
        component={(propsRouter) => <LegalPage {...propsRouter} lang={lang} />}
      />
      <Route
        path={`/${lang}/privacy-policy`}
        component={(propsRouter) => (
          <PrivacyPage {...propsRouter} lang={lang} />
        )}
      />
      <Route
        path={`/${lang}/cookie-policy`}
        component={(propsRouter) => <CookiePage {...propsRouter} lang={lang} />}
      />
      <Route
        path={`/${lang}/off-the-grid`}
        component={(propsRouter) => <GamesPage {...propsRouter} lang={lang} />}
      />
      <Route
        path={`/${lang}/cinema`}
        component={(propsRouter) => (
          <GamesPage {...propsRouter} lang={lang} isCinemaType />
        )}
      />
      <Route
        path={`/${lang}/about`}
        component={(propsRouter) => (
          <HomePage {...propsRouter} about={true} lang={lang} />
        )}
      />
      {/* <Route
        path={`/${lang}/internships`}
        component={(propsRouter) => (
          <InternshipPage {...propsRouter} lang={lang} />
        )}
      /> */}
      <Route
        path={`/${lang}/`}
        exact={true}
        component={(propsRouter) => <HomePage {...propsRouter} lang={lang} />}
      />
      {/* <Route path={`/${lang}/gunz`} component={(propsRouter) => <GunzPage {...propsRouter} lang={lang}/>}/> */}
      <Route
        path={`/${lang}/`}
        exact={false}
        component={(propsRouter) => (
          <PageNotFound {...propsRouter} lang={lang} />
        )}
      />
    </Switch>
  );
}

export default App;
