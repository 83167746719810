
import * as actions from './actions';
import { isAdminPanelUser } from '../tools';

const initialState = {
    homePage: {},
    leaderships: [],
    vacancies: [],
    news: [],
    games: {},
    contactUsPage: {},
    aboutGallery: {},
    legalPage: {
        title: "",
        content: ""
    },
    privacyPage: {
        title: "",
        content: ""
    },
    cookiePage: {
        title: "",
        content: ""
    },
    careersPage: {
        title: "",
        content: ""
    },
    newsPage: {
        title: "",
        content: ""
    },
    internshipPage: {
        title: "",
        content: ""
    },
    locations: {},
    roles: {},
    lang: "en",
    timeline: {},
    newTimeline: {},
    showInactive: isAdminPanelUser(),
    loading: {
        news: true,
        vacancies: true,
        locations: true,
        roles: true,
    },
    fontsLoadingCheck: false,
}

export default function reducer(state = initialState, action){
    switch (action.type){
        case actions.UPDATE_HOME_PAGE:
            return {
                ...state,
                homePage: action.payload
            };
        case actions.UPDATE_LEADERSHIP:
            return {
                ...state,
                leaderships: action.payload
            };
        case actions.UPDATE_VACANCIES:
            return {
                ...state,
                vacancies: action.payload,
                loading: {
                    ...state.loading,
                    vacancies: false
                }
            };
        case actions.UPDATE_NEWS:
            return {
                ...state,
                news: action.payload,
                loading: {
                    ...state.loading,
                    news: false
                }
            };
        case actions.UPDATE_GAMES:
            return {
                ...state,
                games: action.payload
            };
        case actions.UPDATE_CONTACTUS:
            return {
                ...state,
                contactUsPage: action.payload
            };
        case actions.UPDATE_ABOUT_GALLERY:
            return {
                ...state,
                aboutGallery: action.payload
            };
        case actions.UPDATE_LEGAL_PAGE:
            return {
                ...state,
                legalPage: action.payload
            };
        case actions.UPDATE_PRIVACY_PAGE:
            return {
                ...state,
                privacyPage: action.payload
            };
        case actions.UPDATE_COOKIE_PAGE:
            return {
                ...state,
                cookiePage: action.payload
            };
        case actions.UPDATE_ROLES:
            return {
                ...state,
                roles: action.payload,
                loading: {
                    ...state.loading,
                    roles: false
                }
            };
        case actions.UPDATE_LOCATIONS:
            return {
                ...state,
                locations: action.payload,
                loading: {
                    ...state.loading,
                    locations: false
                }
            };
        case actions.UPDATE_LANGUAGE:
            return {
                ...state,
                lang: action.payload
            };
        case actions.UPDATE_TIMELINE:
            return {
                ...state,
                timeline: action.payload
            };
        case actions.UPDATE_NEW_TIMELINE:
            return {
                ...state,
                newTimeline: action.payload
            };
        case actions.UPDATE_CAREERS_PAGE:
            return {
                ...state,
                careersPage: action.payload
            };
        case actions.UPDATE_NEWS_PAGE:
            return {
                ...state,
                newsPage: action.payload
            };
        case actions.UPDATE_INTERNSHIP_PAGE:
            return {
                ...state,
                internshipPage: action.payload
            };
        case actions.FONTS_LOADING_CHECKED:
            return {
                ...state,
                fontsLoadingCheck: true,
            }
        default:
            return state;
    }
}
