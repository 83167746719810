import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (window.gtag) {
      window.gtag("event", "page_view", {
        page_path: window.location.pathname,
      });
    }

    let { hash } = this.props.location;
    let header2 = document.getElementById("header2");

    let elementToScroll = 0;

    if (hash) {
      elementToScroll = document.getElementById(hash.slice(1)).offsetTop - 110; // 64 - height of the top menu
      setTimeout(() => window.scrollTo(0, elementToScroll), 1);
      return "";
    }

    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
