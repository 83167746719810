import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Slider.css';

const Slider = (props) => {
    const { cols, rows, gap, children, className, moreLink, moreTitle, initialHeight } = props;
    const perSlide = cols * rows;
    const slidesCount = Math.ceil(children.length / perSlide);
    let [ page, setPage ] = useState(0);
    let [ wrapperHeight, setWrapperHeight ] = useState(initialHeight || 420);
    const sliderContentRef = useRef(null);


    useEffect(()=>{
        // console.log("page: ", page);
        // console.log("slidesCount: ", slidesCount);
        if(slidesCount > 0 && page >= slidesCount){
            setPage(slidesCount-1);
        } 
        let timeout;
        if (sliderContentRef.current && sliderContentRef.current.offsetHeight && sliderContentRef.current.offsetHeight > 1){
            timeout = setInterval(()=>{
                // console.log("sliderContentRef.current.clientHeight: ", sliderContentRef.current.clientHeight)
                setWrapperHeight(sliderContentRef.current.offsetHeight);
            }, 1000);
            return ()=> clearInterval(timeout);
        }
    });

    let slides = [];

    for (let s = 0; s < slidesCount; s++){ // slicing on slides
        slides[s] = children.slice(s*perSlide, s*perSlide+perSlide);
    }

    let sliderStyle = {
        left: `calc(${-page*100}% - ${gap*page+gap}px)`,
        width: `calc(${slidesCount*100}% + ${slidesCount*gap}px)`
    }

    let wrapperStyle = {
        padding: "0",
        height: `${wrapperHeight}px`
    }

    let slideStyle = {
        marginLeft: gap,
        width: `calc(100% + ${gap}px)`
    }

    const slideLeft = ()=>{
        if (page > 0) setPage(page-1);
    };

    const slideRight = ()=>{
        if (page < slidesCount-1) {
            setPage(page+1);
            setWrapperHeight(sliderContentRef.current.offsetHeight);
        }
    };

    return(
        <div className={`rslider ${className}`}>
            <div className="rslider-wrapper" style={wrapperStyle}>
                <div className={`rslider-content count${slidesCount}`} style={sliderStyle} ref={sliderContentRef}>
                    {slides.map((slide, index)=>{
                        return(
                            <div className={`rslide slide${index} cols${cols}`} key={index} style={slideStyle}>
                                {slide.map((element, index)=>{
                                    return(
                                        <div className={`item`} key={index}>
                                            {element}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="rslider-control">
                <div className={`slide-left ${page === 0}`} onClick={slideLeft}></div>
                <div className={`slide-right ${page === slidesCount-1 }`} onClick={slideRight}></div>
                {moreLink && moreTitle ? <NavLink to={moreLink} className="button more-articles">{moreTitle}</NavLink> : "" }
            </div>
        </div>
    );
}

export default Slider;