import React from 'react';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ReactMarkdown from 'react-markdown';
import { translation } from '../tools';
import MetaTags from 'react-meta-tags';
import Page404Content from '../components/Page404Content';

export default function(){
    return(
        <React.Fragment>
            <Header />
            <Page404Content />
            <Footer />
        </React.Fragment>
    );
}