import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import localization from "../localization";
import "./OTGPreviewSection.css";

export const OTGPreviewSection = ({
  lang,
  isContentFadeIn,
  backgroundTranslateValue,
  contentTranslateValue,
  withoutFadeIn,
  forwardedClass,
  redirectionMode,
  isCinemaType,
}) => {
  //withoutFadeIn: prevent fade in animation. All content shown by default.
  //!withoutFadeIn: basic class(otgLogo, red-btn, pltf-icons - hidden. Fade in - shown with animation.

  const [isRedirectButtonAlreadyFadeIn, setIsRedirectButtonAlreadyFadeIn] =
    useState(false);
  const platformsSequences = ["ps5", "xbox-xs", "pc"]; // "macos"

  useEffect(() => {
    setTimeout(() => setIsRedirectButtonAlreadyFadeIn(true), 400);
  }, []);

  //2 redirection modes.
  //Inner - leads to the page of the current website with the game.
  //Outer - leads to an external website dedicated to the game.
  const redirectionModesData = {
    inner: {
      buttonText: localization[lang].OTG_HOME_SLIDE_LEARN_MORE,
      link: `/${lang}/off-the-grid`,
    },
    outer: {
      buttonText: localization[lang].OTG_GO_TO_WEBSITE,
      link: !isCinemaType
        ? "https://playoffthegrid.com/"
        : "https://offthegridcinema.com/",
    },
  };

  const redirectionButtonLayout = (
    <div
      style={withoutFadeIn ? { opacity: 1, transform: "initial" } : null}
      className={`redirect-button ${isContentFadeIn ? "fadeIn" : ""} ${
        withoutFadeIn || isRedirectButtonAlreadyFadeIn ? "hoverOn" : ""
      } `}
    >
      {redirectionModesData[redirectionMode].buttonText}
    </div>
  );

  const redirectionButtonWrap =
    redirectionMode === "inner" ? (
      <NavLink
        className={"link-wrapper"}
        to={redirectionModesData[redirectionMode].link}
      >
        {redirectionButtonLayout}
      </NavLink>
    ) : (
      <a
        className={`link-wrapper ${isCinemaType ? "cinemaType" : ""}`}
        href={redirectionModesData[redirectionMode].link}
        target="_blank"
      >
        {redirectionButtonLayout}
      </a>
    );

  return (
    <div
      style={
        backgroundTranslateValue && !true
          ? {
              backgroundPosition: `center, center ${
                90 - backgroundTranslateValue
              }%`,
            }
          : null
      }
      className={`otgPreviewSection ${forwardedClass ? forwardedClass : ""}`}
    >
      <div className="content">
        {/*div - potential  sum of heights holder for parallax(wrapper height)*/}
        <div
          style={
            contentTranslateValue
              ? { transform: `translateY(${contentTranslateValue}%)` }
              : null
          }
          className={true ? "column-wrapper" : ""}
        >
          <div
            style={withoutFadeIn ? { opacity: 1, transform: "initial" } : null}
            className={`otgLogo ${
              isContentFadeIn ? "fadeIn" : ""
            } ${forwardedClass}`}
          />
          {redirectionButtonWrap}
          {!isCinemaType && (
            <div
              style={
                withoutFadeIn ? { opacity: 1, transform: "initial" } : null
              }
              className={`platforms-icons ${isContentFadeIn ? "fadeIn" : ""}`}
            >
              {platformsSequences.map((platformName) => (
                <div
                  key={platformName}
                  className={`platform-icon ${platformName}`}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
