import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';
import * as Sentry from "@sentry/react";
import {titleToUrl} from "./tools";
// import { BrowserTracing } from "@sentry/tracing";

const tagManagerArgs = {
    gtmId: 'GTM-P6ZV5H7'
}

TagManager.initialize(tagManagerArgs)

Sentry.init({
    dsn: 'https://aebd126e51654baa844f00bb6b6cd0bc@sentry.hiredeveloper.io/5',
    integrations: [],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
