import React, { useState, useEffect, useRef } from 'react';
import { titleToUrl, translation } from '../tools';

export default function Select(props){
    let { options, height, cb, lang, className, resetCount } = props;

    console.log(height);

    let [ activeIndex, setActiveIndex ] = useState(0);
    let activeRef = useRef(false);
    let [active, setActive] = useState(false);

    useEffect(() => {
        //User can send own data multiple times. So we trigger 'reset menu logic' on resetCount++
        // every time, and only once(before next time);
        setActiveIndex(0);
        setActive(false);
    }, [resetCount]);


    const closeSelect = (e) => {
        if(!e.target.classList.contains("dditem")){
            activeRef.current = false;
            setActive(activeRef.current);
        }
    };

    useEffect(()=>{
        document.body.addEventListener("click", closeSelect);
        return () => document.body.removeEventListener("click", closeSelect);
    }, []);

    if (!options?.length) return("");
    
    return(
        <div className={`r-dropdown dditem ${active ? " active" : "false" } ${className}`} onClick={(e)=>{
            activeRef.current = !activeRef.current;
            setActive(activeRef.current);
        }}>
            <span className={`dditem active-option a${activeIndex}`}>{options[activeIndex].title}</span>
            <ul className="dditem"
                style={{
                overflowY: "auto",
                maxHeight: height*4+2,
                height: (options.length - 1) * height + 2
            }}>
                {options.map((element, index)=>{
                   if (index > 0) {
                        const title = element.title;
                        const direction = index % 2 !== 0 ? 'left' : 'right';
                        return(
                            <li key={index}
                                style={{ top: height*(index - 1) }}
                                className={`dditem ${direction} `}
                                onClick={(e)=>{
                                    setActiveIndex(index);
                                    cb(index);
                                }}
                            >
                                <span className="dditem" data-value={index}>{title}</span>
                            </li>
                        );
                    }
                })}
            </ul>
        </div>
    );
}
