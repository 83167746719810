import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Header2 from "../components/Header2";
import Gallery from "../components/Gallery";
import About from "../components/About";
import Leadership from "../components/Leadership";
import LatestNews from "../components/LatestNews";
import Footer from "../components/Footer";
import { translation } from "../tools";
import { Parallax } from "react-parallax";
import ReactMarkdown from "react-markdown";
import paralaxBg from "../assets/images/ForCareerPage.png";
import { NavLink } from "react-router-dom";
import localization from "../localization";
import MetaTags from "react-meta-tags";
import store from "../shared/store";
import { Loader } from "../components/Loader";
import * as actions from "../shared/actions";

class HomePage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    store.dispatch(actions.fetchAboutGallery());
    store.dispatch(actions.fetchLeadership());
  }

  render() {
    const {
      aboutGallery,
      about,
      homePage,
      lang,
      vacancies,
      location,
      fontsLoadingCheck,
    } = this.props;

    let text = translation(homePage, "create_worlds", lang) || "";
    let vc = vacancies.length
      ? vacancies.filter((v) => v.active == true).length
      : 0;
    text = text.replace("$$", `<span>${vc}</span>`);

    return (
      <React.Fragment>
        <MetaTags>
          {homePage.meta_title ? (
            <>
              <title>{translation(homePage, "meta_title", lang)}</title>
              <meta
                id="meta-title"
                name="title"
                content={translation(homePage, "meta_title", lang)}
              />
              <meta
                id="og-title"
                property="og:title"
                content={translation(homePage, "meta_title", lang)}
              />
              <meta
                id="twitter-title"
                property={translation(homePage, "meta_title", lang)}
              />
            </>
          ) : (
            <>
              <title>{translation(homePage, "title", lang)}</title>
              <meta
                id="meta-title"
                name="title"
                content={translation(homePage, "title", lang)}
              />
              <meta
                id="og-title"
                property="og:title"
                content={translation(homePage, "title", lang)}
              />
              <meta
                id="twitter-title"
                property={translation(homePage, "title", lang)}
              />
            </>
          )}
          {homePage.meta_description ? (
            <>
              <meta
                id="meta-description"
                name="description"
                content={translation(homePage, "meta_description", lang)}
              />
              <meta
                id="og-description"
                property="og:description"
                content={translation(homePage, "meta_description", lang)}
              />
              <meta
                id="twitter-description"
                property={translation(homePage, "meta_description", lang)}
              />
            </>
          ) : (
            <>
              <meta
                id="meta-description"
                name="description"
                content={translation(homePage, "title", lang)}
              />
              <meta
                id="og-description"
                property="og:description"
                content={translation(homePage, "title", lang)}
              />
              <meta
                id="twitter-description"
                property={translation(homePage, "title", lang)}
              />
            </>
          )}
          <link rel="canonical" />
          <meta id="og-url" property="og:url" content={window.location.href} />
          <meta
            id="twitter-url"
            property="twitter:url"
            content={window.location.href}
          />
        </MetaTags>
        <Loader loaded={fontsLoadingCheck} />
        <Header />
        <Header2 isPageLoaded={fontsLoadingCheck} />
        <About about={about} />
        {aboutGallery && (
          <Gallery slides={aboutGallery} className="about-gallery2" />
        )}
        <Leadership />
        <LatestNews />
        <section className="didntfind">
          <Parallax bgImage={paralaxBg} strength={400}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className={`didnt-find`}>
                    <ReactMarkdown source={text} escapeHtml={false} />
                    <p>
                      <NavLink to={`/${lang}/careers/`}>
                        {localization[lang].BUTTON_EXPLORE_CAREERS}
                      </NavLink>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  aboutGallery: state.aboutGallery.gallery_slides,
  lang: state.lang,
  homePage: state.homePage,
  vacancies: state.vacancies,
  fontsLoadingCheck: state.fontsLoadingCheck,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
