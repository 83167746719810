import React, { Component, useState } from 'react';
import random from 'random-number-in-range';
import Fade from 'react-reveal/Fade';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup, Dot } from 'pure-react-carousel';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'
const tempUrl = "";


export default (props) =>{
    const { slides, className } = props;
    const [ visible, setVisible ] = useState(false);
    const [ currentIndex, setCurrentIndex ] = useState(0);

    const gallery = [];
    slides.forEach((slide)=>{
        slide.images.forEach((image)=>{
            gallery.push(image)
        });
    });

    const imgClickHandle = (index) =>{
        console.log(index);
        setCurrentIndex(index);
        openPopup();
    }

    const openPopup = (e)=>{
        setVisible(true);
        document.body.style.top = `-${window.scrollY}px`;
        document.body.classList.add("popup-is-opened");
    }
    const closePopup = (e)=>{
        const scrollY = -parseInt(document.body.style.top);
        document.body.classList.remove("popup-is-opened");
        window.scrollTo(0, scrollY);
        setVisible(false);
    }

    const handleNextSlide = (e)=>{
        e.persist();
        e.stopPropagation(); 

        if(currentIndex+1 >= gallery.length){
    
        } else {
            setCurrentIndex(currentIndex + 1);
        }
        console.log(currentIndex);
    };

    const handlePrevSlide = (e)=>{
        e.persist();
        e.stopPropagation(); 

        if(currentIndex-1 < 0){

        } else {
            setCurrentIndex(currentIndex - 1);
        }
    };

    let slidesCount = slides && slides.length ? slides.length : 0;

    return(
        <section className={`rgallery ${className && className}`} >
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="rgallery-wrapper">
                            <div className="rgallery-slides">
                                <CarouselProvider
                                    style={{maxWidth:"100%"}}
                                    totalSlides={slidesCount}
                                    infinite={true}
                                    visibleSlides={1}
                                    step={1}
                                    naturalSlideWidth={`300px`}
                                    naturalSlideHeight={`600px`}
                                    isIntrinsicHeight
                                >
                                    <Slider>
                                        {slides && slides.map((slide, slideIndex)=>{
                                            return(
                                                <Slide index={slideIndex} key={slideIndex} >
                                                    <div className={`rslide slide${slideIndex}`} key={slideIndex}>
                                                        {slide && slide.images.map((image, imageIndex)=>{
                                                            switch(image.img_size){
                                                                case "s1_4":
                                                                    if(slides[slideIndex].images[imageIndex+1] && slides[slideIndex].images[imageIndex+1] && slides[slideIndex].images[imageIndex+1].img_size == "s1_4"){
                                                                        return(
                                                                            <div className={`image ${image.img_size}`} key={imageIndex} >
                                                                                <div className="image-holder">
                                                                                    <img src={tempUrl + '/api' + slides[slideIndex].images[imageIndex].image.url} onClick={()=> imgClickHandle(slideIndex*slides[slideIndex].images.length + imageIndex)} />
                                                                                </div>
                                                                                <div className="image-holder">
                                                                                    <img src={tempUrl + '/api' + slides[slideIndex].images[imageIndex+1].image.url} onClick={()=> imgClickHandle(slideIndex*slides[slideIndex].images.length + imageIndex)} />
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    break;
                                                                case "s2_4":
                                                                    return(
                                                                        <div className={`image ${image.img_size}`} key={imageIndex} >
                                                                            <div className="image-holder">
                                                                                <img src={tempUrl + '/api' + image.image.url} onClick={()=> imgClickHandle(slideIndex*slides[slideIndex].images.length + imageIndex)} />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                    break;
                                                                case "s4_4":
                                                                    return(
                                                                        <div className={`image ${image.img_size}`} key={imageIndex} >
                                                                            <div className="image-holder">
                                                                                <img src={tempUrl + '/api' + image.image.url} onClick={()=> imgClickHandle(slideIndex*slides[slideIndex].images.length + imageIndex)} />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                    break;
                                                            }
                                                        })}
                                                    </div>
                                                </Slide>
                                            );
                                        })}
                                    </Slider>
                                    <DotGroup className="dotes" />
                                </CarouselProvider>
                            </div>
                            <div className="rgallery-control">

                            </div>
                            <div className={`popup-new-gallery popup ${visible}`} onClick={closePopup}>
                                <div className="big-picture about-pc-popup" >
                                    {gallery && gallery.map((element, index)=>{
                                        return(
                                            <img key={index} className={`image-home ${index == currentIndex}`} src={`/api` + element.image.url} onClick={e => e.stopPropagation()} />
                                        )
                                    })}
                                </div>
                                {gallery && <>
                                    <div className={`left ${currentIndex != 0}`} onClick={handlePrevSlide}></div>
                                    <div className={`right ${currentIndex != gallery.length-1}`} onClick={handleNextSlide}></div>
                                    <div className={`close`} onClick={closePopup}></div>
                                </>}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
