import React from 'react';
import './RadioGroup.css';
import { translation }  from '../tools';

export default class RadioGroup extends React.Component{
    constructor(props){
        super(props);
    }
    state = {
        items: this.props.items,
        selectedButton: 0,
    }

    static getDerivedStateFromProps(props, state){
        return {
            ...state,
            items: props.items
        }
    }

    handleClick(clickedItem, index){
        this.setState({
            ...this.state,
            selectedButton: index
        });
        this.props.cb(clickedItem);
    }

    render(){
        return(
            <div className="radoigroup-container">
                {this.state.items && this.state.items.map((item, index)=>(
                    <div className={`radiobutton ${this.state.selectedButton === index ? 'selected' : ''}`} key={index} onClick={()=>{
                        this.handleClick(item, index);
                    }}>
                        <div className="circle"></div>
                        <div className="label">{ translation(item, "short_title", this.props.lang)}</div>
                    </div>
                ))}
            </div>
        );
    }
}