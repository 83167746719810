import React from "react";
import './Loader.css'

export const Loader = ({ loaded }) => {
  return (
      <div className={`g-loader ${loaded && 'hidden'}`}>
          <div className="loader-logo" />
          <div className="loader-line-mask">
              <div className="loader-line" />
          </div>
      </div>
  )
}