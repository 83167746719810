import React, { useState, useRef, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import './Slider.css';

export const NewsSliderClone = (props) => {
    const { cols, rows, gap, children, className, moreLink, moreTitle, initialHeight, currentWindowWidth, maxHeightCardTextValue, maxHeightCardTagsValue } = props;
    const perSlide = cols * rows;
    const slidesCount = Math.ceil(children.length / perSlide);
    let [ page, setPage ] = useState(0);
    let [ wrapperHeight, setWrapperHeight ] = useState(initialHeight || 420);
    const [ slidesRefsAssigned, setSlidesRefsAssigned ] = useState(false);
    const [ slidesHeightValues, setSlidesHeightValues ] = useState(null);
    
    const sliderContentRef = useRef(null);

    useEffect(()=>{
        // console.log("page: ", page);
        // console.log("slidesCount: ", slidesCount);
        if(slidesCount > 0 && page >= slidesCount){
            setPage(slidesCount-1);
        } 
    });

    const resizingSliderOnEvents = () => {
        let tempArr = [];
        slidesRefs.forEach((item) => item.current && tempArr.push(item.current.offsetHeight))
        console.log(tempArr);
        setSlidesHeightValues(tempArr);
        setWrapperHeight(tempArr[page]);
    }

    useEffect(() => {
        if (slidesRefsAssigned && maxHeightCardTextValue && maxHeightCardTagsValue) {
            setTimeout(() => {
                resizingSliderOnEvents();
            }, 800) 
       }
    }, [maxHeightCardTextValue, maxHeightCardTagsValue])

    useEffect(() => {
        if (currentWindowWidth) {
            resizingSliderOnEvents();
       }
    }, [currentWindowWidth])

    let slides = [];

    for (let s = 0; s < slidesCount; s++){ // slicing on slides
        slides[s] = children.slice(s*perSlide, s*perSlide+perSlide);
    }

    let slidesRefs = useMemo(() => {
        let arr = [];
        for (let i = 0; i < slidesCount; i++) { 
            arr.push(React.createRef(null));
            if (i === slidesCount - 1) {
                setSlidesRefsAssigned(true);
            }
        }
        return arr;
    },[slidesCount])

    let sliderStyle = {
        left: `calc(${-page*100}% - ${gap*page+gap}px)`,
        width: `calc(${slidesCount*100}% + ${slidesCount*gap}px)`,
    }

    let wrapperStyle = {
        padding: "0",
        height: `${wrapperHeight}px`,
    }

    let slideStyle = {
        marginLeft: gap,
        width: `calc(100% + ${gap}px)`,
    }

    const slideLeft = ()=>{
        if (page > 0) setPage(page-1);
        setWrapperHeight(slidesHeightValues[page-1]);
    };

    const slideRight = ()=>{
        if (page < slidesCount-1) setPage(page+1);
        setWrapperHeight(slidesHeightValues[page+1]);
    };

    return(
        <div className={`rslider ${className}`}>
            <div className="rslider-wrapper" style={wrapperStyle}>
                <div className={`rslider-content count${slidesCount}`} style={sliderStyle} ref={sliderContentRef}>
                    {slides.map((slide, index)=>{
                        return(
                            <div ref={slidesRefs[index]} className={`rslide slide${index} cols${cols} last-slide-adapting`} key={index} style={slideStyle}>
                                {slide.map((element, index)=>{
                                    return(
                                        <div className={`item`} key={index}>
                                            {element}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="rslider-control">
                <div className={`slide-left ${page === 0}`} onClick={slideLeft}></div>
                <div className={`slide-right ${page === slidesCount-1 }`} onClick={slideRight}></div>
                {moreLink && moreTitle ? <NavLink to={moreLink} className="button more-articles">{moreTitle}</NavLink> : "" }
            </div>
        </div>
    );
}
