import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";
import Fade from "react-reveal/Fade";
import { translation } from "../tools";
import localization from "../localization";
const tempUrl = "";

function Leadership(props) {
  const { leaderships, lang, showInactive, isAllEmployees } = props;
  const [more, setMore] = useState(false);

  const leadershipToShow = leaderships.filter((val) =>
    isAllEmployees
      ? val.active || showInactive
      : (val.isHomePage && val.active) || (val.isHomePage && showInactive)
  );

  return (
    <React.Fragment>
      <section className="leadership desktop">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>{localization[lang].SECTION_TITLE_LEADERSHIP}</h2>
              <div className="leadership-content">
                {leadershipToShow.map((leader, index) => (
                  <Leader
                    lang={lang}
                    leader={leader}
                    index={index}
                    key={leader.id}
                  />
                ))}
              </div>
              {/* {!isAllEmployees && (
                <NavLink
                  to={`/leadership`}
                  className="button see-all-leadership"
                >
                  {localization[lang].BUTTON_SEE_ALL_LEADERSHIP}
                </NavLink>
              )} */}
            </div>
          </div>
        </div>
      </section>
      <section className="leadership mobile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h2>{localization[lang].SECTION_TITLE_LEADERSHIP}</h2>
              <div className="leadership-content">
                {more
                  ? leadershipToShow.map((leader, index) => (
                      <Leader
                        lang={lang}
                        leader={leader}
                        index={index}
                        key={leader.id}
                      />
                    ))
                  : leadershipToShow
                      .slice(0, 4)
                      .map((leader, index) => (
                        <Leader
                          lang={lang}
                          leader={leader}
                          index={index}
                          key={leader.id}
                        />
                      ))}
              </div>
              {!more ? (
                <div
                  className="button show-more100"
                  onClick={() => {
                    setMore(true);
                  }}
                >
                  {localization[lang].BUTTON_SHOW_MORE}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

const Leader = (props) => {
  let { leader, index, lang } = props;
  const isCustomLink = leader.fullname == "Christian B. Sullivan";
  const [visible, setVisible] = useState(false);
  const openPopup = (e) => {
    e.persist();
    e.stopPropagation();
    if (isCustomLink) {
      window.open(
        "https://gunzillagames.com/en/news/53-Gunzilla-Games-Announces-Strategic-Appointment-of-Christian-B--Sullivan-as-Operating-Partner-and-Board-Member",
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      setVisible(true);
      document.body.style.top = `-${window.scrollY}px`;
      document.body.classList.add("popup-is-opened");
    }
  };
  const closePopup = (e) => {
    e.persist();
    e.stopPropagation();
    const scrollY = -parseInt(document.body.style.top);
    document.body.classList.remove("popup-is-opened");
    window.scrollTo(0, scrollY);
    setVisible(false);
  };
  const prevent = (e) => {
    e.persist();
    e.stopPropagation();
  };

  return (
    <div className="leader-card" key={leader.id} onClick={openPopup}>
      <Fade delay={index * 50}>
        <div className="poster">
          <img src={tempUrl + "/api" + leader.photo.url} />
          <div className="leader-hover">
            <div className="title">{translation(leader, "fullname", lang)}</div>
            <div className="short-desc">
              {translation(leader, "role", lang)}
            </div>
          </div>
        </div>
      </Fade>

      <div className={`popup ${visible}`} onClick={closePopup}>
        <div className="popoup-content" onClick={prevent}>
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <img
                src={tempUrl + "/api" + leader.photo.url}
                className="photo"
              />
              <div className="icons iconsGreen desktop">
                {leader.link_facebook && (
                  <a
                    href={leader.link_facebook}
                    className="facebook"
                    target="blank"
                    rel="nofollow"
                  ></a>
                )}
                {leader.link_twitter && (
                  <a
                    href={leader.link_twitter}
                    className="twitter"
                    target="blank"
                    rel="nofollow"
                  ></a>
                )}
                {leader.link_linkedin && (
                  <a
                    href={leader.link_linkedin}
                    className="linkedin"
                    target="blank"
                    rel="nofollow"
                  ></a>
                )}
                {leader.link_instagram && (
                  <a
                    href={leader.link_instagram}
                    className="instagram"
                    target="blank"
                    rel="nofollow"
                  ></a>
                )}
                {leader.link_personal && (
                  <a
                    href={leader.link_personal}
                    className="personal"
                    target="blank"
                    rel="nofollow"
                  ></a>
                )}
                {leader.link_artstation && (
                  <a
                    href={leader.link_artstation}
                    className="artstation"
                    target="blank"
                    rel="nofollow"
                  ></a>
                )}
              </div>
            </div>
            <div className="col-md-9 col-sm-12 rightPanel">
              <div className="title">
                {translation(leader, "fullname", lang)}
              </div>
              <div className="role">{translation(leader, "role", lang)}</div>
              <div className="icons iconsGreen mobile">
                {leader.link_facebook && (
                  <a
                    href={leader.link_facebook}
                    className="facebook"
                    target="blank"
                    rel="nofollow"
                  ></a>
                )}
                {leader.link_twitter && (
                  <a
                    href={leader.link_twitter}
                    className="twitter"
                    target="blank"
                    rel="nofollow"
                  ></a>
                )}
                {leader.link_linkedin && (
                  <a
                    href={leader.link_linkedin}
                    className="linkedin"
                    target="blank"
                    rel="nofollow"
                  ></a>
                )}
                {leader.link_instagram && (
                  <a
                    href={leader.link_instagram}
                    className="instagram"
                    target="blank"
                    rel="nofollow"
                  ></a>
                )}
                {leader.link_personal && (
                  <a
                    href={leader.link_personal}
                    className="personal"
                    target="blank"
                    rel="nofollow"
                  ></a>
                )}
                {leader.link_artstation && (
                  <a
                    href={leader.link_artstation}
                    className="artstation"
                    target="blank"
                    rel="nofollow"
                  ></a>
                )}
              </div>
              <ReactMarkdown
                className={`desc`}
                source={translation(leader, "full_description", lang)}
                escapeHtml={false}
              />
            </div>
          </div>
          <div className="close" onClick={closePopup}></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  leaderships: state.leaderships,
  lang: state.lang,
  showInactive: state.showInactive,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Leadership);
