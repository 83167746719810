import { debounce } from 'throttle-debounce';

export function titleToUrl(title){
    return "-" + title.replace(/[^a-zA-Zа-яА-ЯёЁ]/gi, "-");
};

export function isEmail(email){
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

export function isAdminPanelUser(){
    return !!localStorage.getItem("jwtToken") || !!sessionStorage.getItem("jwtToken");
}

const langErrorConsoleStyle = "color: #afff01; font-weight: bold; background-color: #000;";

export function translation(object, field, lang_iso, note){
    if (!object || !field || !lang_iso || typeof object !== "object" || typeof field !== "string" || typeof lang_iso !== "string"){
        console.log("%ctranslation errror: ", langErrorConsoleStyle);
        console.log(`%cpassed object: `, langErrorConsoleStyle, object);
        console.log(`%cpassed field: ${field}`, langErrorConsoleStyle);
        console.log(`%cpassed lang_iso: ${lang_iso}`, langErrorConsoleStyle);
        if(note) console.log(`%cNote: ${note}`, langErrorConsoleStyle);
        return "undefined"; // or something else than ""
    }
    if(object[`${field}_${lang_iso}`]){
        return object[`${field}_${lang_iso}`];
    }
    return object[field];
};

export const trimTextForSnippet = (string, length) => {
    //trim to end of previous word(given the symbol = length)
    string = string.substr(0, length);
    return string
        .substr(0, Math.min(string.length, string.lastIndexOf(" ")))
        .replace(/[`~!@#$%^&*()_|+\-=?;"<>\{\}\[\]\\\/]/gi, '')
        .trimLeft()
}

export const windowResizeHandler = (setterFunction) => (debounce(200, false, () => setterFunction(window.innerWidth))());
