import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateLanguage } from '../shared/actions';
const languagesList = [
    {
        title: "English",
        iso: "en"
    },
    {
        title: "Українська",
        iso: "ua"
    },
    {
        title: "Deutsch",
        iso: "de"
    }
];

const isoToLanguageName = (iso) => {
    return languagesList.find((element) => element.iso === iso).title;
};

const LanguageSelector = (props) =>{
    const { lang, updateLanguage } = props;
    const history = useHistory();
    const pathname = useLocation().pathname;
    const [open, setOpen] = useState(false);
    const close = (e) => {
        if(!e.target.classList.contains("mditem")){
            setOpen(false);
        }
    };

    const updateLanguageHandler = (lang)=>{
        updateLanguage(lang);
        languagesList.forEach((language)=>{
            if(document.body.classList.contains(language.iso)) document.body.classList.remove(language.iso);
        });
        document.body.classList.add(lang);
        let newLocation = "/" + lang + pathname.slice(3); // improvement is required
        history.push(newLocation);
    }

    useState(()=>{
        if(pathname){
            let iso = pathname.substr(1,2);
            if(lang !== iso && iso.length && iso.length == 2) updateLanguageHandler(iso);
        }
        // updateLanguageHandler(lang);
        document.body.addEventListener("click", close);
        return ()=>document.body.removeEventListener("click", close);
    });

    return(
        <div className={`language-container language-container-mob mditem ${open}`}
            onClick={(e)=>{
                e.persist();
                e.stopPropagation();
                setOpen(state=>!state)
            }}
        >
            <div className="language-current mditem">
                <div className={`language-icon mditem`} />
            </div>
            <div className={`language-list mditem`}>
                    <div key={999}
                        className={`language-item current-item mditem ${lang}`}
                        onClick={(e)=>{
                            e.persist();
                            e.stopPropagation();
                            setOpen(state=>!state)
                        }}
                    >
                        {isoToLanguageName(lang)}
                    </div>
                {languagesList.map((language, index)=>(
                    <div key={index}
                        className={`language-item mditem ${language.iso === lang} ${language.iso}`}
                        onClick={()=>updateLanguageHandler(language.iso)}
                    >
                        {language.title}
                    </div>
                ))}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    lang: state.lang
});

const mapDispatchToProps = (dispatch) => ({
    updateLanguage: (lang) => {
        document.body.classList.remove("popup-is-opened");
        dispatch(updateLanguage(lang))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);