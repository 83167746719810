import React, { Component, useRef, useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import logoFooter from '../assets/images/logo_green.svg';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { isEmail } from '../tools';
import { translation  } from '../tools';
import localization from '../localization';

const edpMailchimp = "/api/mailchimp";

function Footer(props){
    const { link_fb, link_instagram, link_linkedin, link_twitter, homePage, lang} = props;
    const [inputActive, setInputActive] = useState(false);
    const itemRef = useRef();

    let [placeHolder, setPlaceHolder] = useState(localization[lang].EMAIL_ADDRESS_HERE);
    let [email, setEmail] = useState("");
    let [inputError, setInputError] = useState(false); 
    let [sent, setSent ] = useState(false);

    if(placeHolder !== localization[lang].EMAIL_ADDRESS_HERE ) setPlaceHolder(localization[lang].EMAIL_ADDRESS_HERE);

    const handleInput = (e) => {
        setEmail(e.target.value);
        setInputError(false);
        if(e.target.value.length){
            setInputActive(true);
        } else {
            setInputActive(false);
        }
    };

    const handleSunmit = async (e) => {
        if (!isEmail(email)){
            setInputError(true);
        } else {
            let reCaptchaToken = await window.grecaptcha.execute('6LfJZtcZAAAAAKVCDIKUkzB0LfnsgPCF5CKmi6Po', {action: 'subscribe'});
            let body = {
                "reCaptchaToken": reCaptchaToken,
                "email": email
            };

            let response = await fetch(edpMailchimp, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            response = await response.json();
            if(response && response.error !== true){
            //    setPlaceHolder(response.message);
                setEmail(response.message);
                setSent(true);
                setTimeout(() => {
                    setSent(false);
                    setEmail('');
                    // just for rerendering component when we have a success sent action status,and user already seen this status
                }, 3500)
            } 
            else if(response && response.error == true) {
                setEmail(response.message);
                setInputError(true);
            }
        }
    };

    const setMaxHeight = ()=>{
        if(inputActive){
            itemRef.current.style.maxHeight = itemRef.current.scrollHeight + 15 + 'px';
        } else {
            itemRef.current.style.maxHeight = '0px';
        }
    }

    useEffect(()=>{
        setMaxHeight();
        window.addEventListener("resize", setMaxHeight, false);
        return ()=>{
            window.removeEventListener("resize", setMaxHeight, false);
        }
    });


    return(
        <section className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 logo logo-col">
                        <NavLink to={`/${lang}/`} className="logo-footer"><img src={logoFooter} /></NavLink>
                        <ReactMarkdown
                            className={`copyright copyright-col hide-mobile hide-tablet`}
                            source={translation(homePage, "copyright", lang)}
                            escapeHtml={false}
                        />
                    </div>
                    <div className={`col-12 col-sm-6 col-md-6 col-lg-5 subscribe-col`}>
                        <div className={`subscribe ${sent}`}>
                            <h3>{localization[lang].SUBSCRIBE_TO_OUR_NEWSLATTER}</h3>
                            <input 
                                type="text" 
                                placeholder={placeHolder} 
                                value={email} 
                                className={`error-${inputError}`} 
                                onChange={handleInput} 
                            />
                            <div className="subscribe-button" onClick={handleSunmit}></div>
                        </div>
                        <div className={`subscribe disclaimer ${inputActive}`} ref={itemRef}>
                            <ReactMarkdown
                                className={``}
                                source={translation(homePage, "disclaimer_subscribe", lang)}
                                escapeHtml={false}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 iconsWhite icons-col">
                        <a className="facebook" target="blank" href={link_fb} rel="nofollow"></a>
                        <a className="twitter" target="blank" href={link_twitter} rel="nofollow"></a>
                        <a className="linkedin" target="blank" href={link_linkedin} rel="nofollow"></a>
                        <a className="instagram" target="blank" href={link_instagram} rel="nofollow"></a>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 copyright copyright-col hide-desktop">
                        <ReactMarkdown
                            className={``}
                            source={translation(homePage, "copyright", lang)}
                            escapeHtml={false}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

const mapStateToProps = (state) => ({
    homePage: state.homePage,
    link_fb: state.homePage.link_fb,
    link_instagram: state.homePage.link_instagram,
    link_linkedin: state.homePage.link_linkedin,
    link_twitter: state.homePage.link_twitter,
    lang: state.lang,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);