import React from 'react';
import localization from '../localization';
import { connect } from 'react-redux';



function Page404Content({lang, loading}){
    const loaded = Object.values(loading).every((val)=>val==false);
    return (
        <section className={`page-404-section ${lang}`}>
            <div className="container">
                <div className="row">
                    <div className={`col col-12 holder404 ${loaded}`}>
                        <div className="g-loader">
                            <div className="loader-logo"></div>
                            <div className="loader-line-mask">
                                <div className="loader-line"></div>
                            </div>
                        </div>
                        <div className="page404 sh" title="404">404</div>
                        <div className="page404-1 sh">{localization[lang].PAGE_404_TEXT_1}</div>
                        <div className="page404-2 sh">{localization[lang].PAGE_404_TEXT_2}</div>
                        <a className="button sh" href={`/${lang}/`}>{localization[lang].PAGE_404_TEXT_3}</a>
                    </div>
                </div>
            </div>  
        </section>
    );
}

const mapStateToProps = (state) => ({
    loading: state.loading,
    lang: state.lang
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Page404Content);