export const FETCH_HOME_PAGE = "FETCH_HOME_PAGE";
export const FETCH_VACANCIES = "FETCH_VACANCIES";
export const FETCH_NEWS = "FETCH_NEWS";
export const FETCH_LEADERSHIP = "FETCH_LEADERSHIP";
export const FETCH_GAMES = "FETCH_GAMES";
export const FETCH_CONTACTUS = "FETCH_CONTACTUS";
export const FETCH_ABOUT_GALLERY = "FETCH_ABOUT_GALLERY";
export const FETCH_LEGAL_PAGE = "FETCH_LEGAL_PAGE";
export const FETCH_PRIVACY_PAGE = "FETCH_PRIVACY_PAGE";
export const FETCH_COOKIE_PAGE = "FETCH_COOKIE_PAGE";
export const FETCH_ROLES = "FETCH_ROLES";
export const FETCH_LOCATIONS = "FETCH_LOCATIONS";
export const FETCH_TIMELINE = "FETCH_TIMELINE";
export const FETCH_NEW_TIMELINE = "FETCH_NEW_TIMELINE";
export const FETCH_CAREERS_PAGE = "FETCH_CAREERS_PAGE";
export const FETCH_NEWS_PAGE = "FETCH_NEWS_PAGE";
export const FETCH_INTERNSHIP_PAGE = "FETCH_INTERNSHIP_PAGE";

export const UPDATE_HOME_PAGE = "UPDATE_HOME_PAGE";
export const UPDATE_VACANCIES = "UPDATE_VACANCIES";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const UPDATE_LEADERSHIP = "UPDATE_LEADERSHIP";
export const UPDATE_GAMES = "UPDATE_GAMES";
export const UPDATE_CONTACTUS = "UPDATE_CONTACTUS";
export const UPDATE_ABOUT_GALLERY = "UPDATE_ABOUT_GALLERY";
export const UPDATE_LEGAL_PAGE = "UPDATE_LEGAL_PAGE";
export const UPDATE_PRIVACY_PAGE = "UPDATE_PRIVACY_PAGE";
export const UPDATE_COOKIE_PAGE = "UPDATE_COOKIE_PAGE";
export const UPDATE_ROLES = "UPDATE_ROLES";
export const UPDATE_LOCATIONS = "UPFATE_LOCATIONS";
export const UPDATE_TIMELINE = "UPFATE_TIMELINE";
export const UPDATE_NEW_TIMELINE = "UPDATE_NEW_TIMELINE";
export const UPDATE_CAREERS_PAGE = "UPDATE_CAREERS_PAGE";
export const UPDATE_NEWS_PAGE = "UPDATE_NEWS_PAGE";
export const UPDATE_INTERNSHIP_PAGE = "UPDATE_INTERNSHIP_PAGE";

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

export const FONTS_LOADING_CHECKED = "FONTS_LOADING_CHECKED";


/* fetch actiions */
export const fetchHomePage = () => ({
    type: FETCH_HOME_PAGE
});

export const fetchVacancies = () => ({
    type: FETCH_VACANCIES
});

export const fetchNews = () => ({
    type: FETCH_NEWS
});

export const fetchLeadership = () => ({
    type: FETCH_LEADERSHIP
});

export const fetchGames = () => ({
    type: FETCH_GAMES
});

export const fetchContactUs = () => ({
    type: FETCH_CONTACTUS
});

export const fetchAboutGallery = () => ({
    type: FETCH_ABOUT_GALLERY
});

export const fetchLegalPage = () => ({
    type: FETCH_LEGAL_PAGE
});

export const fetchPrivacyPage = () => ({
    type: FETCH_PRIVACY_PAGE
});

export const fetchCookiePage = () => ({
    type: FETCH_COOKIE_PAGE
});

export const fetchRoles = () => ({
    type: FETCH_ROLES
});

export const fetchLocations = () => ({
    type: FETCH_LOCATIONS
});

export const fetchTimeline = () => ({
    type: FETCH_TIMELINE
});

export const fetchNewTimeline = () => ({
    type: FETCH_NEW_TIMELINE
});

export const fetchCareersPage = () => ({
    type: FETCH_CAREERS_PAGE
});

export const fetchNewsPage = () => ({
    type: FETCH_NEWS_PAGE
});

export const fetchInternshipPage = () => ({
    type: FETCH_INTERNSHIP_PAGE
});

/****** UPDATE STORE ACTION *********/
export const updateHomePage = (homePage) => ({
    type: UPDATE_HOME_PAGE,
    payload: homePage
});

export const updateVacancies = (vacancies) => ({
    type: UPDATE_VACANCIES,
    payload: vacancies
});

export const updateNews = (news) => ({
    type: UPDATE_NEWS,
    payload: news
});

export const updateLeadership = (leadership) => ({
    type: UPDATE_LEADERSHIP,
    payload: leadership
});

export const updateGames = (games) => ({
    type: UPDATE_GAMES,
    payload: games
});

export const updateContactUs = (contactUsPage) => ({
    type: UPDATE_CONTACTUS,
    payload: contactUsPage
});

export const updateAboutGallery = (aboutGallery) => ({
    type: UPDATE_ABOUT_GALLERY,
    payload: aboutGallery
});

export const updateLegalPage = (legalPage) => ({
    type: UPDATE_LEGAL_PAGE,
    payload: legalPage
});

export const updatePrivacyPage = (privacyPage) => ({
    type: UPDATE_PRIVACY_PAGE,
    payload: privacyPage
});

export const updateCookiePage = (cookiePage) => ({
    type: UPDATE_COOKIE_PAGE,
    payload: cookiePage
});

export const updateRoles = (rooles) => ({
    type: UPDATE_ROLES,
    payload: rooles
});

export const updateLocations = (locations) => ({
    type: UPDATE_LOCATIONS,
    payload: locations
});

export const updateTimeline = (timeline) => ({
    type: UPDATE_TIMELINE,
    payload: timeline    
});

export const updateNewTimeline = (newTimeline) => ({
    type: UPDATE_NEW_TIMELINE,
    payload: newTimeline,
});

export const updateCareersPage = (careersPage) => ({
    type: UPDATE_CAREERS_PAGE,
    payload: careersPage
});

export const updateNewsPage = (newsPage) => ({
    type: UPDATE_NEWS_PAGE,
    payload: newsPage
});

export const updateInternshipPage = (internshipPage) => ({
    type: UPDATE_INTERNSHIP_PAGE,
    payload: internshipPage
});

// --------------- ---------------- //
export const updateLanguage = (language) => ({
    type: UPDATE_LANGUAGE,
    payload: language
});

export const fontsLoadingChecked = () => ({
    type: FONTS_LOADING_CHECKED,
});
