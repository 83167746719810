import React, { useState, useRef, useEffect } from "react";
import { useParams, NavLink, Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../components/Header";
import Header2 from "../components/Header2";
import Footer from "../components/Footer";
import LatestNews from "../components/LatestNews";
import localization from "../localization";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  InstapaperShareButton,
} from "react-share";
import ReactMarkdown from "react-markdown";
import { titleToUrl, isEmail, translation, trimTextForSnippet } from "../tools";
import moment from "moment";
import { Parallax } from "react-parallax";
import paralaxBg from "../assets/images/bg_newsletter.png";
import ScrollAnimation from "react-animate-on-scroll";
import MetaTags from "react-meta-tags";
import Timeline from "../components/Timeline";
import Page404Content from "../components/Page404Content";
import * as actions from "../shared/actions";
import store from "../shared/store";
import { Loader } from "../components/Loader";
import * as Sentry from "@sentry/react";

const tempUrl = process.env.REACT_APP_URL || "";
const edpMailchimp = "/api/mailchimp";

/* MAIN COMPONENT */
function NewsPage(props) {
  /* NEWSLETTER BEGIN */
  let [placeHolder, setPlaceHolder] = useState(
    localization[props.lang].EMAIL_ADDRESS_HERE
  );
  let [email, setEmail] = useState("");
  let [inputError, setInputError] = useState(false);
  let [sent, setSent] = useState(false);
  let [activeTagName, setActiveTagName] = useState("#All");
  const [inputActive, setInputActive] = useState(false);
  const itemRef = useRef();

  useEffect(() => {
    store.dispatch(actions.fetchNewsPage());
    store.dispatch(actions.fetchTimeline());
    store.dispatch(actions.fetchNewTimeline());
  }, []);

  const setMaxHeight = () => {
    try {
      if (inputActive) {
        itemRef.current.style.maxHeight =
          itemRef.current.scrollHeight - itemRef.current.scrollHeight + "px";
      } else {
        itemRef.current.style.maxHeight = "0px";
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    setMaxHeight();
    window.addEventListener("resize", setMaxHeight, false);
    return () => {
      window.removeEventListener("resize", setMaxHeight, false);
    };
  });

  const callBack = (name) => {
    // console.log("active tag name in NewsPage: ", setActiveTagName);
    // console.log("incoming tag name in NewsPage: ", name);
    setActiveTagName(name);
    setTimeout(() => {
      window.scrollTo(0, window.scrollY + 1);
    }, 100);
  };
  const handleInput = (e) => {
    setEmail(e.target.value);
    setInputError(false);
    if (e.target.value.length) {
      setInputActive(true);
    } else {
      setInputActive(false);
    }
  };

  const handleSunmit = async (e) => {
    if (!isEmail(email)) {
      setInputError(true);
    } else {
      let reCaptchaToken = await window.grecaptcha.execute(
        "6LfJZtcZAAAAAKVCDIKUkzB0LfnsgPCF5CKmi6Po",
        { action: "subscribe" }
      );
      let body = {
        reCaptchaToken: reCaptchaToken,
        email: email,
      };

      let response = await fetch(edpMailchimp, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      response = await response.json();
      if (response && response.error !== true) {
        //    setPlaceHolder(response.message);
        setEmail(response.message);
        setSent(true);
      } else if (response && response.error == true) {
        setEmail(response.message);
        setInputError(true);
      }
    }
  };

  /* NEWSLETTER END */

  const { slug } = useParams();
  const {
    news,
    lang,
    timeline,
    newTimeline,
    showInactive,
    newsPage,
    homePage,
    fontsLoadingCheck,
  } = props;
  let articleIndex = null;
  let articleTitle = null;
  let slugId;

  console.log("slug", slug);
  console.log("news", news);

  // news.forEach((element, index) => {
  //     if ( (element.id + titleToUrl(element.title)) == slug ) {
  //         articleIndex = index;
  //         articleTitle = title;
  //     }
  // });

  if (slug) {
    slugId = slug.split("-").shift();
  }

  console.log("slugId", slugId);

  if (news) {
    news.forEach((element, index) => {
      if (element.id == slugId) {
        articleIndex = index;
        articleTitle = element.title;
      }
    });
  }

  if (articleIndex !== null && (news[articleIndex].active || showInactive)) {
    let nextIndex = news[articleIndex + 1] ? articleIndex + 1 : 0;
    return (
      <React.Fragment>
        <Header />
        {/*
                <section className="article-navigation">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 article-navigation-container">
                                <NavLink to={`/${lang}/news`} className="back-link">&#x2190; {localization[lang].BUTTON_BACK}</NavLink>
                                <div className="v-divider"></div>
                                <NavLink to={`/${lang}/news/${news[nextIndex].id + titleToUrl(news[nextIndex].title)}`} className="shorten-2">
                                <span>{localization[lang].NEXT_IS} </span> {translation(news[nextIndex], "title", lang)}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </section>
                */}
        <Article
          lang={lang}
          article={news[articleIndex]}
          callBack={callBack}
          timeline={timeline}
          newTimeline={newTimeline}
        />

        <section className="newsletter">
          <Parallax bgImage={paralaxBg} strength={300}>
            <div className="container">
              <div className="row">
                <div className="col-12 newsletter-container">
                  <div className="title">
                    {localization[lang].SUBSCRIBE_TO_OUR_NEWSLATTER}
                  </div>
                  <div className="description">
                    {localization[lang].SUBSCRIBE_TEXT}
                  </div>
                  <div className={`subscribe ${sent}`}>
                    <input
                      type="text"
                      placeholder={placeHolder}
                      value={email}
                      className={`error-${inputError}`}
                      onChange={handleInput}
                    />
                    <div
                      className="subscribe-button"
                      onClick={handleSunmit}
                    ></div>
                  </div>
                  <div
                    className={`subscribe disclaimer d350 ${inputActive}`}
                    ref={itemRef}
                  >
                    <ReactMarkdown
                      className={``}
                      source={translation(
                        homePage,
                        "disclaimer_subscribe",
                        lang
                      )}
                      escapeHtml={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        </section>

        <LatestNews callBack={callBack} />
        <Footer />
      </React.Fragment>
    );
  } else if (
    articleIndex !== null &&
    !(news[articleIndex].active || showInactive)
  ) {
    Sentry.captureException({
      ARTICLE_FOUND_BUT_INACTIVE: "",
      initialSlug: slug,
      articleIndex: articleIndex,
      articleTitle: articleTitle ? articleTitle : "matching title not found",
      articleTitleAfterRegexCall: articleTitle
        ? titleToUrl(articleTitle)
        : "matching title not found",
    });

    return (
      <React.Fragment>
        <Header />
        {/* ARTICLE FOUND BUT INACTIVE */}
        <Page404Content lang={lang} />
        <Footer />
      </React.Fragment>
    );
  } else if (slug && !articleIndex) {
    if (news.length) {
      Sentry.captureException({
        ARTICLE_IS_NOT_FOUND: "",
        initialSlug: slug,
        articleIndex: "matching index not found",
        articleTitle: "matching title not found",
      });
    }
    return (
      <React.Fragment>
        <Header />
        {/* <Header2 /> */}
        {/* ARTICLE NOT FOUND */}
        <Page404Content lang={lang} />
        <Footer />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Header />
        <MetaTags>
          {newsPage.meta_title ? (
            <>
              <title>{translation(newsPage, "meta_title", lang)}</title>
              <meta
                id="meta-title"
                name="title"
                content={translation(newsPage, "meta_title", lang)}
              />
              <meta
                id="og-title"
                property="og:title"
                content={translation(newsPage, "meta_title", lang)}
              />
              <meta
                id="twitter-title"
                name="twitter:title"
                content={translation(newsPage, "meta_title", lang)}
              />
            </>
          ) : (
            <>
              <title>{translation(newsPage, "title", lang)}</title>
              <meta
                id="meta-title"
                name="title"
                content={translation(newsPage, "title", lang)}
              />
              <meta
                id="og-title"
                property="og:title"
                content={translation(newsPage, "title", lang)}
              />
              <meta
                id="twitter-title"
                name="twitter:title"
                content={translation(newsPage, "title", lang)}
              />
            </>
          )}
          {newsPage.meta_description ? (
            <>
              <meta
                id="meta-description"
                name="description"
                content={translation(newsPage, "meta_description", lang)}
              />
              <meta
                id="og-description"
                property="og:description"
                content={translation(newsPage, "meta_description", lang)}
              />
              <meta
                id="twitter-description"
                name="twitter:description"
                content={translation(newsPage, "meta_description", lang)}
              />
            </>
          ) : (
            <>
              <meta
                id="meta-description"
                name="description"
                content={translation(newsPage, "title", lang)}
              />
              <meta
                id="og-description"
                property="og:description"
                content={translation(newsPage, "title", lang)}
              />
              <meta
                id="twitter-description"
                name="twitter:description"
                content={translation(newsPage, "title", lang)}
              />
            </>
          )}
          <link rel="canonical" />
          {/* <meta id='twitter-card' name="twitter:card" content="summary_large_image" /> */}
          <meta id="og-url" property="og:url" content={window.location.href} />
          <meta
            id="twitter-url"
            name="twitter:url"
            content={window.location.href}
          />
        </MetaTags>
        {/*<loader loaded={fontsLoadingCheck} />*/}
        {/* <Header2 /> */}
        {/* LIST OF ARTICLES WITH PAGINATION */}
        <Loader loaded={fontsLoadingCheck} />
        {news ? (
          <NewsList
            showInactive={showInactive}
            lang={lang}
            news={news}
            activeTagName={activeTagName}
            callBack={callBack}
          />
        ) : (
          ""
        )}
        <Footer />
      </React.Fragment>
    );
  }
}

function Article(props) {
  const { article, callBack, lang, timeline, newTimeline } = props;
  let shareUrl = window.location.href;
  let history = useHistory();

  const setTagIndexByName = (name) => {
    callBack(name);
    // console.log("tag name: ", name);
    // console.log("history: ", history);
    history.push(`/${lang}/news`);
  };

  const imgUrl = article.sharing_preview
    ? window.location.origin + "/api" + article.sharing_preview.url
    : window.location.origin + "/api" + article.poster.url;

  let currentTimeline;
  if (timeline && article.id === timeline.article_id)
    currentTimeline = timeline;
  else if (newTimeline && article.id === newTimeline.article_id)
    currentTimeline = newTimeline;

  return (
    <section className={`article ${currentTimeline ? "wide" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <MetaTags>
              {article.meta_title ? (
                <>
                  <title>{translation(article, "meta_title", lang)}</title>
                  <meta
                    id="meta-title"
                    name="title"
                    content={translation(article, "meta_title", lang)}
                  />
                  <meta
                    id="og-title"
                    property="og:title"
                    content={translation(article, "meta_title", lang)}
                  />
                  <meta
                    id="twitter-title"
                    name="twitter:title"
                    content={translation(article, "meta_title", lang)}
                  />
                </>
              ) : (
                <>
                  <title>{translation(article, "title", lang)}</title>
                  <meta
                    id="meta-title"
                    name="title"
                    content={translation(article, "title", lang)}
                  />
                  <meta
                    id="og-title"
                    property="og:title"
                    content={translation(article, "title", lang)}
                  />
                  <meta
                    id="twitter-title"
                    name="twitter:title"
                    content={translation(article, "title", lang)}
                  />
                </>
              )}

              {article.meta_description ? (
                <meta
                  id="meta-description"
                  name="description"
                  content={translation(article, "meta_description", lang)}
                />
              ) : (
                <meta
                  id="meta-description"
                  name="description"
                  content={translation(article, "title", lang)}
                />
              )}

              {article.short_description ? (
                <>
                  <meta
                    id="og-description"
                    property="og:description"
                    content={translation(article, "short_description", lang)}
                  />
                  <meta
                    id="twitter-description"
                    name="twitter:description"
                    content={translation(article, "short_description", lang)}
                  />
                </>
              ) : (
                <>
                  <meta
                    property="og:description"
                    content={trimTextForSnippet(
                      translation(article, "content", lang),
                      130
                    )}
                  />
                  <meta
                    id="twitter-description"
                    name="twitter:description"
                    content={translation(article, "title", lang)}
                  />
                </>
              )}
              <meta id="og-type" property="og:type" content="article" />
              <meta
                id="og-url"
                property="og:url"
                content={window.location.href}
              />
              <meta
                id="twitter-card"
                name="twitter:card"
                content="summary_large_image"
              />
              <meta
                id="twitter-url"
                name="twitter:url"
                content={window.location.href}
              />
              <meta id="twitter-image" name="twitter:image" content={imgUrl} />
              <meta id="og-image" property="og:image" content={imgUrl} />
              <meta
                id="og-published_time"
                property="article:published_time"
                content={article.created_at}
              />
              <link rel="canonical" />
            </MetaTags>
            <h1 className="title">{translation(article, "title", lang)}</h1>
            <img
              src={tempUrl + "/api" + article.poster.url}
              className="poster"
            />
            <div className="article-info">
              {article.tags &&
                article.tags.map((tag) => (
                  <span
                    className="tag"
                    key={tag.id}
                    onClick={() => setTagIndexByName(tag.title)}
                  >
                    {translation(tag, "title", lang)}
                  </span>
                ))}
              {article.createed_date ? (
                lang == "ua" ? (
                  <div className="date">
                    {moment(article.createed_date).format("D.MM.YYYY")}
                  </div>
                ) : (
                  <div className="date">
                    {moment(article.createed_date).format("D MMM, YYYY")}
                  </div>
                )
              ) : lang == "ua" ? (
                <div className="date">
                  {moment(article.created_at).format("D.MM.YYYY")}
                </div>
              ) : (
                <div className="date">
                  {moment(article.created_at).format("D MMM, YYYY")}
                </div>
              )}
            </div>

            <ReactMarkdown
              linkTarget="_blank"
              className={`article-content`}
              source={translation(article, "content", lang)}
              escapeHtml={false}
            />

            {currentTimeline && (
              <Timeline timeline={currentTimeline} lang={lang} />
            )}

            <div className="share-title">{localization[lang].TITLE_SHARE}</div>
            <div className="share-buttons2">
              <FacebookShareButton
                url={shareUrl}
                quote={article.title}
                className="facebook"
              ></FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                title={article.title}
                className="twitter"
              ></TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                title={article.title}
                className="linkedin"
              ></LinkedinShareButton>
              {/*<InstapaperShareButton
                                url={shareUrl}
                                title={article.title}
                                className="instagram"
                            >
                            </InstapaperShareButton>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function NewsList(props) {
  function get_domain_from_url(url) {
    let a = document.createElement("a");
    a.setAttribute("href", url);
    return a.hostname;
  }

  let { news, activeTagName, callBack, lang, showInactive } = props;
  let [page, setPage] = useState(1);
  // let [ activeTagIndex, setActiveTagIndex ] = useState(0);
  news = news.filter((value) => value.active || showInactive);
  news = news.filter((value) =>
    value.tags.some((tag) => tag.title === "#News")
  );
  console.log(news);
  const limit = 4;
  const length = news.length;
  const maxPages = Math.ceil(length / limit);

  const handleLoadMore = () => {
    if (page < maxPages) {
      setPage(++page);
    }
  };

  const extractTags = (articles) => {
    let tags = [];
    articles.forEach((article) => {
      article.tags.forEach((tag) => {
        let index = tags.findIndex((element) => element.title === tag.title);
        if (index < 0) tags.push(tag);
      });
    });

    tags = tags.sort((value1, value2) => {
      return "" + value1.title.localeCompare(value2.title);
    });

    tags.unshift({
      title: localization["en"].TAGS_ALL,
      id: 1000,
      title_de: localization["de"].TAGS_ALL,
      title_ua: localization["ua"].TAGS_ALL,
    });

    return tags;
  };

  let newsToShow =
    activeTagName !== "#All"
      ? news.filter((article) => {
          let showArticle = false;
          article.tags.forEach((tag) => {
            if (tag.title == activeTagName) showArticle = true;
          });
          return showArticle && article.active;
        })
      : news.filter((n) => n.active || showInactive);

  newsToShow = newsToShow
    .sort(
      (value1, value2) =>
        "" + value2.created_at.localeCompare(value1.created_at)
    )
    .slice(0, page * limit);

  return (
    <section className="news-list">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>{localization[lang].PAGE_TITLE_GUNZILLA_PRESS}</h1>
            <div className="news-grid">
              {newsToShow.map((article, index) => (
                <ScrollAnimation
                  animateIn="animate__zoomIn"
                  animateOut="animate__zoomOut"
                  delay={index * 100}
                  animateOnce={true}
                  key={article.id}
                >
                  <div className="card">
                    {!article.link ? (
                      <React.Fragment>
                        <NavLink
                          to={`/${lang}/news/${
                            article.id + titleToUrl(article.title)
                          }`}
                        >
                          <div className="animation-image-zoom">
                            <img src={tempUrl + "/api" + article.poster.url} />
                          </div>
                          <div className="container1">
                            {article.createed_date ? (
                              lang == "ua" ? (
                                <div className="date">
                                  {moment(article.createed_date).format(
                                    "D.MM.YYYY"
                                  )}
                                </div>
                              ) : (
                                <div className="date">
                                  {moment(article.createed_date).format(
                                    "D MMM, YYYY"
                                  )}
                                </div>
                              )
                            ) : lang == "ua" ? (
                              <div className="date">
                                {moment(article.created_at).format("D.MM.YYYY")}
                              </div>
                            ) : (
                              <div className="date">
                                {moment(article.created_at).format(
                                  "D MMM, YYYY"
                                )}
                              </div>
                            )}
                            {/* lang === "ua" ? 
                                                            <div className="date">{moment(article.created_at).format("D.MM.YYYY")}</div>
                                                        :
                                                            <div className="date">{moment(article.created_at).format("D MMM, YYYY")}</div>
                                                        */}
                            <h2>{translation(article, "title", lang)}</h2>
                            {/*<ReactMarkdown
                                                            className={`desc`}
                                                            source={article.short_description}
                                                            escapeHtml={false}
                                                        />*/}
                          </div>
                        </NavLink>
                      </React.Fragment>
                    ) : (
                      // external
                      <React.Fragment>
                        <a target="_blank" href={article.link} rel="nofollow">
                          <div
                            className="animation-image-zoom"
                            style={{ position: "relative" }}
                          >
                            <img src={tempUrl + "/api" + article.poster.url} />
                            <div className="external_resource">
                              {article.externa_icon && (
                                <img
                                  src={`${tempUrl}/api${article.externa_icon.url}`}
                                  width="30px"
                                  height="30px"
                                  style={{ marginRight: "10px" }}
                                />
                              )}
                              {article.external_label}
                            </div>
                          </div>
                          <div className="container1">
                            {article.createed_date ? (
                              lang == "ua" ? (
                                <div className="date">
                                  {moment(article.createed_date).format(
                                    "D.MM.YYYY"
                                  )}
                                </div>
                              ) : (
                                <div className="date">
                                  {moment(article.createed_date).format(
                                    "D MMM, YYYY"
                                  )}
                                </div>
                              )
                            ) : lang == "ua" ? (
                              <div className="date">
                                {moment(article.created_at).format("D.MM.YYYY")}
                              </div>
                            ) : (
                              <div className="date">
                                {moment(article.created_at).format(
                                  "D MMM, YYYY"
                                )}
                              </div>
                            )}
                            <h2>{translation(article, "title", lang)}</h2>
                            {/*<ReactMarkdown
                                                            className={`desc`}
                                                            source={article.short_description}
                                                            escapeHtml={false}
                                                        />*/}
                          </div>
                        </a>
                      </React.Fragment>
                    )}
                  </div>
                </ScrollAnimation>
              ))}
            </div>
            <div
              className={`button ${newsToShow.length >= page * limit}`}
              onClick={handleLoadMore}
            >
              {localization[lang].BUTTON_LOAD_MORE}
            </div>
            <div className="press-wrapper">
              <div className="press-block">
                <h1>
                  <div className="pr-icon" />
                  <div>PR CONTACTS</div>
                </h1>
                <div className="press-block__text">
                  If you would like to request a quote, article, or anything
                  else from our public relations team, please get in touch with
                  us today.
                </div>
                <a className="fullSize" href="mailto:pr@gunzillagames.com">
                  <div className={`button fullSize`}>CONTACT</div>
                </a>
              </div>
              <div className="press-block">
                <h1>
                  <div className="pr-kit-icon" />
                  <div>PRESS KIT</div>
                </h1>
                <div className="press-block__text">
                  For information about the game and assets, download our press
                  kit.
                </div>
                <a
                  className="fullSize"
                  target="blank"
                  href={
                    "https://files.gunzillagames.com/GunzillaGamesPressKit.zip"
                  }
                  rel="nofollow"
                >
                  <div className={`button fullSize`}>DOWNLOAD</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  news: state.news,
  lang: state.lang,
  newsPage: state.newsPage,
  showInactive: state.showInactive,
  timeline: state.timeline,
  newTimeline: state.newTimeline,
  homePage: state.homePage,
  fontsLoadingCheck: state.fontsLoadingCheck,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);
