import React, { useRef, useEffect, useState, useCallback } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { NewsSliderClone } from '../components/NewsSliderClone';
import moment from 'moment';
import { translation, titleToUrl } from '../tools';
import localization from '../localization';
import ScrollAnimation from 'react-animate-on-scroll';
import { windowResizeHandler } from '../tools';

const tempUrl = "";

function LatestNews(props){
    const { news, callBack, lang, showInactive } = props; // minor bug is here

    const [ currentWindowWidth, setCurrentWindowWidth ] = useState(null);
    const [ maxHeightCardTextValue, setMaxHeightCardTextValue ] = useState(null);
    const [ maxHeightCardTagsValue, setMaxHeightCardTagsValue ] = useState(null);
    
    const newWithoutCurrent = news.filter((value)=>{
        return window.location.pathname.indexOf(titleToUrl(value.title)) >= 0 ? false : true;
    });

    useEffect(() => {
        const listener = () => windowResizeHandler(setCurrentWindowWidth);
        window.addEventListener('resize', listener);

        return () => {
            window.removeEventListener('resize', listener);
        }
    })
   
    let newsToShow = newWithoutCurrent.filter((value) => (value.active || showInactive));
    
    newsToShow = newsToShow.sort((value1, value2)=>('' + value2.created_at.localeCompare(value1.created_at)));

    
    let maxTextHeightInCard = null;
    let maxTagsHeightInCard = null;
    
    const setMaxTextheightInCard = useCallback((textHeightValue, tagsHeightValue, index, stopConditionIndex = newsToShow.length - 1 ) => {
        maxTextHeightInCard = textHeightValue > maxTextHeightInCard ? textHeightValue : maxTextHeightInCard;
        maxTagsHeightInCard = tagsHeightValue > maxTagsHeightInCard ? tagsHeightValue : maxTagsHeightInCard;
    
        if (stopConditionIndex === index) {
            setMaxHeightCardTextValue(maxTextHeightInCard);
            setMaxHeightCardTagsValue(maxTagsHeightInCard);
        }
    },[news]);

    return(
        <section className="latest-news">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>{localization[lang].SECTION_TITLE_LATEST_NEWS}</h2>
                        <NewsSliderClone
                            cols={4}
                            gap={20}
                            rows={1}
                            initialHeight={420}
                            className="news-slider"
                            moreLink="/news"
                            moreTitle={localization[lang].BUTTON_MORE_ARTICLES}
                            currentWindowWidth={currentWindowWidth}
                           maxHeightCardTextValue={maxHeightCardTextValue}
                            maxHeightCardTagsValue={maxHeightCardTagsValue}
                        >
                           { newsToShow.map((article, index) => {
                                return (
                                    <Article
                                        lang={lang}
                                        article={article}
                                        index={index}
                                        key={article.id}
                                        callBack={callBack}
                                        setMaxTextheightInCard={setMaxTextheightInCard}
                                        maxHeightCardTextValue={maxHeightCardTextValue}
                                        maxHeightCardTagsValue={maxHeightCardTagsValue}
                                 />
                                )
                            }) }
                        </NewsSliderClone> 
                    </div>
                </div>
            </div>
        </section>
    );
}

/////////////////////////////////////////////////////////////////////////

const Article = (props) => {
    function get_domain_from_url(url) {
        let a = document.createElement('a');
        a.setAttribute('href', url);
        return a.hostname;
    }

    const { article, index, callBack, lang, setMaxTextheightInCard, maxHeightCardTextValue, maxHeightCardTagsValue } = props;
    let history = useHistory();
   
    const cardRef = useRef(null);
    const textContainerRef = useRef(null);
    const tagsContainerRef = useRef(null);

    const imageLoadedHandler = () => {
        if(!maxHeightCardTextValue && !maxHeightCardTagsValue && textContainerRef.current && tagsContainerRef.current)
        setMaxTextheightInCard(textContainerRef.current.clientHeight, tagsContainerRef.current.clientHeight, index)
    };

    let textStyle = {
        height: `${maxHeightCardTextValue}px`,
    };

    let tagsStyle = {
        height: `${maxHeightCardTagsValue}px`
    };
    
    return(
        <ScrollAnimation animateIn='animate__zoomIn'animateOut='animate__zoomOut' delay={index * 200} animateOnce={true} className={'animationCardWrap'}>
            <div className="news-card" key={index} ref={cardRef}>
                {!article.link ?
                    <NavLink to={`/${lang}/news/${article.id + titleToUrl(article.title)}`} className="link">
                        <div className="animation-image-zoom">
                            <img onLoad={imageLoadedHandler} src={tempUrl + '/api' + article.poster_additional.url} />
                        </div>
                        <div className="container1">
                            <div ref={tagsContainerRef} className="tags" style={tagsStyle} onClick={(e)=>{
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                e.preventDefault();
                            }}>
                                {article.tags && article.tags.map((tag)=>(
                                    <span 
                                        className="tag"
                                        key={tag.id} 
                                        onClick={()=>{
                                            if(callBack){
                                                callBack(tag.title);
                                                history.push(`/${lang}/news`);
                                            } else {
                                                history.push(`/${lang}/news`);
                                            }
                                        }}
                                    >
                                        {translation(tag, "title", lang)}
                                    </span>
                                ))}
                            </div>
                            <div className="title" style={textStyle} ref={textContainerRef}>
                                {translation(article, "title", lang)}
                            </div>
                        </div>
                    </NavLink>
                :
                    <a target='_blank' href={article.link} className="link" rel="nofollow">
                        <div className="poster animation-image-zoom" style={{position: "relative"}}>
                            <img onLoad={imageLoadedHandler} src={tempUrl + '/api' + article.poster_additional.url} />
                            <div className="external_resource">
                                {article.externa_icon && <img src={`/api${article.externa_icon.url}`} width="30px" height="30px" style={{width: "30px", marginRight: "10px"}} />}
                                {article.external_label}
                            </div>
                        </div>
                        <div className="container1">
                            <div className="tags" style={tagsStyle}>
                                {article.tags && article.tags.map((tag)=>(
                                    <span className="tag" key={tag.id} >
                                        {translation(tag, "title", lang)}
                                    </span>
                                ))}
                            </div>
                            <div className="title" style={textStyle} ref={textContainerRef}>
                                {translation(article, "title", lang)}
                            </div>
                        </div>
                    </a>
                }
            </div>
        </ScrollAnimation>
    );
}

const mapStateToProps = (state) => ({
    news: state.news,
    lang: state.lang,
    showInactive: state.showInactive,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(LatestNews);
