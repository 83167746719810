import React, { Component } from 'react';
import { connect } from 'react-redux';
import localization from '../localization';
import Header from '../components/Header';
import Header2 from '../components/Header2';
import Footer from '../components/Footer';
import RadioGroup from '../components/RadioGroup';
import ReactMarkdown from 'react-markdown';
import { isEmail, translation } from '../tools';
import ScrollAnimation from 'react-animate-on-scroll';
import MetaTags from 'react-meta-tags';
import * as actions from "../shared/actions";
import { Loader } from "../components/Loader";
import store from "../shared/store";

const edpContactUs = "/api/contact-us/";

class ContactUsPage extends Component{
    constructor(props){
        super(props);
        this.itemRef = React.createRef();
        this.radioClickHandler = this.radioClickHandler.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setMaxHeight = this.setMaxHeight.bind(this);
        this.setInputActive = this.setInputActive.bind(this);
    }

    setMaxHeight(){
        if(this.state.inputActive){
            this.itemRef.current.style.maxHeight = this.itemRef.current.scrollHeight + 'px';
        } else {
            this.itemRef.current.style.maxHeight = '0px';
        }
    }

    setInputActive(val){
        this.setState({...this.state, inputActive: val});
    }

    componentDidMount(){
        store.dispatch(actions.fetchContactUs());
        this.setMaxHeight();
        window.addEventListener("resize", this.setMaxHeight, false);
    }
    componentWillUnmount(){
        window.removeEventListener("resize", this.setMaxHeight, false);
    }


    state = {
        inputActive: false,
        fields: {
            name: {
                placeholder: localization[this.props.lang].FIELD_PLACEHOLDER_YOUR_NAME,
                value: "",
                error: false
            },
            email: {
                placeholder: localization[this.props.lang].FIELD_PLACEHOLDER_YOUR_EMAIL,
                value: "",
                error: false
            },
            message: {
                placeholder: localization[this.props.lang].FIELD_PLACEHOLDER_YOUR_MESSAGE,
                value: "",
                error: false
            },
        },
        department: null,
        showMessage: false,
    }
    
    radioClickHandler(item){
        this.setState({
            ...this.state,
            department: item
        });
    }

    handleInput(e){
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.target.name]: {
                    ...this.state.fields[e.target.name],
                    value: e.target.value,
                    error: false
                }
            }
        }, ()=>{
        //    console.log(this.state);
        });
    }

    async handleSubmit(){
        // console.log(this.state);
        // form validation
        let nameE = false;
        let emailE = false;
        let messageE = false;

        if (this.state.fields.name.value.length < 3 || this.state.fields.name.value.length > 50) nameE = true;
        if (!isEmail(this.state.fields.email.value)) emailE = true;
        if (this.state.fields.message.value.length < 3 || this.state.fields.name.value.length > 500) messageE = true;

        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                name: {
                    ...this.state.fields.name,
                    error: nameE
                },
                email: {
                    ...this.state.fields.email,
                    error: emailE
                },
                message: {
                    ...this.state.fields.message,
                    error: messageE
                },
            },
        });

        if (!nameE && !emailE && !messageE){
            let reCaptchaToken = await window.grecaptcha.execute('6LfJZtcZAAAAAKVCDIKUkzB0LfnsgPCF5CKmi6Po', {action: 'submit'});
            let body = {
                "reCaptchaToken": reCaptchaToken,
                "name": this.state.fields.name.value,
                "email": this.state.fields.email.value,
                "message": this.state.fields.message.value,
                "department": JSON.stringify(this.state.department)
            };

            let response = await fetch(edpContactUs, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            response = await response.json();
            let respMess = JSON.parse(response.message);
            //console.log(respMess);
            //console.log(response);

            if (respMess.response.indexOf("250 Ok") !== -1){
                this.setState({
                    ...this.state,
                    fields: {
                        name: {
                            placeholder: "Your full name",
                            value: "",
                            error: false
                        },
                        email: {
                            placeholder: "Your email address",
                            value: "",
                            error: false
                        },
                        message: {
                            placeholder: "Your message",
                            value: "",
                            error: false
                        },
                    },
                    showMessage: true
                }, ()=>{
                    setTimeout(()=>{
                        this.setState({showMessage: false});
                    }, 5000);
                });
            }
        } else {
            // show error message
        }

    }

    componentDidUpdate(){
        if (!this.state.department && this.props.email_group) this.setState({...this.state, department: this.props.email_group[0]});
    }

    render(){
        const { title, description, email_group, link_title, link_text, link_url, lang, contactUsPage, fontsLoadingCheck } = this.props;
        console.log(this.props);
        // console.log("this.state: ", this.state);
        return(
            <React.Fragment>
                <MetaTags>
                {contactUsPage.meta_title ? 
                    <>
                        <title>{translation(contactUsPage, "meta_title", lang)}</title>
                        <meta id="meta-title" name="title" content={translation(contactUsPage, "meta_title", lang)} />
                        <meta id="og-title" property="og:title" content={translation(contactUsPage, "meta_title", lang)} />
                        <meta id="twitter-title" property={translation(contactUsPage, "meta_title", lang)} /> 
                    </>
                    :
                    <>
                        <title>{translation(contactUsPage, "title", lang)}</title>
                        <meta id="meta-title" name="title" content={translation(contactUsPage, "title", lang)} />
                        <meta id="og-title" property="og:title" content={translation(contactUsPage, "title", lang)} />
                        <meta id="twitter-title" property={translation(contactUsPage, "title", lang)} /> 
                    </>
                }
                {contactUsPage.meta_description ?
                    <>
                        <meta id="meta-description" name="description" content={translation(contactUsPage, "meta_description", lang)} />
                        <meta id="og-description" property="og:description" content={translation(contactUsPage, "meta_description", lang)} /> 
                        <meta id="twitter-description" property={translation(contactUsPage, "meta_description", lang)} /> 
                    </>
                    :
                    <>
                        <meta id="meta-description" name="description" content={translation(contactUsPage, "title", lang)} />
                        <meta id="og-description" property="og:description" content={translation(contactUsPage, "title", lang)} /> 
                        <meta id="twitter-description" property={translation(contactUsPage, "title", lang)} /> 
                    </>
                }
                <link rel="canonical" />  
                <meta id="og-url" property="og:url" content={window.location.href} />
                <meta id="twitter-url" property="twitter:url" content={window.location.href} />
                </MetaTags>
                <Header />
                {/* <Header2 /> */}
                <Loader loaded={fontsLoadingCheck} />
                <section className="contact-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6" animateIn='animate__fadeInDown'animateOut='animate__fadeOutUp' animateOnce={false} >
                                    <h1>{translation(contactUsPage, "title", lang)}</h1>
                                    <ScrollAnimation animateIn='animate__fadeInUp' animateOut='animate__fadeOuDown' animateOnce={true} >
                                        <ReactMarkdown
                                            className={`description`}
                                            source={translation(contactUsPage, "Description", lang)}
                                            escapeHtml={false}
                                        />
                                    </ScrollAnimation>
                            </div>
                        </div>
                        <div className="row justify-content-between">
                            <ScrollAnimation className="col-12 col-sm-12 col-md-12 col-lg-6" animateIn='animate__fadeInUp' animateOut='animate__fadeOuDown' animateOnce={true} >
                                    <div className="form-holder">
                                        <RadioGroup lang={lang} items={this.props.email_group} cb={this.radioClickHandler} title={contactUsPage.short_title} />
                                        <div className="form-content">
                                            <input 
                                                type="text" 
                                                name="name" 
                                                value={this.state.fields.name.value}
                                                autoComplete="off"
                                                placeholder={this.state.fields.name.placeholder} 
                                                className={`field error-${this.state.fields.name.error}`} 
                                                onChange={this.handleInput}
                                                onFocus={()=>this.setInputActive(true)} 
                                                onBlur={()=>this.setInputActive(false)}
                                            />
                                            <input 
                                                type="text"
                                                name="email" 
                                                value={this.state.fields.email.value} 
                                                autoComplete="off"
                                                placeholder={this.state.fields.email.placeholder} 
                                                className={`field error-${this.state.fields.email.error}`} 
                                                onChange={this.handleInput}
                                                onFocus={()=>this.setInputActive(true)} 
                                                onBlur={()=>this.setInputActive(false)}
                                            />
                                            <textarea 
                                                rows={4} 
                                                name="message" 
                                                value={this.state.fields.message.value} 
                                                placeholder={this.state.fields.message.placeholder} 
                                                className={`field meessage error-${this.state.fields.message.error}`} 
                                                onChange={this.handleInput}
                                                onFocus={()=>this.setInputActive(true)} 
                                                onBlur={()=>this.setInputActive(false)}
                                            />
                                            <div className={`disclaimer ${!!this.state.fields.name.value || !!this.state.fields.email.value || !!this.state.fields.message.value}`} ref={this.itemRef}>
                                                <ReactMarkdown
                                                    className={``}
                                                    source={translation(this.props.homePage, "disclaimer_contactus", lang)}
                                                    escapeHtml={false}
                                                />
                                            </div>
                                            <button className="button link-button" onClick={this.handleSubmit} >{localization[lang].BUTTON_SEND_MESSAGE}</button>
                                            <div className={`message-info ${this.state.showMessage}`} >
                                                <div className="title">{localization[lang].MESSAGE_THANKS_1}</div>
                                                <div className="description">{localization[lang].MESSAGE_THANKS_2}</div>
                                            </div>
                                        </div>
                                    </div>
                            </ScrollAnimation>

                            <ScrollAnimation className="col-12 col-sm-12 col-md-12 col-lg-6 contact-links" animateIn='animate__fadeInRight'animateOut='animate__fadeOutLeft' animateOnce={true} delay={500} duration={1} >
                                    <div className="row">
                                        {email_group && email_group.map((element, index)=>(
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 clink" key={index} >
                                                <h6>{translation(element, "long_title", lang)}</h6>
                                                <a target="blank" href={`mailto:${element.email}`}>{element.email}</a>
                                            </div>
                                        ))}
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 clink">
                                            <h6>{translation(contactUsPage, "link_title", lang)}</h6>
                                            <a target="blank" href={link_url}>{translation(contactUsPage, "link_text", lang)}</a>
                                        </div>
                                    </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    email_group: state.contactUsPage.email_group,
    contactUsPage: state.contactUsPage,
    lang: state.lang,
    title: state.contactUsPage.title,
    description: state.contactUsPage.Description,
    link_title: state.contactUsPage.link_title,
    link_text: state.contactUsPage.link_text,
    link_url: state.contactUsPage.link_url,
    homePage: state.homePage,
    fontsLoadingCheck: state.fontsLoadingCheck,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);