import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ReactMarkdown from 'react-markdown';
import { translation } from '../tools';
import MetaTags from 'react-meta-tags';
import * as actions from "../shared/actions";
import store from "../shared/store";
import { Loader } from "../components/Loader";

function Content(props){
    const { cookiePage, lang, fontsLoadingCheck } = props;
    let { hash } = props.location;

    useEffect(()=> {
      store.dispatch(actions.fetchCookiePage());
    },[]);

    let elementToScroll = 0;
    if (hash){
        setTimeout(()=>{
            elementToScroll = document.getElementById(hash.slice(1));
            if (elementToScroll && elementToScroll.offsetTop){
                window.scrollTo(0, elementToScroll.offsetTop - 64);
            }
        }, 800);
    }

    return(
        <React.Fragment>
            <Header />
            <MetaTags>
            {cookiePage.meta_title ? 
                <>
                    <title>{translation(cookiePage, "meta_title", lang)}</title>
                    <meta id="meta-title" name="title" content={translation(cookiePage, "meta_title", lang)} />
                    <meta id="og-title" property="og:title" content={translation(cookiePage, "meta_title", lang)} />
                    <meta id="twitter-title" property={translation(cookiePage, "meta_title", lang)} /> 
                </>
                :
                <>
                    <title>{translation(cookiePage, "title", lang)}</title>
                    <meta id="meta-title" name="title" content={translation(cookiePage, "title", lang)} />
                    <meta id="og-title" property="og:title" content={translation(cookiePage, "title", lang)} />
                    <meta id="twitter-title" property={translation(cookiePage, "title", lang)} /> 
                </>
            }
            {cookiePage.meta_description ?
                <>
                    <meta id="meta-description" name="description" content={translation(cookiePage, "meta_description", lang)} />
                    <meta id="og-description" property="og:description" content={translation(cookiePage, "meta_description", lang)} /> 
                    <meta id="twitter-description" property={translation(cookiePage, "meta_description", lang)} /> 
                </>
                :
                <>
                    <meta id="meta-description" name="description" content={translation(cookiePage, "title", lang)} />
                    <meta id="og-description" property="og:description" content={translation(cookiePage, "title", lang)} /> 
                    <meta id="twitter-description" property={translation(cookiePage, "title", lang)} /> 
                </>
            }
            <link rel="canonical" />  
            <meta id="og-url" property="og:url" content={window.location.href} />
            <meta id="twitter-url" property="twitter:url" content={window.location.href} />
            </MetaTags>
            <Loader loaded={fontsLoadingCheck}/>
            <section className={`cms ${props.className && props.className}`}>
                <div className="container">
                    <div className="row">
                        <div className="col col-ms-12">
                            {cookiePage.title && <h1>{translation(cookiePage, "title", lang)}</h1>}
                            {cookiePage.content &&
                                <ReactMarkdown
                                    className={`text`}
                                    source={translation(cookiePage, "content", lang)}
                                    escapeHtml={false}
                                />
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    cookiePage: state.cookiePage,
    lang: state.lang,
    fontsLoadingCheck: state.fontsLoadingCheck,
});

export default connect(mapStateToProps, null)(Content);