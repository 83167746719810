import React, {useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ReactMarkdown from 'react-markdown';
import { translation, windowResizeHandler } from '../tools';
import MetaTags from 'react-meta-tags';
import localization from '../localization';
import { Loader } from "../components/Loader";

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup, Dot } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import ScrollAnimation from 'react-animate-on-scroll';

import { Parallax } from 'react-parallax';
import paralaxBg from '../assets/images/KV_073641.jpg';

import ApplyForm from '../components/ApplyForm';

function Content(props){
    const { internshipPage, lang, showInactive, fontsLoadingCheck } = props;
    const [showForm, setShowForm] = useState(false);
    const [containerHeight, setContainerHeight] = useState(0);
    const [childrenHeight, setChildrenHeight] = useState(0);
    const [currentWindowWidth, setCurrentWindowWidth] = useState(0);

    // console.log(currentWindowWidth);

    useEffect(() => {
        const listener = () => windowResizeHandler(setCurrentWindowWidth);
        window.addEventListener('resize', listener);

        return () => {
            window.removeEventListener('resize', listener);
        }
    });

    const containerRef = useRef();
    const child1Ref = useRef();
    const child2Ref = useRef();

    const child1RowRef = useRef(null);
    const child2RowRef = useRef(null);

    useEffect(()=>{
        if(internshipPage.active || showInactive ){
            const highestRowHeight = child2RowRef.current.offsetHeight > child1RowRef.current.offsetHeight 
                ? child2RowRef.current.offsetHeight : child1RowRef.current.offsetHeight;        
            child1Ref.current.style.height = highestRowHeight + "px";
            child2Ref.current.style.height = highestRowHeight + "px";
            containerRef.current.style.height = highestRowHeight + "px";
        }
    });
    
    if(internshipPage.active || showInactive){
        return(
            <React.Fragment>
                <Header />
                <MetaTags>
                {internshipPage.meta_title ? 
                    <>
                        <title>{translation(internshipPage, "meta_title", lang)}</title>
                        <meta id="meta-title" name="title" content={translation(internshipPage, "meta_title", lang)} />
                        <meta id="og-title" property="og:title" content={translation(internshipPage, "meta_title", lang)} />
                        <meta id="twitter-title" property={translation(internshipPage, "meta_title", lang)} /> 
                    </>
                    :
                    <>
                        <title>{translation(internshipPage, "title", lang)}</title>
                        <meta id="meta-title" name="title" content={translation(internshipPage, "title", lang)} />
                        <meta id="og-title" property="og:title" content={translation(internshipPage, "title", lang)} />
                        <meta id="twitter-title" property={translation(internshipPage, "title", lang)} /> 
                    </>
                }
                {internshipPage.meta_description ?
                    <>
                        <meta id="meta-description" name="description" content={translation(internshipPage, "meta_description", lang)} />
                        <meta id="og-description" property="og:description" content={translation(internshipPage, "meta_description", lang)} /> 
                        <meta id="twitter-description" property={translation(internshipPage, "meta_description", lang)} /> 
                    </>
                    :
                    <>
                        <meta id="meta-description" name="description" content={translation(internshipPage, "title", lang)} />
                        <meta id="og-description" property="og:description" content={translation(internshipPage, "title", lang)} /> 
                        <meta id="twitter-description" property={translation(internshipPage, "title", lang)} /> 
                    </>
                }
                <link rel="canonical" />
                <meta id="og-url" property="og:url" content={window.location.href} />
                <meta id="twitter-url" property="twitter:url" content={window.location.href} />
                </MetaTags>
                <section className={`cms internship ${props.className && props.className}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col col-ms-12">
                                {internshipPage.title && <h1>{translation(internshipPage, "title", lang)}</h1>}
                                {internshipPage.header_content &&
                                    <ScrollAnimation animateIn='animate__fadeInUp' animateOut='animate__fadeOutDown' animateOnce={true} animatePreScroll={true} duration={1} >
                                        <ReactMarkdown
                                            className={`text text_header_content`}
                                            source={translation(internshipPage, "header_content", lang)}
                                            escapeHtml={false}
                                        />
                                    </ScrollAnimation>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="container desktop">
                        <div className="row">
                            <div className="col col-ms-12">
                                <div className="internship-cards">
                                    {internshipPage.intern_cards ? internshipPage.intern_cards.map((value, index)=><InternshipCard key={index} lang={lang} card={value} index={index} />) : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mobile">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="internship-cards hide-tablet">
                                    <CarouselProvider
                                        style={{maxWidth:"100%"}}
                                        totalSlides={8}
                                        infinite={true}
                                        visibleSlides={1}
                                        step={1}
                                        naturalSlideWidth={`300px`}
                                        naturalSlideHeight={`600px`}
                                        isIntrinsicHeight
                                    >
                                        <Slider>
                                            {internshipPage.intern_cards ? internshipPage.intern_cards.map((value, index)=>(
                                                <Slide index={index} key={index}>
                                                    <InternshipCardMob lang={lang} card={value} index={index} />
                                                </Slide>
                                            )) : ""}
                                        </Slider>
                                        <DotGroup className="dotes" />
                                    </CarouselProvider>
                                </div>
                                <div className="internship-cards hide-mobile hide-desktop">
                                    <CarouselProvider
                                        style={{maxWidth:"100%"}}
                                        totalSlides={8}
                                        infinite={true}
                                        visibleSlides={2}
                                        step={1}
                                        naturalSlideWidth={`300px`}
                                        naturalSlideHeight={`600px`}
                                        isIntrinsicHeight
                                    >
                                        <Slider>
                                            {internshipPage.intern_cards ? internshipPage.intern_cards.map((value, index)=>(
                                                <Slide index={index} key={index}>
                                                    <InternshipCardMob lang={lang} card={value} index={index} />
                                                </Slide>
                                            )) : ""}
                                        </Slider>
                                        <DotGroup className="dotes" />
                                    </CarouselProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="newsletter internship">
                    <Parallax bgImage={paralaxBg} strength={300}>
                        <div className="container-wrapper" ref={containerRef}>
                            <div className={`inter-wrapper ${showForm}`} >
                                <div className="p60">
                                    <div className="container" ref={child1Ref}>
                                        <div className="row" ref={child1RowRef}>
                                            <div className="col-12 newsletter-container">
                                                {internshipPage.header_content &&
                                                    <ScrollAnimation animateIn='animate__fadeInUp' animateOut='animate__fadeOutDown' animateOnce={true} animatePreScroll={true} duration={1} >
                                                        <ReactMarkdown
                                                            className={`text text_main_content`}
                                                            source={translation(internshipPage, "main_content", lang)}
                                                            escapeHtml={false}
                                                        />
                                                        <div 
                                                            className="button"
                                                            onClick={()=>{
                                                                setShowForm(true);
                                                            }}
                                                        >{localization[lang].APPLY_HERE}</div>
                                                    </ScrollAnimation>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dark-popup-bg" ref={child2Ref}>
                                    <div className="p60">
                                        <div className="container">
                                            <div className="row" ref={child2RowRef}>
                                                <div className="col-12">
                                                    <div className="vacancy-additional"></div>
                                                </div>
                                                <div className="col-12 form-holder-horizontal">
                                                    <div className="close-white" onClick={()=>{setShowForm(false);}}></div>
                                                    <ApplyForm jdId={internshipPage.lever_id} discipline={true} lang={lang} />
                                                </div>
                                                <div className="col-12">
                                                    <div className="vacancy-additional"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Parallax>
                </section>
                <section className="faq internship">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <h1>{localization[lang].FAQ}</h1>
                            </div>
                            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                                <div className="faq-container">
                                    {internshipPage.internship_faq && internshipPage.internship_faq.map((item, index)=>{
                                        return (
                                            <FaqItem
                                                title={translation(item, "title", lang)}
                                                content={translation(item, "content", lang)}
                                                key={index}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    } else {
        return(
            <React.Fragment>
                <Loader loaded={fontsLoadingCheck} />
                <Header />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <br /><br /><br /><br /><br /><br />
                                {/* Page loading */}
                                <br /><br /><br /><br /><br /><br /><br /><br /><br />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

function FaqItem(props){
    const [opened, setOpened] = useState(false);
    const {title, content} = props;
    const itemRef = useRef();

    const handleClick = ()=>{
        setOpened(!opened);
    }

    useEffect(()=>{
        if(opened){
            itemRef.current.style.maxHeight = itemRef.current.scrollHeight + 'px';
        } else {
            itemRef.current.style.maxHeight = '0px';
        }
        
    });

    return (
        <div className={`faq-item ${opened}`} onClick={handleClick}>
            <div className="title">
                {title}
            </div>
            <div className="desc" ref={itemRef} onClick={(e)=>{e.stopPropagation()}}>
                <div>
                    {content}
                </div>
            </div>
        </div>
    );
}

function InternshipCard(props){
    const {card, index, lang} = props;
    const imgUrl = "/api" + card.image.url;
    return(
        <div className="card flip-box" key={index}>
            <div className="flip-box-inner">
                <div className="content flip-box-front">
                    <img width="80" height="80" src={imgUrl} />
                    <div className="title">{translation(card, "title", lang)}</div>
                </div>
                <div className="hover flip-box-back">
                    <div className="title">{translation(card, "title", lang)}</div>
                    <div className="desc">{translation(card, "desc", lang)}</div>
                </div>
            </div>
        </div>
    );
}

function InternshipCardMob(props){
    const {card, index, lang} = props;
    const imgUrl = "/api" + card.image.url;
    return(
        <div className="card mob" key={index}>
            <div className="content">
                <img width="80" height="80" src={imgUrl} />
                <div className="title">{translation(card, "title", lang)}</div>
                <div className="desc">{translation(card, "desc", lang)}</div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    internshipPage: state.internshipPage,
    lang: state.lang,
    showInactive: state.showInactive,
    fontsLoadingCheck: state.fontsLoadingCheck,
});

export default connect(mapStateToProps, null)(Content);