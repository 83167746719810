import React from 'react';

 const Checkbox = (props) => {
    const { checked, callback, children, widthLimitation, applyFormParent, disabled } = props;
    return(
        <div
            className={`checkbox${checked ? " checked" : ""}${applyFormParent ? ' applyForm-checkbox':''}${disabled ? ' disabled':''}`}
            onClick={() => disabled ? '' : callback()}
        >
            <div className="icon"></div>
            <div className={`checkbox-title${widthLimitation ? ' widthLimitation':''}${applyFormParent  ? ' applyForm-checkbox-title':''}`}>
                {children}
            </div>
        </div>
    );
};

export default Checkbox;