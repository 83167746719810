import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { isEmail, translation } from "../tools";
import ReactMarkdown from "react-markdown";
import localization from "../localization";
import { fetchContactUsPage } from "../shared/api";
import SelectSimple from "../components/SelectSimple";
import Checkbox from "./Checkbox";
import * as Sentry from "@sentry/react";

const LEVER_GUNZILLA_URL = "https://";

const ApplyForm = (props) => {
  const { lang, jdId, homePage, discipline } = props;
  const [sent, setSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [sentError, setSentError] = useState(false);
  const [filename, setFilename] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState(false);
  const [disclaimerMessage, setDisclaimerMessage] = useState(false);
  const [subscription, setSubscription] = useState(true);
  //For right UseEffect working in dropDown menu(<SimpleSelect/>). Count ++ makes
  // the component reset once. When count ++ again - useEffect trigger once again;
  const [resetSelectMenuCount, setResetSelectMenuCount] = useState(0);

  const disciplinesList = props.disciplines
    ? Array.from(props.disciplines)
    : [];
  const [disciplineIndex, setDisciplineIndex] = useState(0);
  disciplinesList.unshift({ title: localization[lang].SELECT_DISCIPLINE });
  const [disciplineError, setDisciplineError] = useState(false);

  const disciplineRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const portfolioRef = useRef();
  const fileRef = useRef();
  const uploadRef = useRef();

  const [inputActive, setInputActive] = useState(false);
  const itemRef = useRef();

  const setMaxHeight = () => {
    if (inputActive) {
      itemRef.current.style.maxHeight =
        itemRef.current.scrollHeight + 15 + "px";
    } else {
      itemRef.current.style.maxHeight = "0px";
    }
  };

  const setDeisciplinIndexHandler = (ind) => {
    setDisciplineError(false);
    setDisciplineIndex(ind);
  };

  const handleInputFirstName = (event) => {
    event.target.classList.remove("error-true");
    setDisclaimerMessage(
      !!event.target.value ||
        !!lastNameRef.current.value ||
        !!emailRef.current.value ||
        !!portfolioRef.current.value
    );
  };
  const handleInputLastName = (event) => {
    event.target.classList.remove("error-true");
    setDisclaimerMessage(
      !!event.target.value ||
        !!firstNameRef.current.value ||
        !!emailRef.current.value ||
        !!portfolioRef.current.value
    );
  };
  const handleInputEmail = (event) => {
    event.target.classList.remove("error-true");
    setDisclaimerMessage(
      !!event.target.value ||
        !!firstNameRef.current.value ||
        !!lastNameRef.current.value ||
        !!portfolioRef.current.value
    );
  };

  const handleInputPortfolio = (event) => {
    event.target.classList.remove("error-true");
    setDisclaimerMessage(
      !!event.target.value ||
        !!firstNameRef.current.value ||
        !!lastNameRef.current.value ||
        !!emailRef.current.value
    );
  };

  const handleMailSubscription = () => setSubscription(!subscription);

  const handleInputFile = (event) => {
    if (fileRef.current.files[0]) {
      let checkResult = false;
      const AVAILABLE_FORMATS = ["doc", "docx", "pdf", "txt"];
      AVAILABLE_FORMATS.forEach((value) => {
        let index = fileRef.current.files[0].name.split(".").length;
        if (
          fileRef.current.files[0].name.split(".")[index - 1].toLowerCase() ==
          value
        )
          checkResult = true;
      });
      if (checkResult) {
        setFilename(fileRef.current.files[0].name);
        setFileErrorMessage(false);
      } else {
        uploadRef.current.classList.add("true");
        fileRef.current.value = "";
        setFilename("");
      }
    } else {
      fileRef.current.value = "";
      setFilename("");
    }
  };
  const handleClickFileSelect = () => {
    fileRef.current.click();
  };

  const handleClearFile = () => {
    fileRef.current.value = "";
    setFilename("");
  };

  const handleForm = async () => {
    let errors = [];
    let ready = true;
    let formObject = null;
    let fileErrorMesage = false;
    setSentError(false);

    if (discipline && !disciplineIndex) {
      console.log(" 1 error");
      errors.push("Discipline");
      ready = false;
      setDisciplineError(true);
    }
    if (firstNameRef?.current?.value?.trim().length < 2) {
      console.log(" 2 error");
      firstNameRef.current.classList.add("error-true");
      errors.push("First name");
      ready = false;
    }
    if (lastNameRef?.current?.value?.trim().length < 2) {
      console.log(" 3 error");
      lastNameRef.current.classList.add("error-true");
      errors.push("Last name");
      ready = false;
    }
    if (portfolioRef?.current?.value?.trim().length < 2) {
      console.log(" 3 error");
      portfolioRef.current.classList.add("error-true");
      errors.push("Portfolio");
      ready = false;
    }
    if (!isEmail(emailRef?.current?.value)) {
      console.log(" 4 error");
      emailRef.current.classList.add("error-true");
      errors.push("Email");
      ready = false;
    }
    if (!fileRef.current.value) {
      console.log(" 5 error");
      errors.push("File");
      uploadRef.current.classList.add("true");
      ready = false;
      fileErrorMesage = true;
    }
    if (!discipline && !jdId) {
      console.log(" 6 error");
      errors.push("JDID");
      ready = false;
    }

    if (ready) {
      formObject = new FormData();
      formObject.append(
        "name",
        firstNameRef.current.value + " " + lastNameRef.current.value
      );
      formObject.append("portfolio", portfolioRef.current.value);
      formObject.append("email", emailRef.current.value);
      formObject.append(
        "resume",
        fileRef.current.files[0],
        fileRef.current.files[0].name
      );
      formObject.append("subscription", subscription ? "1" : "0");
      if (discipline) {
        formObject.append("jdid", disciplinesList[disciplineIndex].lever_id);
      } else {
        formObject.append("jdid", jdId);
      }

      console.log(" 2 PotentialFormData", {
        name: firstNameRef.current.value + " " + lastNameRef.current.value,
        email: emailRef.current.value,
        resume: [fileRef.current.files[0], fileRef.current.files[0].name],
        subscription: subscription ? "1" : "0",
        jdid: discipline ? disciplinesList[disciplineIndex].lever_id : jdId,
      });
    } else {
      console.log("Errors: ", errors);
      fileErrorMesage && setFileErrorMessage(true);
      return;
    }

    setIsSending(true);

    // console.log('Sending formdata: ', formObject.entries());

    for (let entries of formObject.entries()) {
      console.log(entries[0] + ", " + entries[1]);
    }

    let response = await fetch(`/api/lever-bridge/`, {
      method: "POST",
      body: formObject,
    });

    response = await response.json();

    setIsSending(false);
    console.log(response);

    if (response.statusCode > 300) {
      setSentError(true);
      setSent(false);

      Sentry.captureException({
        APPLY_FORM_RESPONSE_ERROR: "",
        JobSlug: discipline
          ? disciplinesList?.[disciplineIndex]?.lever_id
          : jdId,
      });
    } else {
      setSent(true);

      if (response["message"] !== "looks like sent") {
        Sentry.captureException({
          APPLY_FORM_UNEXPECTED_RESPONSE_MESSAGE: "",
          JobSlug: discipline
            ? disciplinesList?.[disciplineIndex]?.lever_id
            : jdId,
        });
      }

      //Disable 'Thank you overlay. reset all Fields, FileHolder, and Discipline droplist.
      setTimeout(() => {
        firstNameRef.current.value = "";
        lastNameRef.current.value = "";
        emailRef.current.value = "";
        portfolioRef.current.value = "";
        setSent(false);
        handleClearFile();
        setDisciplineIndex(0);
        setResetSelectMenuCount(resetSelectMenuCount + 1);
      }, 2500);
      if (window.gtag) {
        window.gtag("event", "APPLICATION SUBMITTED", {
          page_path: window.location.pathname,
        });
      }
    }
  };

  return (
    <>
      <div
        id="resume-form"
        style={
          !jdId
            ? { paddingBottom: "1px", opacity: "0.6", pointerEvents: "none" }
            : null
        }
        className={`row section-resume-upload`}
      >
        <div className="col-sm-12 col-md-6 last">
          <div className="form-content form-holder">
            {discipline && (
              <SelectSimple
                options={disciplinesList}
                height={51}
                cb={setDeisciplinIndexHandler}
                className={`error-${disciplineError}`}
                //If 0 - don't reset. After 1 success data sent - value equal 1, first reset.
                resetCount={resetSelectMenuCount}
              />
            )}
            <input
              type="text"
              name="fn"
              className="field"
              ref={firstNameRef}
              onChange={handleInputFirstName}
              placeholder={localization[lang].LEVER_FIRST_NAME}
              onFocus={() => setInputActive(true)}
              onBlur={() => setInputActive(false)}
            />
            <input
              type="text"
              name="ln"
              className="field"
              ref={lastNameRef}
              onChange={handleInputLastName}
              placeholder={localization[lang].LEVER_LAST_NAME}
              onFocus={() => setInputActive(true)}
              onBlur={() => setInputActive(false)}
            />
            <input
              type="email"
              name="em"
              className="field"
              ref={emailRef}
              onChange={handleInputEmail}
              placeholder={localization[lang].LEVER_EMAIL}
              onFocus={() => setInputActive(true)}
              onBlur={() => setInputActive(false)}
            />
            <input
              type="text"
              name="pf"
              className="field"
              ref={portfolioRef}
              onChange={handleInputPortfolio}
              placeholder={localization[lang].LEVER_PORTFOLIO}
              onFocus={() => setInputActive(true)}
              onBlur={() => setInputActive(false)}
            />
            <Checkbox
              applyFormParent
              callback={handleMailSubscription}
              checked={subscription}
            >
              {"Subscribe to our Newsletter"}
            </Checkbox>
            <div className={`disclaimer ${disclaimerMessage}`} ref={itemRef}>
              <ReactMarkdown
                className={``}
                source={translation(homePage, "disclaimer_lever", lang)}
                escapeHtml={false}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <div className="right-part-upload">
            <h3 className="upload-text-1">
              {localization[lang].LEVER_UPLOAD_RESUME} <span>*</span>
            </h3>
            <div className="upload-text-2">
              {localization[lang].LEVER_UPLOAD_RESUME_DESCRIPTION}
            </div>
            {filename ? (
              <div className="file-upload-name" onClick={handleClearFile}>
                {filename}
              </div>
            ) : (
              <div
                className="file-upload-button"
                ref={uploadRef}
                onClick={handleClickFileSelect}
              >
                {localization[lang].LEVER_SELECT_FILE}
              </div>
            )}
            <input
              ref={fileRef}
              onChange={handleInputFile}
              type="file"
              name="resume"
              className="field"
              accept=".doc, .docx, .pdf, .txt"
              style={{ display: "none" }}
            />
          </div>
          <div className={`no-file-error-msg ${fileErrorMessage}`}>
            {localization[lang].LEVER_FILE_IS_NOT_SELECTED}
          </div>
          <div className={`no-file-error-msg ${sentError}`}>
            {localization[lang].LEVER_MESSEGE_ERROR_1}
          </div>
        </div>
        <div className="col-sm-12 last">
          <div className="button button2" onClick={handleForm}>
            {localization[lang].LEVER_SUBMIT_APPLICATION}
          </div>
        </div>

        <div className={`loader-holder ${isSending}`}>
          <div className="loader"></div>
        </div>
        <div className={`success-sent-message ${sent}`}>
          <div className="chackmark-green-big"></div>
          <div className="text1">
            {localization[lang].LEVER_MESSEGE_SUCCESS_1}
          </div>
          <div className="text2">
            {localization[lang].LEVER_MESSEGE_SUCCESS_2}
          </div>
        </div>
      </div>
      {!jdId && <LeverIdAbsenceNotificationError />}
    </>
  );
};

//lever abscense notification 'popup'
const LeverIdAbsenceNotificationError = () => {
  return (
    <div className="leverIfAbsenceNotificationError">
      Error
      <br />
      <span>
        Please, submit your CV manually to{" "}
        <span>contact@gunzillagames.com</span>
      </span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.lang,
  homePage: state.homePage,
  disciplines: state.internshipPage.departments,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ApplyForm);
