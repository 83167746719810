import { all, call, put, select, take, takeLatest, fork, cancel } from 'redux-saga/effects';
import * as api from './api';
import * as actions from './actions';

function* fetchHomePageWorker(){
    const homePage = yield call(api.fetchHomePage);
    // data validation ....
    yield put(actions.updateHomePage(homePage));
}

function* fetchLeadershipWorker(){
    let leadership = yield call(api.fetchLeadership);
    // data validation ....
    leadership = leadership.sort((element1, elemtn2)=>{
        return element1.order - elemtn2.order;    
    });
    yield put(actions.updateLeadership(leadership));
}

function* fetchVacanciesWorker(){
    const vacancies = yield call(api.fetchVacancies);
    // data validation ....
    yield put(actions.updateVacancies(vacancies));
}

function* fetchNewsWorker(){
    const news = yield call(api.fetchNews);
    // data validation ....
    yield put(actions.updateNews(news));
}

function* fetchGamesWorker(){
    const games = yield call(api.fetchGames);
    // data validation ....
    yield put(actions.updateGames(games));
}

function* fetchContactUsPageWorker(){
    const fetchedContactUsPage = yield call(api.fetchContactUsPage);
    // data validation ....
    yield put(actions.updateContactUs(fetchedContactUsPage));
}

function* fetchAboutGalleryWorker(){
    const fetchedAboutGallery = yield call(api.fetchAboutGallery);
    // data validation ....
    yield put(actions.updateAboutGallery(fetchedAboutGallery));
}

function* fetchLegalPageWorker(){
    const fetchedLegalPage = yield call(api.fetchLegalPage);
    // data validation ....
    yield put(actions.updateLegalPage(fetchedLegalPage));
}

function* fetchPrivacyPageWorker(){
    const fetchedPrivacyPage = yield call(api.fetchPrivacyPage);
    // data validation ....
    yield put(actions.updatePrivacyPage(fetchedPrivacyPage));
}

function* fetchCookiePageWorker(){
    const fetchedCookiePage = yield call(api.fetchCookiePage);
    // data validation ....
    yield put(actions.updateCookiePage(fetchedCookiePage));
}

function* fetchRolesWorker(){
    const fetchedRoles = yield call(api.fetchRoles);
    // data validation ....
    yield put(actions.updateRoles(fetchedRoles));
}

function* fetchLocationsWorker(){
    const fetchedLocations = yield call(api.fetchLocations);
    // data validation ....
    yield put(actions.updateLocations(fetchedLocations));
}

function* fetchTimelineWorker(){
    const fetchedTimeline = yield call(api.fetchTimeline);
    // data validation ....
    yield put(actions.updateTimeline(fetchedTimeline));
}

function* fetchNewTimelineWorker(){
    const fetchedNewTimeline = yield call(api.fetchNewTimeline);
    // data validation ....
    yield put(actions.updateNewTimeline(fetchedNewTimeline));
}

function* fetchCareersPageWorker(){
    const fetchedCareersPage = yield call(api.fetchCareersPage);
    // data validation ....
    yield put(actions.updateCareersPage(fetchedCareersPage));
}

function* fetchNewsPageWorker(){
    const fetchedNewsPage = yield call(api.fetchNewsPage);
    // data validation ....
    yield put(actions.updateNewsPage(fetchedNewsPage));
}

function* fetchInternshipPageWorker(){
    const fetchedInternshipPage = yield call(api.fetchInternshipPage);
    // data validation ....
    yield put(actions.updateInternshipPage(fetchedInternshipPage));
}

export default function* rootSaga(){
    yield all([
        takeLatest(actions.FETCH_HOME_PAGE, fetchHomePageWorker),
        takeLatest(actions.FETCH_LEADERSHIP, fetchLeadershipWorker),
        takeLatest(actions.FETCH_VACANCIES, fetchVacanciesWorker),
        takeLatest(actions.FETCH_NEWS, fetchNewsWorker),
        takeLatest(actions.FETCH_GAMES, fetchGamesWorker),
        takeLatest(actions.FETCH_CONTACTUS, fetchContactUsPageWorker),
        takeLatest(actions.FETCH_ABOUT_GALLERY, fetchAboutGalleryWorker),
        takeLatest(actions.FETCH_LEGAL_PAGE, fetchLegalPageWorker),
        takeLatest(actions.FETCH_PRIVACY_PAGE, fetchPrivacyPageWorker),
        takeLatest(actions.FETCH_COOKIE_PAGE, fetchCookiePageWorker),
        takeLatest(actions.FETCH_ROLES, fetchRolesWorker),
        takeLatest(actions.FETCH_LOCATIONS, fetchLocationsWorker),
        takeLatest(actions.FETCH_TIMELINE, fetchTimelineWorker),
        takeLatest(actions.FETCH_NEW_TIMELINE, fetchNewTimelineWorker),
        takeLatest(actions.FETCH_CAREERS_PAGE, fetchCareersPageWorker),
        takeLatest(actions.FETCH_NEWS_PAGE, fetchNewsPageWorker),
        takeLatest(actions.FETCH_INTERNSHIP_PAGE, fetchInternshipPageWorker),
    ]);
}