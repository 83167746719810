import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ReactMarkdown from 'react-markdown';
import { translation } from '../tools';
import MetaTags from 'react-meta-tags';
import * as actions from "../shared/actions";
import store from "../shared/store";
import { Loader } from "../components/Loader";

function Content(props){
    const { legalPage, lang, fontsLoadingCheck } = props;

    useEffect(() => {
        store.dispatch(actions.fetchLegalPage());
    },[]);

    return(
        <React.Fragment>
            <Loader loaded={fontsLoadingCheck} />
            <Header />
                <MetaTags>
                {legalPage.meta_title ? 
                    <>
                        <title>{translation(legalPage, "meta_title", lang)}</title>
                        <meta id="meta-title" name="title" content={translation(legalPage, "meta_title", lang)} />
                        <meta id="og-title" property="og:title" content={translation(legalPage, "meta_title", lang)} />
                        <meta id="twitter-title" property={translation(legalPage, "meta_title", lang)} /> 
                    </>
                    :
                    <>
                        <title>{translation(legalPage, "title", lang)}</title>
                        <meta id="meta-title" name="title" content={translation(legalPage, "title", lang)} />
                        <meta id="og-title" property="og:title" content={translation(legalPage, "title", lang)} />
                        <meta id="twitter-title" property={translation(legalPage, "title", lang)} /> 
                    </>
                }
                {legalPage.meta_description ?
                    <>
                        <meta id="meta-description" name="description" content={translation(legalPage, "meta_description", lang)} />
                        <meta id="og-description" property="og:description" content={translation(legalPage, "meta_description", lang)} /> 
                        <meta id="twitter-description" property={translation(legalPage, "meta_description", lang)} /> 
                    </>
                    :
                    <>
                        <meta id="meta-description" name="description" content={translation(legalPage, "title", lang)} />
                        <meta id="og-description" property="og:description" content={translation(legalPage, "title", lang)} /> 
                        <meta id="twitter-description" property={translation(legalPage, "title", lang)} /> 
                    </>
                }
                <link rel="canonical" />
                <meta id="og-url" property="og:url" content={window.location.href} />
                <meta id="twitter-url" property="twitter:url" content={window.location.href} />
                </MetaTags>
            <section className={`cms ${props.className && props.className}`}>
                <div className="container">
                    <div className="row">
                        <div className="col col-ms-12">
                            {legalPage.title && <h1>{translation(legalPage, "title", lang)}</h1>}
                            {legalPage.content &&
                                <ReactMarkdown
                                    className={`text`}
                                    source={translation(legalPage, "content", lang)}
                                    escapeHtml={false}
                                />
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    legalPage: state.legalPage,
    lang: state.lang,
    fontsLoadingCheck: state.fontsLoadingCheck,
});

export default connect(mapStateToProps, null)(Content);