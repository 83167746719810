import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup, Dot } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import { translation } from '../tools';
import localization from '../localization';

import ScrollAnimation from 'react-animate-on-scroll';
import { map } from 'underscore';

const tempUrl = "";

function About(props){
    const { aboutCards, aboutContent, aboutGallery, about, lang, homePage } = props;
    
    useEffect(()=>{
        if (about){
            let elementToScroll = document.getElementById("about").offsetTop - 64*2.2; // 64 - height of the top menu
            // window.scrollTo(0, elementToScroll);
            setTimeout(()=>window.scrollTo(0, elementToScroll), 50);
        } else {
            // window.scrollTo(0, 0);
        }
    });

    return(
        <section id="about" className="about">
            <div className="container desktop">
                <div className="row">
                    <div className="col-md-12">
                        <h2>{localization[lang].SECTION_TITLE_ABOUT_GUNZILLA}</h2>
                        <div className="about-cards">
                            {aboutCards ? aboutCards.map((value, index)=><AboutCard key={index} lang={lang} card={value} index={index} />) : ""}
                        </div>
                        <ScrollAnimation animateIn='animate__fadeInUp' animateOut='animate__fadeOutDown' animateOnce={true} animatePreScroll={true} duration={1} >
                            <ReactMarkdown
                                className={`about-content`}
                                source={translation(homePage, "about_content", lang)}
                                escapeHtml={false}
                            />
                        </ScrollAnimation>
                        <Gallery gallery={aboutGallery} />
                    </div>
                </div>
            </div>
            <div className="container mobile">
                <div className="row">
                    <div className="col-md-12">
                        <h2>{localization[lang].SECTION_TITLE_ABOUT_GUNZILLA}</h2>
                        <div className="about-cards about-cards hide-tablet">
                            <CarouselProvider
                                style={{maxWidth:"100%"}}
                                totalSlides={5}
                                infinite={true}
                                visibleSlides={1}
                                step={1}
                                naturalSlideWidth={`300px`}
                                naturalSlideHeight={`600px`}
                                isIntrinsicHeight
                            >
                                <Slider>
                                    {aboutCards ? aboutCards.map((value, index)=>(
                                        <Slide index={index} key={index}>
                                            <AboutCard lang={lang} card={value} index={index} />
                                        </Slide>
                                    )) : ""}
                                </Slider>
                                <DotGroup className="dotes" />
                            </CarouselProvider>
                        </div>
                        <div className="about-cards about-cards hide-mobile hide-desktop">
                            <CarouselProvider
                                style={{maxWidth:"100%"}}
                                totalSlides={5}
                                infinite={true}
                                visibleSlides={2}
                                step={1}
                                naturalSlideWidth={`300px`}
                                naturalSlideHeight={`600px`}
                                isIntrinsicHeight
                            >
                                <Slider>
                                    {aboutCards ? aboutCards.map((value, index)=>(
                                        <Slide index={index} key={index}>
                                            <AboutCard lang={lang} card={value} index={index} />
                                        </Slide>
                                    )) : ""}
                                </Slider>
                                <DotGroup className="dotes" />
                            </CarouselProvider>
                        </div>
                        <ReactMarkdown
                            className={`about-content`}
                            source={translation(homePage, "about_content", lang)}
                            escapeHtml={false}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

/* ----------------------------------------------------------------------------- */
function Gallery(props){
    const { gallery } = props;
    const [ visible, setVisible ] = useState(false);
    const [ currentIndex, setCurrentIndex ] = useState(0);

    let newGallery = [];
    const slideLimit = 6;
    let slidesCount = 1;

    if (gallery && gallery.length){
        slidesCount = Math.ceil(gallery.length / slideLimit);
        
        for(let slide = 0; slide < slidesCount; slide++){
            let slideCollection = gallery.slice(slide*slideLimit, slide*slideLimit+slideLimit);
            newGallery.push(slideCollection);
        }
    }

    const imgClickHandle = (index) =>{
        setCurrentIndex(index);
        openPopup();
    }

    const openPopup = (e)=>{
        setVisible(true);
        document.body.style.top = `-${window.scrollY}px`;
        document.body.classList.add("popup-is-opened");
    }
    const closePopup = (e)=>{
        const scrollY = -parseInt(document.body.style.top);
        document.body.classList.remove("popup-is-opened");
        window.scrollTo(0, scrollY);
        setVisible(false);
    }

    const handleNextSlide = (e)=>{
        e.persist();
        e.stopPropagation(); 

        if(currentIndex+1 >= gallery.length){
    
        } else {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevSlide = (e)=>{
        e.persist();
        e.stopPropagation(); 

        if(currentIndex-1 < 0){

        } else {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return(
        <div className="gallery-desctop hidden-sm">
            <CarouselProvider
                style={{maxWidth:"100%", width:"100%"}}
                totalSlides={slidesCount}
                infinite={true}
                visibleSlides={1}
                step={1}
                dragEnabled={false}
                isPlaying={true}
                interval={7000}
                isIntrinsicHeight               
            >
                <Slider>
                    {newGallery ? newGallery.map((slide, slideIndex)=>{
                        return(
                            <Slide className="about-gallery" index={slideIndex} key={slideIndex}>
                                {slide ? slide.map((element, imgIndex)=>{
                                    const {width, height } = element.image;
                                    const ratio = width / height > 1 ? "wide" : "narrow";
                                    return(
                                        <div className={`imgWrapper ${ratio}`} key={imgIndex}>
                                            <img width={Math.ceil(element.image.width / 1.87)} src={"/api" + element.image.url} className={`image `} key={element.image.id} onClick={()=> imgClickHandle(slideIndex*slideLimit + imgIndex) } />
                                        </div>
                                    );
                                }) : "" }
                            </Slide>
                        );
                    }) : ""}
                </Slider>
                <DotGroup className="dotes" />
            </CarouselProvider>
            <div className={`popup-new-gallery popup ${visible}`} onClick={closePopup}>
                <div className="big-picture about-pc-popup" >
                    {gallery && gallery.map((element, index)=>{
                        return(
                            <div className={`about-pc-gallery-img-wrapper ${index == currentIndex}`}>
                                <img key={index} className="image-home" src={`/api` + element.image.url} onClick={e => e.stopPropagation()} />
                                <div className="dotes">
                                    {gallery && gallery.map((value, index)=>(<div className={`dot ${index == currentIndex}`} key={index}></div>))}
                                 </div>
                            </div>
                        )
                    })}
                </div>
                {gallery && <>
                    <div className={`left ${currentIndex !== 0}`} onClick={handlePrevSlide}></div>
                    <div className={`right ${currentIndex !== gallery.length-1}`} onClick={handleNextSlide}></div>
                    <div className={`close`} onClick={closePopup}></div>
                </>}
            </div>
        </div>
    );
};

function AboutCard(props){
    const {card, index, lang} = props;
    const imgUrl = "/api" + card.image.url;
    return(
        <div className="card flip-box" key={index}>
            <div className="flip-box-inner">
                <div className="content flip-box-front" style={{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.8) 99%), url(${imgUrl})`}}>
                    <div className="title">{translation(card, "title", lang)}</div>
                    <div className="desc">{translation(card, "desc", lang)}</div>
                </div>
                <div className="hover flip-box-back">
                    <div className="title">{translation(card, "title", lang)}</div>
                    <div className="desc">{translation(card, "desc", lang)}</div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => ({
    aboutCards: state.homePage.about_cards,
    homePage: state.homePage,
    aboutGallery: state.homePage.about_gallery,
    lang: state.lang
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(About);