import { createStore, applyMiddleware  } from 'redux';
import createSagaMiddleware from 'redux-saga';
import saga from './saga';
import reducer from './reducer';
import * as actions from './actions';
// import logger from 'redux-logger'

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducer, applyMiddleware(sagaMiddleware, /*logger*/));

sagaMiddleware.run(saga);

export default store;

//---Generally located requests---
//used in all components
store.dispatch(actions.fetchHomePage());
//used in several components
store.dispatch(actions.fetchInternshipPage());
//used by 'loading' state.what is ultimately detected by 404 component.
store.dispatch(actions.fetchLocations());
store.dispatch(actions.fetchNews());
store.dispatch(actions.fetchRoles());
store.dispatch(actions.fetchVacancies());
//other requests -in the respective components.

//---List of all requests---
// store.dispatch(actions.fetchHomePage());
// store.dispatch(actions.fetchLeadership());
// store.dispatch(actions.fetchVacancies());
// store.dispatch(actions.fetchNews());
// store.dispatch(actions.fetchGames());
// store.dispatch(actions.fetchContactUs());
// store.dispatch(actions.fetchAboutGallery());
// store.dispatch(actions.fetchPrivacyPage());
// store.dispatch(actions.fetchLegalPage());
// store.dispatch(actions.fetchCookiePage());
// store.dispatch(actions.fetchRoles());
// store.dispatch(actions.fetchLocations());
// store.dispatch(actions.fetchTimeline());
// store.dispatch(actions.fetchCareersPage());
// store.dispatch(actions.fetchNewsPage());
// store.dispatch(actions.fetchInternshipPage());
