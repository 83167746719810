import React, { useState, useEffect } from 'react';
import logoHeader2 from '../assets/images/logo-green-big.svg';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { translation } from '../tools';
import { OTGPreviewSection } from "./OTGPreviewSection";
import { Gamescom2022HomeSlide } from './Gamescom2022HomeSlide/Gamescom2022HomeSlide';
import { OtgCinemaHomeSlide } from './OtgCinemaHomeSlide/OtgCinemaHomeSlide';
//swiper slider
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css'; // bundle of swiper styles

function Header2(props){
    const [ reveal1, setReveal1 ] = useState(false);
    const [ reveal2, setReveal2 ] = useState(false);
    //swiper instance
    const [swiper, setSwiper] = useState(undefined);
    //TODO: remove rerender after gc2022
    const [currentSlide, setCurrentSlide] = useState(0);

    let interval;

    useEffect(()=>{
        interval = setInterval(()=>{
            if(props.homePage){
                setTimeout(()=>{
                    setReveal1(true);
                }, 200);
                setTimeout(()=>{
                    setReveal2(true);
                }, 700);
                clearInterval(interval);
            }
        }, 200);

        return ()=>{
            clearInterval(interval);
        }
    }, [props]);

    //adding a shadow effect to white text elements (as well as the language icon) that are on a white background
    useEffect(() => {
        if (currentSlide > 1) return;

        const navList = Array.from(document.querySelectorAll('.header-nav-menu-items li'));

        const nodes = {
            currentNavItems: currentSlide === 0 ? navList : navList.reverse().slice(0, 2),
            languageIcon: document.querySelector('.language-icon.lditem')
        };

        const togglingBoxShadow = (mode) => {
             if ( mode === 'add') {
                nodes.currentNavItems.forEach(node =>  node.classList.add('withTextShadowForVisibility'));
                nodes.languageIcon.classList.add('withBoxShadowForVisibility');
                if (currentSlide === 0) {
                    nodes.currentNavItems.forEach(node => node.classList.add('withTextLighterForVisibility'));  
                }
            }
            else {
                nodes.currentNavItems.forEach(node =>  node.classList.remove('withTextShadowForVisibility'));
                nodes.languageIcon.classList.remove('withBoxShadowForVisibility');
                if (currentSlide === 0) {
                    nodes.currentNavItems.forEach(node =>  node.classList.remove('withTextLighterForVisibility'));  
                }
            }
        };

        //For disabling box shadow when 'Black' sticky header visible
        const scrollHandler = debounce(100, () => {
                if (window.scrollY > 40) {
                    togglingBoxShadow('delete');
                } else togglingBoxShadow('add');
            }
        );

        togglingBoxShadow('add');
        window.addEventListener('scroll', scrollHandler);
       
        return () => {
            togglingBoxShadow('delete');
            window.removeEventListener('scroll', scrollHandler);
        }

    }, [currentSlide]);

    const { lang, homePage, isPageLoaded } = props;

    //TODO: back to old headerSection layout after 'Year two' event
    return (
        <Swiper
            className='header2-slider'
            onInit={setSwiper}
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            speed={1200}
            onTouchStart={() => swiper.params.speed = 600}
            autoplay={{
                delay: 5000,
                disableOnInteraction: true,
            }}

            onSlideChange={() => setCurrentSlide(swiper.activeIndex)}
        >
            <SwiperSlide>
                <OtgCinemaHomeSlide lang={lang} />
            </SwiperSlide>

            {/* <SwiperSlide>
                <Gamescom2022HomeSlide lang={lang} />
            </SwiperSlide> */}

            <SwiperSlide>
                <OTGPreviewSection
                    lang={lang}
                    forwardedClass='inHeader2Slider'
                    redirectionMode='inner'
                    withoutFadeIn
                />
            </SwiperSlide>


            <SwiperSlide>
                <section id="header2" className="header2">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-12 col-lg-9">
                                <div className={`header2-logo ${reveal1}`}>
                                    {/*<a href={`/${lang}/news/25-Gunzilla-Presses-on-in-Event-filled-Second-Year`}>*/}
                                        <img src={logoHeader2} />
                                    {/*</a>*/}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="header2-text">
                                    <ReactMarkdown
                                        className={`header2-text text-reveal2 ${reveal2}`}
                                        source={translation(props.homePage, "header_content", lang)}
                                        escapeHtml={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </SwiperSlide>
        </Swiper>
    );
}

const mapStateToProps = (state) => ({
    homePage: state.homePage,
    lang: state.lang
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Header2);



// <section id="header2" className="header2">
//     <div className="container">
//         <div className="row">
//             <div className="col-xl-6 col-md-12 col-lg-9">
//                 <div className={`header2-logo ${reveal1}`}>
//                     <a href={`/${lang}/news/25-Gunzilla-Presses-on-in-Event-filled-Second-Year`}>
//                         <img src={logoHeader2} />
//                     </a>
//                 </div>
//             </div>
//         </div>
//         <div className="row">
//             <div className="col-12">
//                 <div className="header2-text">
//                     <ReactMarkdown
//                         className={`header2-text text-reveal2 ${reveal2}`}
//                         source={translation(props.homePage, "header_content", lang)}
//                         escapeHtml={false}
//                     />
//                 </div>
//             </div>
//         </div>
//     </div>
// </section>


// <div id='header2-2' className='header2--year-two'>
//     <div className={`leftSideBackgroundHandler`}/>
//     <div className='leftSideContentHandler'>
//         <div className={`title ${isPageLoaded ? 'animated' : ''}`}>Celebrating</div>
//         <div className={`highlightedTitle ${isPageLoaded ? 'animated' : ''}`} />
//         <a className={`linkWrapperOfBtn ${isPageLoaded ? 'animated' : ''}`} href={`/${lang}/news/25-Gunzilla-Presses-on-in-Event-filled-Second-Year`}>
//             <div className='redirect-button'>SEE HOW IT WAS</div>
//         </a>
//     </div>
//
//     <div className='rightSide'>
//         <div className={`banner ${isPageLoaded ? 'animated' : ''}`} />
//     </div>
// </div>

// <OTGPreviewSection withoutFadeIn />