import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import logoHeader from "../assets/images/logo_green.svg";
import Fade from "react-reveal/Fade";
import { translation } from "../tools";
import localization from "../localization";
import LanguageSelector from "../containers/LanguageSelector";
import LanguageSelectorMobile from "../containers/LanguageSelectorMobile";
import ScrollAnimation from "react-animate-on-scroll";

function Header(props) {
  const { lang, vacancies, homePage } = props;
  let vc = vacancies.length
    ? vacancies.filter((v) => v.active == true).length
    : 0;
  const [popupShow, setPopupShow] = useState(false);
  const [menu, setMenu] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [isGameSubMenuVisible, setIsGameSubMenuVisible] = useState(false);
  const [isCareerSubMenuVisible, setIsCareerSubMenuVisible] = useState(false);

  const { link_fb, link_instagram, link_linkedin, link_twitter } = props;
  const localStorageKey = "gunzilla-cookies-accepted";
  const localStorageKey_coockieAsked = "gunzilla-cookies-asked";

  const [langSelectorVisible, setLangSelectorVisible] = useState(false);

  let [cookiesAccepted, setCookiesAccepted] = useState(
    localStorage.getItem(localStorageKey) ? true : false
  );
  let [cookieAskedState, setCoockieAskedState] = useState(
    localStorage.getItem(localStorageKey_coockieAsked) ? true : false
  );

  const handleScroll = useCallback(
    (e) => {
      const is_top = window.scrollY < 40;
      if (is_top !== isTop) setIsTop(() => is_top);
    },
    [isTop]
  );

  handleScroll();

  useEffect(() => {
    setTimeout(() => {
      setPopupShow(true);
    }, 8000);

    window.addEventListener("scroll", handleScroll);

    return () => {
      document.body.style.position = "";
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isTop]);

  const startAllTrackers = () => {
    console.log("Analityca started");
    window["ga-disable-UA-176320990-1"] = false;
    if (window.gtag) {
      window.gtag("js", new Date());
      window.gtag("config", "UA-176320990-1");
    }
  };

  const stopAllTrackers = () => {
    console.log("Analityca stoped");
    window["ga-disable-UA-176320990-1"] = true;
  };

  useEffect(() => {
    let cookieAsked =
      localStorage.getItem(localStorageKey_coockieAsked) == "true";
    let cookieAccepted = localStorage.getItem(localStorageKey) == "true";

    if (cookieAsked && cookieAccepted) {
      // start all analitycs and trackers
      startAllTrackers();
    } else {
      stopAllTrackers();
    }
  });

  const handleMenu = () => {
    if (!menu) {
      setTimeout(() => {
        setLangSelectorVisible(true);
      }, 1000);
      setMenu(true);
      document.body.style.top = `-${window.scrollY}px`;
      document.body.classList.add("popup-is-opened");
    } else {
      const scrollY = -parseInt(document.body.style.top);
      document.body.classList.remove("popup-is-opened");
      window.scrollTo(0, scrollY);
      setMenu(false);
      setLangSelectorVisible(false);
    }
  };

  const handleAccept = () => {
    localStorage.setItem(localStorageKey, true);
    localStorage.setItem(localStorageKey_coockieAsked, true);
    setCoockieAskedState(true);
    setCookiesAccepted(true);
  };

  const handleDeny = () => {
    setCookiesAccepted(false);
    localStorage.setItem(localStorageKey_coockieAsked, true);
    localStorage.setItem(localStorageKey, false);
    stopAllTrackers();
    setCoockieAskedState(true);
  };

  return (
    <React.Fragment>
      <section className={`header desktop isTop-${isTop}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <NavLink to={`/${lang}/`} className="logo-footer">
                <img className="logoHeader" src={logoHeader} />
              </NavLink>
            </div>
            <div className="col-md-7 menu header-nav-menu-items">
              <ul>
                <li>
                  <NavLink to={`/${lang}/about/`} activeClassName="is-active">
                    {localization[lang].MENU_ABOUT}
                  </NavLink>
                </li>
                <li style={{ wordSpacing: "-2px" }}>
                  <div className={`language-container lditem false`}>
                    <div className="language-current lditem">
                      <NavLink
                        to={`/${lang}/off-the-grid/`}
                        activeClassName="is-active"
                      >
                        {localization[lang].MENU_GAME_NAME}
                      </NavLink>
                    </div>
                    <div className="language-list lditem">
                      <div className="language-item lditem headerDdMenuItem">
                        <NavLink
                          to={`/${lang}/off-the-grid/`}
                          activeClassName="is-active"
                        >
                          {localization[lang].MENU_GAME}
                        </NavLink>
                      </div>
                      <div className="language-item lditem headerDdMenuItem">
                        <NavLink
                          to={`/${lang}/cinema/`}
                          activeClassName="is-active"
                        >
                          {localization[lang].MENU_CINEMA}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li><NavLink to={`/${lang}/gunz/`} activeClassName='is-active'>{localization[lang].MENU_GUNZ}</NavLink></li> */}
                <li>
                  <div className={`language-container lditem false`}>
                    <div className="language-current lditem">
                      <div className="lditem">
                        <NavLink
                          to={`/${lang}/careers/`}
                          activeClassName="is-active"
                        >
                          {localization[lang].MENU_CAREERS}
                        </NavLink>
                        <span className="vc"> {vc}</span>
                      </div>
                    </div>
                    {/* <div className="language-list lditem">
                      <div className="language-item lditem headerDdMenuItem">
                        <NavLink
                          to={`/${lang}/careers/`}
                          activeClassName="is-active"
                        >
                          {localization[lang].MENU_JOBS}
                        </NavLink>
                      </div>
                      <div className="language-item lditem headerDdMenuItem">
                        <NavLink
                          to={`/${lang}/internships/`}
                          activeClassName="is-active"
                        >
                          {localization[lang].MENU_INTERNSHIPS}
                        </NavLink>
                      </div>
                    </div> */}
                  </div>
                </li>
                <li>
                  <NavLink to={`/${lang}/news/`} activeClassName="is-active">
                    {localization[lang].MENU_NEWS}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/${lang}/contact/`} activeClassName="is-active">
                    {localization[lang].MENU_CONTACT}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-md-3 lang-cell header-languages-icon">
              <LanguageSelector />
            </div>
          </div>
        </div>
        {props.children && (
          <div className="container">
            <div className="row">
              <div className="col-12">{props.children}</div>
            </div>
          </div>
        )}
      </section>
      <section className={`header mobile isTop-${isTop}`}>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <NavLink to={`/${lang}/`} className="logo-footer">
                <img className="logoHeader" src={logoHeader} />
              </NavLink>
            </div>
            <div className="col-6 mob-menu">
              <div className="burger" onClick={handleMenu}></div>
            </div>
          </div>
        </div>
      </section>
      {/* MOBILE MENU BEGIN */}
      <section className="menu-mobile mobile">
        <div className={`menu-content ${menu}`}>
          <div className="left" id="menu-menu">
            <div className="mobile-menu-logo">
              {menu && (
                <Fade delay={0}>
                  <NavLink to={`/${lang}/`} className="logo-footer">
                    <img className="logoHeader" src={logoHeader} />
                  </NavLink>
                </Fade>
              )}
            </div>
            {menu && (
              <Fade delay={0}>
                <div className="burger-close" onClick={handleMenu}></div>
              </Fade>
            )}
            <div className="menu-items">
              {menu && (
                <Fade delay={100}>
                  <NavLink
                    to={`/${lang}/about/`}
                    activeClassName="is-active"
                    onClick={handleMenu}
                  >
                    {localization[lang].MENU_ABOUT}
                  </NavLink>
                </Fade>
              )}
              {menu && (
                <Fade delay={200}>
                  <div
                    className={`submenu-container ${isGameSubMenuVisible}`}
                    onClick={() =>
                      setIsGameSubMenuVisible(!isGameSubMenuVisible)
                    }
                  >
                    <NavLink
                      style={{ wordSpacing: "-3px" }}
                      to={`/${lang}/off-the-grid/`}
                      activeClassName="is-active"
                      onClick={(e) => e.preventDefault()}
                    >
                      {localization[lang].MENU_GAME_NAME}
                      <div
                        style={{ marginLeft: "16px" }}
                        className="arrow-top"
                      ></div>
                    </NavLink>
                    <div className={`sun-menu`}>
                      <NavLink
                        to={`/${lang}/off-the-grid/`}
                        activeClassName="is-active"
                        onClick={handleMenu}
                      >
                        {localization[lang].MENU_GAME}
                      </NavLink>
                      <NavLink
                        to={`/${lang}/cinema/`}
                        activeClassName="is-active"
                        onClick={handleMenu}
                      >
                        {localization[lang].MENU_CINEMA}
                      </NavLink>
                    </div>
                  </div>
                </Fade>
              )}
              {/* {menu &&
                            <Fade delay={300} >
                                <NavLink to={`/${lang}/gunz/`} activeClassName='is-active' onClick={handleMenu} >{localization[lang].MENU_GUNZ}</NavLink>
                            </Fade>
                            } */}
              {menu && (
                <Fade delay={300}>
                  {/* <div
                    className={`submenu-container ${isCareerSubMenuVisible}`}
                    onClick={() =>
                      setIsCareerSubMenuVisible(!isCareerSubMenuVisible)
                    }
                  > */}
                  <NavLink
                    to={`/${lang}/careers/`}
                    activeClassName="is-active"
                    onClick={handleMenu}
                    // onClick={(e) => e.preventDefault()}
                  >
                    {localization[lang].MENU_CAREERS}{" "}
                    {/* <span className="vc">{vc}</span>
                      <div className="arrow-top"></div> */}
                  </NavLink>
                  {/* <div className={`sun-menu`}>
                      <NavLink
                        to={`/${lang}/careers/`}
                        activeClassName="is-active"
                        onClick={handleMenu}
                      >
                        {localization[lang].MENU_JOBS}
                      </NavLink>
                      <NavLink
                        to={`/${lang}/internships/`}
                        activeClassName="is-active"
                        onClick={handleMenu}
                      >
                        {localization[lang].MENU_INTERNSHIPS}
                      </NavLink>
                    </div> */}
                  {/* </div> */}
                </Fade>
              )}
              {menu && (
                <Fade delay={400}>
                  <NavLink
                    to={`/${lang}/news/`}
                    activeClassName="is-active"
                    onClick={handleMenu}
                  >
                    {localization[lang].MENU_NEWS}
                  </NavLink>
                </Fade>
              )}
              {menu && (
                <Fade delay={500}>
                  <NavLink
                    to={`/${lang}/contact/`}
                    activeClassName="is-active"
                    onClick={handleMenu}
                  >
                    {localization[lang].MENU_CONTACT}
                  </NavLink>
                </Fade>
              )}
              {menu && (
                <Fade delay={700}>
                  <div className="iconsWhite">
                    <a
                      className="facebook"
                      target="blank"
                      href={link_fb}
                      rel="nofollow"
                    ></a>
                    <a
                      className="twitter"
                      target="blank"
                      href={link_twitter}
                      rel="nofollow"
                    ></a>
                    <a
                      className="linkedin"
                      target="blank"
                      href={link_linkedin}
                      rel="nofollow"
                    ></a>
                    <a
                      className="instagram"
                      target="blank"
                      href={link_instagram}
                      rel="nofollow"
                    ></a>
                  </div>
                </Fade>
              )}
              {menu && (
                <div className={`element-visible ${langSelectorVisible}`}>
                  <LanguageSelectorMobile />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* MOBILE MENU END */}

      {!cookieAskedState && (
        <section className={`cookies-popup ${popupShow}`}>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                {homePage.cookies_popup && (
                  <ReactMarkdown
                    className={`text`}
                    source={translation(homePage, "cookies_popup", lang)}
                    escapeHtml={false}
                  />
                )}
              </div>
              <div className="col-md-5">
                <div className="button button2" onClick={handleAccept}>
                  {localization[lang].ACCEPT}
                </div>
                <div style={{ minWidth: "16px", width: "16px" }}></div>
                <div className="button button2 secondary" onClick={handleDeny}>
                  {localization[lang].DENY}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  link_fb: state.homePage.link_fb,
  link_instagram: state.homePage.link_instagram,
  link_linkedin: state.homePage.link_linkedin,
  link_twitter: state.homePage.link_twitter,
  homePage: state.homePage,
  lang: state.lang,
  vacancies: state.vacancies,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
