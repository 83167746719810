export default {
  en: {
    MENU_ABOUT: "ABOUT",
    MENU_GAME: "Game",
    MENU_GAME_NAME: "OFF THE GRID",
    MENU_GUNZ: "GUNZ",
    MENU_CAREERS: "CAREERS",
    MENU_NEWS: "NEWS",
    MENU_CONTACT: "CONTACT",
    MENU_JOBS: "Jobs",
    MENU_INTERNSHIPS: "Internships",
    MENU_CINEMA: "Cinema",
    SECTION_TITLE_ABOUT_GUNZILLA: "ABOUT GUNZILLA",
    SECTION_TITLE_LEADERSHIP: "LEADERSHIP",
    SECTION_TITLE_LATEST_NEWS: "LATEST NEWS",
    SECTION_TITLE_GUNZ: "Introducing GunZ",
    SUBSCRIBE_TO_OUR_NEWSLATTER: "Subscribe to our Newsletter",
    SUBSCRIBE_TEXT:
      "Be the first to learn about our industry-defining AAA game and the latest company updates",
    EMAIL_ADDRESS_HERE: "Email address here",
    SEARCH_CAREERS_PLACEHOLDER: "Search for your next career here",
    SELECT_LOCATION: "Location",
    SELECT_ROLE: "Role",
    APPLY_HERE: "APPLY HERE",
    CHECK_US_OUT_ON_LINKEDIN: "CHECK US OUT ON LINKEDIN",
    DISCOVER_MORE_VACANCIES: "Discover more vacancies",
    POSTED: "Posted",
    FULL_TIME: "Full time",
    MORE_VACANCIES: "MORE VACANCIES",
    PAGE_TITLE_GUNZILLA_NEWS: "Gunzilla news",
    PAGE_TITLE_CAREERS: "Careers",
    BUTTON_SEND_MESSAGE: "Send message",
    FIELD_PLACEHOLDER_YOUR_NAME: "Your full name",
    FIELD_PLACEHOLDER_YOUR_EMAIL: "Your email address",
    FIELD_PLACEHOLDER_YOUR_MESSAGE: "Your message",
    MESSAGE_THANKS_1: "Thanks for your message!",
    MESSAGE_THANKS_2: "We'll be sure to get back to you as soon as we can.",
    TITLE_SHARE: "Share",
    TITLE_SOCIALS_GUNZ: "Join us:",
    BUTTON_BACK: "Back",
    BUTTON_ACCEPT_COOKIES: "ACCEPT COOKIES",
    BUTTON_SHOW_MORE: "Show more",
    NEXT_IS: "Next is",
    ALL: "ALL",
    BUTTON_LOAD_MORE: "Load more",
    VACANCIES_NOT_FOUND:
      "There are no jobs for the filters you selected at the moment. You can still apply with the general application below.",
    BUTTON_EXPLORE_CAREERS: "See Open Jobs",
    LABEL_NEW: "New",
    BUTTON_FILTERS: "Filters",
    BUTTON_FILTERS_CLEAR: "Clear All",
    BUTTON_FILTERS_APPLY: "Apply",
    BUTTON_REDIRECT_TO_GUNZ: "Go to Website",
    BUTTON_SEE_ALL_LEADERSHIP: "See All",
    EXPERTISE_LEVEL: "Career Level",
    TAGS_ALL: "#All",
    FAQ: "FAQ",
    LEVER_SUBMIT_APPLICATION: "Submit",
    LEVER_FIRST_NAME: "First name *",
    LEVER_LAST_NAME: "Last name *",
    LEVER_EMAIL: "Email *",
    LEVER_PORTFOLIO: "Portfolio *",
    LEVER_UPLOAD_RESUME: "Upload resume",
    LEVER_UPLOAD_RESUME_DESCRIPTION:
      "Upload as a DOC, DOCX, HTML, PDF or TXT file (Required).",
    LEVER_SELECT_FILE: "Select Files",
    LEVER_FILE_IS_NOT_SELECTED: "Please upload the file.",
    LEVER_MESSEGE_SUCCESS_1: "Thank you!",
    LEVER_MESSEGE_SUCCESS_2: "Your application has been received.",
    LEVER_MESSEGE_ERROR_1:
      "Service is temporarily unavailable, please try again later ot try to email us in the meantime",
    LEVER_MESSEGE_ERROR_2: "",
    LEVER_EMAIL_POPUP: `By submitting this application you agree to Gunzilla Game's <a target="_blank" href="/en/privacy-policy/">Privacy Policy & Terms and Conditions</a>.`,
    BUTTON_MORE_ARTICLES: "Discover more articles",
    PAGE_404_TEXT_1: "Ooops!!",
    PAGE_404_TEXT_2: "That page doesn’t exist or is unavailable.",
    PAGE_404_TEXT_3: "Go Back to Home",
    SELECT_DISCIPLINE: "Discipline",
    ACCEPT: "Accept",
    DENY: "Deny",
    OTG_GO_TO_WEBSITE: "GO TO WEBSITE",
    OTG_WATCH_TRAILER: "WATCH TRAILER",
    OTG_HEADING:
      "TEARDROP ISLAND DESPERATELY NEEDS A SAVIOR. TOO BAD IT GOT YOU INSTEAD",
    OTG_DESCRIPTION_1:
      "Gunzilla Games presents the world’s most unhinged battle royale: Off The Grid (OTG)!",
    OTG_DESCRIPTION_2:
      "OTG delivers a brand-new AAA battle royale third-person shooter experience. Set in a dystopian cyberpunk future, 150 players duke it out across a tropical island while pursuing an immersive 60-hour narrative campaign.",
    OTG_DESCRIPTION_3:
      "OTG aims to evolve the genre by strongly emphasizing its incredible story, giving players the freedom to impact the game with each decision they make. Gamers can craft and customize their in-game items to create their perfect Zero and emerge as the victor!",
    OTG_DESCRIPTION_4: "Coming soon to PlayStation®5, Xbox® Series X|S and PC.",
    OTG_HOME_SLIDE_LEARN_MORE: "LEARN MORE",
    OTG_CINEMA_TITLE_FIRST_PART: "Welcome to",
    OTG_CINEMA_DESCRIPTION:
      "OTG Cinema blasts the universe of Gunzilla Games’ battle royale 2.0 Off The Grid into the cinematic domain of Hollywood. Produced, written and directed by Oscar-nominated director and Gunzilla’s CVO Neill Blomkamp, these stories send you on a white-knuckle ride into the dark heart of OTG. While not directly connected to the plot, OTG Cinema gives players a flavor of the game’s world before its release. Is there a glimmer of hope amid a ruthless corporate war? Watch to find out!",
    OTG_CINEMA_HOME_SLIDE_OUT_NOW: "out now",
    OTG_CINEMA_HOME_SLIDE_SEE_MORE: "WATCH NOW",
  },
  de: {
    MENU_ABOUT: "ÜBER UNS",
    MENU_GAME: "Game",
    MENU_GAME_NAME: "OFF THE GRID",
    MENU_GUNZ: "GUNZ",
    MENU_CAREERS: "KARRIERE",
    MENU_NEWS: "NEWS",
    MENU_CONTACT: "KONTAKT",
    MENU_JOBS: "Arbeitsplätze",
    MENU_INTERNSHIPS: "Praktika",
    MENU_CINEMA: "Cinema",
    SECTION_TITLE_ABOUT_GUNZILLA: "ÜBER GUNZILLA GAMES",
    SECTION_TITLE_LEADERSHIP: "FÜHRUNGSTEAM",
    SECTION_TITLE_LATEST_NEWS: "Neueste Nachrichten",
    SECTION_TITLE_GUNZ: "GunZ im Überblick",
    SUBSCRIBE_TO_OUR_NEWSLATTER: "Abonniere unseren Newsletter",
    SUBSCRIBE_TEXT:
      "Seien Sie der Erste, der mehr über unser branchenbestimmendes Spiel und die neuesten Unternehmensupdates erfährt",
    EMAIL_ADDRESS_HERE: "E-Mail-Adresse hier",
    SEARCH_CAREERS_PLACEHOLDER: "Suche hier nach deiner neuen Karriere",
    SELECT_LOCATION: "Standort",
    SELECT_ROLE: "Position",
    APPLY_HERE: "HIER BEWERBEN",
    CHECK_US_OUT_ON_LINKEDIN: "Folgen Sie uns auf LinkedIn",
    DISCOVER_MORE_VACANCIES: "Entdecke weiters Stellenangebote",
    POSTED: "Absenden",
    FULL_TIME: "Ganztägig",
    MORE_VACANCIES: "Mehr Jobs",
    PAGE_TITLE_GUNZILLA_NEWS: "GUNZILLA-NACHRICHTEN",
    PAGE_TITLE_CAREERS: "KARRIERE",
    TITLE_SOCIALS_GUNZ: "Beitreten:",
    BUTTON_SEND_MESSAGE: "Nachricht senden",
    BUTTON_REDIRECT_TO_GUNZ: "Website besuchen",
    FIELD_PLACEHOLDER_YOUR_NAME: "Deine voller Name",
    FIELD_PLACEHOLDER_YOUR_EMAIL: "Deine E-Mail-Adresse",
    FIELD_PLACEHOLDER_YOUR_MESSAGE: "Deine Nachricht",
    MESSAGE_THANKS_1: "Vielen Dank für Ihre Nachricht!",
    MESSAGE_THANKS_2:
      "Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.",
    TITLE_SHARE: "Aktie",
    BUTTON_BACK: "Zurück",
    BUTTON_ACCEPT_COOKIES: "COOKIES AKZEPTIEREN",
    BUTTON_SHOW_MORE: "Mehr anzeigen",
    NEXT_IS: "Folgende",
    ALL: "ALL",
    BUTTON_LOAD_MORE: "Mehr laden",
    VACANCIES_NOT_FOUND:
      "Derzeit sind keine Jobs für die von Ihnen ausgewählten Filter vorhanden. Sie können sich weiterhin mit der unten stehenden allgemeinen Bewerbung bewerben.",
    BUTTON_EXPLORE_CAREERS: "Siehe Offene Jobs",
    LABEL_NEW: "Neu",
    BUTTON_FILTERS: "Filters",
    BUTTON_FILTERS_CLEAR: "Clear All",
    BUTTON_SEE_ALL_LEADERSHIP: "See All",
    BUTTON_FILTERS_APPLY: "Apply",
    EXPERTISE_LEVEL: "Karrierestufe",
    TAGS_ALL: "#Alle",
    FAQ: "FAQ",
    LEVER_SUBMIT_APPLICATION: "Bewerbung",
    LEVER_FIRST_NAME: "First name *",
    LEVER_LAST_NAME: "Last name *",
    LEVER_EMAIL: "Email *",
    LEVER_PORTFOLIO: "Portfolio *",
    LEVER_UPLOAD_RESUME: "Lebenslauf hochladen",
    LEVER_UPLOAD_RESUME_DESCRIPTION:
      "Als DOC-, DOCX-, HTML-, PDF- oder TXT-Datei hochladen (erforderlich)",
    LEVER_SELECT_FILE: "Dateien auswählen",
    LEVER_FILE_IS_NOT_SELECTED: "Bitte laden Sie die Datei hoch.",
    LEVER_MESSEGE_SUCCESS_1: "Vielen Dank!",
    LEVER_MESSEGE_SUCCESS_2: "Your application has been received.",
    LEVER_MESSEGE_ERROR_1:
      "Der Dienst ist vorübergehend nicht verfügbar, bitte versuchen Sie es später erneut",
    LEVER_MESSEGE_ERROR_2: "",
    LEVER_EMAIL_POPUP: `By submitting this application you agree to Gunzilla Game's <a target="_blank" href="/de/privacy-policy/">Privacy Policy & Terms and Conditions</a>.`,
    BUTTON_MORE_ARTICLES: "Alles neu",
    PAGE_404_TEXT_1: "Hoppla!",
    PAGE_404_TEXT_2: "Diese Seite existiert nicht oder ist nicht verfügbar.",
    PAGE_404_TEXT_3: "Zurück zur Startseite",
    SELECT_DISCIPLINE: "Discipline",
    ACCEPT: "Akzeptieren",
    DENY: "Verzicht",
    OTG_GO_TO_WEBSITE: "ZUR WEBSITE",
    OTG_WATCH_TRAILER: "WATCH TRAILER",
    OTG_HEADING:
      "TEARDROP ISLAND DESPERATELY NEEDS A SAVIOR. TOO BAD IT GOT YOU INSTEAD",
    OTG_DESCRIPTION: `Gunzilla Games präsentiert Off The Grid (OTG), einen neuen AAA Battle Royale 2.0 Third Person Shooter, der in einer dystopischen Cyberpunk-Zukunft spielt. OTG ist ein BR wie kein anderes und will das Genre durch eine starke Betonung des erzählerischen Fortschritts weiterentwickeln, indem es den Spielern die Freiheit gibt, die Geschichte und das Gameplay mit jeder Entscheidung zu beeinflussen. Der Kern der Spielerfahrung ist die einzigartige Art und Weise, in der die Spieler ihre Gegenstände im Spiel herstellen, anpassen und miteinander tauschen können. In OTG kämpfen die Spieler darum, die verdeckten Firmenkämpfe der Zukunft zu überleben. Demnächst für PlayStation®5, Xbox® Series X|S und PC erhältlich.`,
    OTG_HOME_SLIDE_LEARN_MORE: "MEHR ERFAHREN",
    OTG_CINEMA_TITLE_FIRST_PART: "WILLKOMMEN IM",
    OTG_CINEMA_DESCRIPTION: `OTG Cinema bringt das Universum von Gunzilla Games' Battle Royale 2.0 Off The Grid in die filmische Welt von Hollywood. Produziert, geschrieben und inszeniert vom Oscar-nominierten Regisseur und Gunzillas CVO Neill Blomkamp, schicken dich diese Geschichten auf eine atemberaubende Reise in das dunkle Herz von OTG. OTG Cinema steht zwar nicht in direktem Zusammenhang mit der Handlung, gibt den Spielern vor der Veröffentlichung aber einen Vorgeschmack auf die Spielwelt. Gibt es inmitten des rücksichtslosen Firmenkriegs einen Hoffnungsschimmer? Schau zu und finde es heraus!`,
    OTG_CINEMA_HOME_SLIDE_OUT_NOW: "JETZT LIVE",
    OTG_CINEMA_HOME_SLIDE_SEE_MORE: "MEHR ANZEIGEN",
  },
  ua: {
    MENU_ABOUT: "Про нас",
    MENU_GAME: "Гра",
    MENU_GAME_NAME: "OFF THE GRID",
    MENU_GUNZ: "GUNZ",
    MENU_CAREERS: "Вакансії",
    MENU_NEWS: "Новини",
    MENU_CONTACT: "Контакти",
    MENU_JOBS: "Вакансії",
    MENU_INTERNSHIPS: "Стажування",
    MENU_CINEMA: "Cinema",
    SECTION_TITLE_ABOUT_GUNZILLA: "ПРО GUNZILLA GAMES",
    SECTION_TITLE_LEADERSHIP: "Наші Лідери",
    SECTION_TITLE_LATEST_NEWS: "Останні новини",
    SECTION_TITLE_GUNZ: "Знайомтесь із GunZ",
    SUBSCRIBE_TO_OUR_NEWSLATTER: "Підпишіться на розсилку",
    SUBSCRIBE_TEXT:
      "Підпишіться, щоб першими дізнатися про нашу гру, яка змінить індустрію, та щоб бути в курсі останніх новин",
    EMAIL_ADDRESS_HERE: "Введіть свою пошту",
    SEARCH_CAREERS_PLACEHOLDER: "Шукай свою наступну роботу",
    SELECT_LOCATION: "Місто",
    SELECT_ROLE: "Посада",
    APPLY_HERE: "Відгукнутися",
    CHECK_US_OUT_ON_LINKEDIN: "Підпишіться на LinkedIn",
    DISCOVER_MORE_VACANCIES: "Переглянути більше вакансій",
    POSTED: "Дата публікації",
    FULL_TIME: "Повний робочий день",
    MORE_VACANCIES: "Більше вакансій",
    PAGE_TITLE_GUNZILLA_NEWS: "Новини",
    PAGE_TITLE_CAREERS: "Вакансії",
    BUTTON_SEND_MESSAGE: "Відправити",
    FIELD_PLACEHOLDER_YOUR_NAME: "Ваше повне ім'я",
    FIELD_PLACEHOLDER_YOUR_EMAIL: "Ваша пошта",
    FIELD_PLACEHOLDER_YOUR_MESSAGE: "Ваше повідомлення",
    MESSAGE_THANKS_1: "Дякую, що звернуля до нас!",
    MESSAGE_THANKS_2: "Ми спробуємо відповісти вам якомога швидше.",
    TITLE_SHARE: "Поділитися",
    TITLE_SOCIALS_GUNZ: "Доєднатися",
    BUTTON_BACK: "Назад",
    BUTTON_ACCEPT_COOKIES: "Прийняти умови Кукіз",
    BUTTON_SHOW_MORE: "Показати більше",
    BUTTON_SEE_ALL_LEADERSHIP: "Показати більше",
    BUTTON_REDIRECT_TO_GUNZ: "Перейти на сайт",
    NEXT_IS: "Наступне",
    ALL: "ALL",
    BUTTON_LOAD_MORE: "Завантажити більше",
    VACANCIES_NOT_FOUND:
      "Наразі немає вакансій, що відповідають даним фільтрам. Ви можете податися, використавши загальну заявку нижче.",
    BUTTON_EXPLORE_CAREERS: "Переглянути вакансії",
    LABEL_NEW: "Нове",
    BUTTON_FILTERS: "Filters",
    BUTTON_FILTERS_CLEAR: "Clear All",
    BUTTON_FILTERS_APPLY: "Apply",
    EXPERTISE_LEVEL: "Рівень кар'єри",
    TAGS_ALL: "#Всі",
    FAQ: "FAQ",
    LEVER_SUBMIT_APPLICATION: "Відправити",
    LEVER_FIRST_NAME: "First name *",
    LEVER_LAST_NAME: "Last name *",
    LEVER_EMAIL: "Email *",
    LEVER_PORTFOLIO: "Portfolio *",
    LEVER_UPLOAD_RESUME: "Завантажити Резюме",
    LEVER_UPLOAD_RESUME_DESCRIPTION:
      "Завантажте файл DOC, DOCX, HTML, PDF або TXT (Обов’язково).",
    LEVER_SELECT_FILE: "Вибрати файли",
    LEVER_FILE_IS_NOT_SELECTED: "Будь ласка завантажте файл.",
    LEVER_MESSEGE_SUCCESS_1: "Дякую!",
    LEVER_MESSEGE_SUCCESS_2: "Your application has been received.",
    LEVER_MESSEGE_ERROR_1:
      "Сервіс тимчасово недоступний, будь ласка, спробуйте ще раз пізніше",
    LEVER_MESSEGE_ERROR_2: "",
    LEVER_EMAIL_POPUP: `By submitting this application you agree to Gunzilla Game's <a target="_blank" href="/ua/privacy-policy/">Privacy Policy & Terms and Conditions</a>.`,
    BUTTON_MORE_ARTICLES: "Всі новини",
    PAGE_404_TEXT_1: "Ой!",
    PAGE_404_TEXT_2: "Ця сторінка не існує, або недоступна.",
    PAGE_404_TEXT_3: "Повернутися на головну",
    SELECT_DISCIPLINE: "Discipline",
    ACCEPT: "Прийняти",
    DENY: "Відхилити",
    OTG_GO_TO_WEBSITE: "ПЕРЕЙТИ ДО САЙТУ",
    OTG_WATCH_TRAILER: "WATCH TRAILER",
    OTG_HEADING:
      "TEARDROP ISLAND DESPERATELY NEEDS A SAVIOR. TOO BAD IT GOT YOU INSTEAD",
    OTG_DESCRIPTION:
      "Gunzilla Games презентує Off The Grid (OTG), новий шутер-блокбастер від третьої особи в жанрі Королівська Битва 2.0, події якого розгортаються у антиутопічному кіберпанк-майбутньому. Не схожа на інші ігри в жанрі, OTG має намір посприяти його розвитку, роблячи нагалос на наративній складовій та даючи гравцям можливість впливати на історію та геймплей кожного разу, коли вони приймають рішення. Особливу роль в досвіді та наративі буде грати унікальна механіка, що відноситься до ігрових предметів: гравці зможуть не тільки створювати і змінювати їх, а і обмінюватися між собою. В OTG гравці будуть битися аби вижити у таємних корпоративних війнах майбутнього. Чекайте OTG скоро на PlayStation®5, Xbox® Series X|S, а також ПК.",
    OTG_HOME_SLIDE_LEARN_MORE: "ДІЗНАТИСЯ БІЛЬШЕ",
    OTG_CINEMA_TITLE_FIRST_PART: "Ласкаво Просимо До",
    OTG_CINEMA_DESCRIPTION:
      "OTG Cinema відправляє всесвіт королівської битви 2.0 Off The Grid від Gunzilla Games прямо у видовищний світ Голлівуда. Створені, написані та зняті Ніллом Блумкампом, номінованим на Оскар режисером та CVO Gunzilla, історії OTG Cinema беруть вас у карколомну подорож до темного серця OTG. Хоча і прямо не пов’язані з сюжетом гри, ці історії дають можливість відчути, яким буде її світ до самого релізу. Чи є хоча б слід надії серед жорстокої війни корпорацій, що розгортається в OTG? Дивіться аби дізнатися!",
    OTG_CINEMA_HOME_SLIDE_OUT_NOW: "Дивіться",
    OTG_CINEMA_HOME_SLIDE_SEE_MORE: "WATCH NOW",
  },
};
