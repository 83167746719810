import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
} from "react";
import { NavLink, Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Select from "../components/Select";
import SelectRoles from "../components/SelectRoles";
import localization from "../localization";
import { titleToUrl, translation } from "../tools";
import ReactMarkdown from "react-markdown";
import Header from "../components/Header";
import Header2 from "../components/Header2";
import Footer from "../components/Footer";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import moment from "moment";
import * as _ from "underscore";
import Slider from "../components/Slider";
import Fade from "react-reveal/Fade";
import { Parallax } from "react-parallax";
import ScrollAnimation from "react-animate-on-scroll";
import paralaxBg from "../assets/images/ForCareerPage.png";
import MetaTags from "react-meta-tags";
import Checkbox from "../components/Checkbox";
import FlipMove from "react-flip-move";
import ApplyForm from "../components/ApplyForm";
import Page404Content from "../components/Page404Content";
import * as actions from "../shared/actions";
import store from "../shared/store";
import { Loader } from "../components/Loader";
const tempUrl = "";

function CareerPage(props) {
  useEffect(() => {
    store.dispatch(actions.fetchCareersPage());
  }, []);

  console.log(props);
  const [showForm, setShowForm] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const [childrenHeight, setChildrenHeight] = useState(0);

  const containerRef = useRef();
  const child1Ref = useRef();
  const child2Ref = useRef();

  // console.log(props);

  useEffect(() => {
    /*
            set conainer height according to heigiest children
            set smaller children height same as heighes
            oncluck move container up/down by 50%
        */
    if (
      !child1Ref?.current?.scrollHeight ||
      !child2Ref?.current?.scrollHeight ||
      !containerRef?.current?.scrollHeight
    ) {
    } else {
      let ch = containerRef.current.scrollHeight;
      let c1h = child1Ref.current.scrollHeight;
      let c2h = child2Ref.current.scrollHeight;

      if (c1h > c2h) {
        containerRef.current.style.height = c1h + "px";
        child1Ref.current.style.height = c1h + "px";
        child2Ref.current.style.height = c1h + "px";
      } else {
        containerRef.current.style.height = c2h + "px";
        child1Ref.current.style.height = c2h + "px";
        child2Ref.current.style.height = c2h + "px";
      }
    }
  });

  let {
    vacancies,
    homePage,
    perks,
    link_linkedin,
    lang,
    locationList,
    careersPage,
    lever_id,
    fontsLoadingCheck,
  } = props;
  const { slug } = useParams();
  let vacancyIndex = null;

  if (vacancies && vacancies.length) {
    vacancies = vacancies.filter((vacancy) => vacancy.active == true);

    vacancies.forEach((element, index) => {
      if (element.id + titleToUrl(element.title) == slug) vacancyIndex = index;
    });
  }

  if (vacancyIndex !== null) {
    return (
      <React.Fragment>
        <Loader loaded={fontsLoadingCheck} />
        <VacancyPage
          lang={lang}
          vacancy={vacancies[vacancyIndex]}
          vacancies={vacancies}
          opportunities={translation(homePage, "job_opportunities", lang)}
          recrutingProcess={translation(homePage, "recruting_process", lang)}
          link_linkedin={link_linkedin}
          locationList={locationList}
          leverApiKey={careersPage.lever_api_key}
        />
      </React.Fragment>
    );
  } else if (slug && !vacancyIndex) {
    return (
      <React.Fragment>
        <Header />
        <Page404Content />
        <Footer />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Header />
        <MetaTags>
          {careersPage.meta_title ? (
            <>
              <title>{translation(careersPage, "meta_title", lang)}</title>
              <meta
                id="meta-title"
                name="title"
                content={translation(careersPage, "meta_title", lang)}
              />
              <meta
                id="og-title"
                property="og:title"
                content={translation(careersPage, "meta_title", lang)}
              />
              <meta
                id="twitter-title"
                property={translation(careersPage, "meta_title", lang)}
              />
            </>
          ) : (
            <>
              <title>{translation(careersPage, "title", lang)}</title>
              <meta
                id="meta-title"
                name="title"
                content={translation(careersPage, "title", lang)}
              />
              <meta
                id="og-title"
                property="og:title"
                content={translation(careersPage, "title", lang)}
              />
              <meta
                id="twitter-title"
                property={translation(careersPage, "title", lang)}
              />
            </>
          )}
          {careersPage.meta_description ? (
            <>
              <meta
                id="meta-description"
                name="description"
                content={translation(careersPage, "meta_description", lang)}
              />
              <meta
                id="og-description"
                property="og:description"
                content={translation(careersPage, "meta_description", lang)}
              />
              <meta
                id="twitter-description"
                property={translation(careersPage, "meta_description", lang)}
              />
            </>
          ) : (
            <>
              <meta
                id="meta-description"
                name="description"
                content={translation(careersPage, "title", lang)}
              />
              <meta
                id="og-description"
                property="og:description"
                content={translation(careersPage, "title", lang)}
              />
              <meta
                id="twitter-description"
                property={translation(careersPage, "title", lang)}
              />
            </>
          )}
          <link rel="canonical" />
          <meta id="og-url" property="og:url" content={window.location.href} />
          <meta
            id="twitter-url"
            property="twitter:url"
            content={window.location.href}
          />
        </MetaTags>
        {vacancies.length > 0 && locationList.length > 0 && (
          <VacanciesGrid
            lang={lang}
            vacancies={vacancies}
            careerContent={translation(homePage, "career_content", lang)}
            perks={perks}
            perksTitle={translation(homePage, "perks_title", lang)}
            locationList={locationList}
          />
        )}
        <Loader loaded={fontsLoadingCheck} />
        <section className="didntfind">
          <Parallax bgImage={paralaxBg} strength={400}>
            <div className="container-wrapper" ref={containerRef}>
              <div className={`inter-wrapper ${showForm}`}>
                <div className="p60" ref={child1Ref}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 newsletter-container didnt-find">
                        <ScrollAnimation
                          className="jc-center"
                          animateIn="animate__fadeInUp"
                          animateOut="animate__fadeOutDown"
                          animateOnce={true}
                          animatePreScroll={true}
                          duration={1}
                        >
                          <ReactMarkdown
                            className={`d`}
                            source={translation(
                              homePage,
                              "didnt_find_your_job",
                              lang
                            )}
                            escapeHtml={false}
                          />
                          <div
                            className="button"
                            onClick={() => {
                              setShowForm(true);
                            }}
                          >
                            {localization[lang].APPLY_HERE}
                          </div>
                        </ScrollAnimation>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dark-popup-bg">
                  <div className="p60">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <div className="vacancy-additional"></div>
                        </div>
                        <div
                          className="col-12 form-holder-horizontal"
                          ref={child2Ref}
                        >
                          <div
                            className="close-white"
                            onClick={() => {
                              setShowForm(false);
                            }}
                          ></div>
                          <ApplyForm jdId={lever_id} lang={lang} />
                        </div>
                        <div className="col-12">
                          <div className="vacancy-additional"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

// ------------------- TOOLS ---------------------------
// vacancy filtering
const filterVacancies = (vacancies, location, role, search, lang) => {
  let _vacancies = vacancies;

  if (search.length > 2) {
    _vacancies = _vacancies.filter((vacancy) => {
      return (
        String(translation(vacancy, "title", lang, "1"))
          .toLowerCase()
          .indexOf(String(search).toLowerCase()) >= 0
      );
    });
  }

  if (role !== localization[lang].ALL) {
    _vacancies = _vacancies.filter((vacancy) => {
      return translation(vacancy.jd_role, "title", lang, "2") == role;
    });
  }

  if (location !== localization[lang].ALL) {
    _vacancies = _vacancies.filter((vacancy) => {
      let result = false;
      vacancy.location_2s.forEach((loc) => {
        if (translation(loc, "title_short", lang, "3") === location)
          result = true;
      });
      return result;
    });
  }
  return _vacancies;
};

const filterVacanciesMulti = (
  vacancies,
  locations,
  roles,
  search,
  levels,
  lang
) => {
  let _vacancies = vacancies;
  let filterByRoles = [];
  let filterByLocations = [];
  let filterByLevels = [];
  /*
            1. create filter list
            2. delete if not in the list
        */
  locations.forEach((loc) => {
    if (loc.checked) filterByLocations.push(loc.title);
  });
  roles.forEach((role) => {
    if (role.checked) filterByRoles.push(role.title);
  });
  levels.forEach((level) => {
    if (level.checked) filterByLevels.push(level.title);
  });

  // console.log(levels);

  if (search.length > 2) {
    _vacancies = _vacancies.filter((vacancy) => {
      return (
        String(translation(vacancy, "title", lang, "4"))
          .toLowerCase()
          .indexOf(String(search).toLowerCase()) >= 0
      );
    });
  }

  if (filterByRoles.length > 0) {
    _vacancies = _vacancies.filter((vac) => {
      let result = false;

      filterByRoles.forEach((roleTitle) => {
        if (translation(vac.jd_role, "title", lang, "5") === roleTitle)
          result = true;
      });

      return result;
    });
  }

  if (filterByLocations.length > 0) {
    _vacancies = _vacancies.filter((vac) => {
      let result = false;

      vac.location_2s.forEach((vacancyLocation) => {
        filterByLocations.forEach((filterLocation) => {
          if (
            translation(vacancyLocation, "title_short", lang, "6") ===
            filterLocation
          )
            result = true;
        });
      });

      return result;
    });
  }

  if (filterByLevels.length > 0) {
    _vacancies = _vacancies.filter((vac) => {
      let result = false;

      filterByLevels.forEach((level) => {
        if (
          vac.jd_expertise_level &&
          translation(vac.jd_expertise_level, "title", lang, "7") === level
        )
          result = true;
      });

      return result;
    });
  }
  // console.log(_vacancies);
  return _vacancies;
};

const extractLocationsAndRoles = function (
  vacancies,
  lang,
  locationList,
  addAll = true
) {
  let _locations = []; // for location extracting
  let _roles = []; // for roles extracting
  let _expertiseLevels = []; // for expertise levels
  let locations = []; // final {title, count}
  let roles = []; // final
  let expertiseLevels = []; // final

  // extract roles and locations from the list of vacamcies
  if (vacancies.length && vacancies.length > 0) {
    vacancies.forEach((vacancy) => {
      vacancy.location_2s.forEach((location) => {
        _locations.push(translation(location, "title_short", lang, "8"));
      });
      _roles.push(translation(vacancy.jd_role, "title", lang));

      if (vacancy.jd_expertise_level) {
        _expertiseLevels.push(
          translation(vacancy.jd_expertise_level, "title", lang, "9") +
            `:${vacancy.jd_expertise_level.order}`
        );
      }
    });
  }

  let locationsMerged = Array.from(new Set(_locations));
  let rolesMerged = Array.from(new Set(_roles));
  let expertiseLevelsMerged = Array.from(new Set(_expertiseLevels));

  // count locations
  locationsMerged.forEach((lm) => {
    let locationToPush = { title: lm, count: 0, checked: false };

    _locations.forEach((_l) => {
      if (lm === _l) locationToPush.count++;
    });

    locations.push(locationToPush);
  });

  rolesMerged.forEach((rm) => {
    let roleToPush = { title: rm, count: 0, checked: false };

    _roles.forEach((_r) => {
      if (rm === _r) roleToPush.count++;
    });

    roles.push(roleToPush);
  });

  expertiseLevelsMerged.forEach((em) => {
    let expertiseLevelToPush = {
      title: em.split(":")[0],
      count: 0,
      checked: false,
      order: +em.split(":")[1],
    };

    _expertiseLevels.forEach((_expLvl) => {
      if (em === _expLvl) expertiseLevelToPush.count++;
    });

    expertiseLevels.push(expertiseLevelToPush);
  });

  // sorting
  locations.sort((value1, value2) => {
    return "" + value1.title.localeCompare(value2.title);
  });
  roles.sort((value1, value2) => {
    return "" + value1.title.localeCompare(value2.title);
  });

  expertiseLevels.sort((value1, value2) => {
    return value1.order - value2.order;
  });

  // adding missing locations
  // adding "all" oprion

  if (locationList.length) {
    locationList.forEach((original) => {
      let shouldAdd = true;
      locations.forEach((extracted) => {
        if (
          translation(original, "title_short", lang, "10") == extracted.title
        ) {
          shouldAdd = false;
        }
      });

      if (shouldAdd) {
        locations.push({
          title: translation(original, "title_short", lang, "11"),
          count: 0,
          checked: false,
        });
      }
    });
  }

  if (addAll) {
    locations.unshift({
      title: localization[lang].ALL,
      count: 0, // vacancies.length
    });
    roles.unshift({
      title: localization[lang].ALL,
      count: vacancies.length,
    });
  }

  // attaching translation

  return [locations, roles, expertiseLevels];
};
// ----------------- END TOOLS --------------------

// All vacancies with filter
const VacanciesGrid = (props) => {
  const { vacancies, careerContent, perks, perksTitle, lang, locationList } =
    props;
  const [page, setPage] = useState(1);
  const [flocation, setFlocation] = useState(localization[lang].ALL);
  const [frole, setFrole] = useState(localization[lang].ALL);
  const [search, setSearch] = useState(""); // search query from input
  const [isFilter, setIsFilter] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [disabledCheckboxes, setDisabledCheckboxes] = useState(false);

  const [isSticky, setIsSticky] = useState(false);
  const refStickyBlock = React.createRef();
  const [up, setUp] = useState(false);
  const upRef = useRef(0);

  const scrollHandler = (e) => {
    const windowYPos = window.scrollY;
    const scrollingUp = upRef.current > windowYPos;
    if (scrollingUp && !up) {
      setUp(true);
    } else if (!scrollingUp && up) {
      setUp(false);
    }
    upRef.current = windowYPos;
  };

  const handleChangeLocation = (location) => {
    // console.log(flocation);
    // console.log(location);
    setFlocation(location);
  };

  const handleChangeRole = (role) => setFrole(role);

  const handleSearchInput = (e) => setSearch(e.target.value);
  const clearHandler = (e) => setSearch("");

  const [locations, roles, expertiseLevel] = extractLocationsAndRoles(
    vacancies,
    lang,
    locationList,
    false
  ); // last param means do not add All in the list

  const [locationsState, setLocationsState] = useState(locations);
  const [rolesState, setRolesState] = useState(roles);
  const [expertiseLevelState, setExpertiseLevelState] =
    useState(expertiseLevel);

  let vacanciesToShow = filterVacanciesMulti(
    vacancies,
    locationsState,
    rolesState,
    search,
    expertiseLevelState,
    lang
  );
  /* SORTING BY opening_date */
  if (vacanciesToShow.sort) {
    vacanciesToShow = vacanciesToShow.sort(
      (value1, value2) =>
        "" + value2.opening_date.localeCompare(value1.opening_date)
    );
  }

  const resetFilters = () => {
    let newRoles = Array.from(rolesState);
    let newLocations = Array.from(locationsState);
    let newExportiseLevels = Array.from(expertiseLevelState);

    newRoles.forEach((rol, index, sourceArray) => {
      if (rol.checked) sourceArray[index].checked = false;
    });

    newLocations.forEach((loc, index, sourceArray) => {
      if (loc.checked) sourceArray[index].checked = false;
    });

    newExportiseLevels.forEach((lvl, index, sourceArray) => {
      if (lvl.checked) sourceArray[index].checked = false;
    });

    setRolesState(newRoles);
    setLocationsState(newLocations);
    setExpertiseLevelState(newExportiseLevels);
    setIsFilter(false);
    // setShowFilter(false);
  };

  const isChecked = (arrayToCheck) => {
    let result = false;
    arrayToCheck.forEach((element) => {
      if (element.checked) result = true;
    });
    return result;
  };

  const toggleCheckboxDisabling = () => {
    setDisabledCheckboxes(true);
    setTimeout(() => setDisabledCheckboxes(false), 600);
  };

  const handleRole = (index) => {
    toggleCheckboxDisabling();
    let newState = Array.from(rolesState);
    newState[index].checked = !newState[index].checked;
    setRolesState(newState);
    if (isChecked(newState)) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
    // console.log(newState);
  };
  const handleLocation = (index) => {
    toggleCheckboxDisabling();
    let newState = Array.from(locationsState);
    newState[index].checked = !newState[index].checked;
    setLocationsState(newState);
    if (isChecked(newState)) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
    // console.log(newState);
  };

  const handleExperienceLevel = (index) => {
    toggleCheckboxDisabling();
    let newState = Array.from(expertiseLevelState);
    newState[index].checked = !newState[index].checked;
    setExpertiseLevelState(newState);
    if (isChecked(newState)) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
    // console.log(newState);
  };

  const handleFilterShow = (e) => {
    if (showFilter) {
      // document.body.style.overflow = "unset";
      const scrollY = -parseInt(document.body.style.top);
      document.body.classList.remove("popup-is-opened");
      window.scrollTo(0, scrollY);
      setShowFilter(false);
      const divGridTop = document.getElementById("vacancies-grid-top");
      if (divGridTop && divGridTop.getBoundingClientRect) {
        window.scrollTo(0, 730);
      }
    } else {
      setShowFilter(true);
      document.body.style.top = `-${window.scrollY}px`;
      document.body.classList.add("popup-is-opened");
    }
  };

  // pagination begin
  const limit = 12;
  const maxPages = Math.ceil(vacanciesToShow.length / limit);

  if (page > maxPages && maxPages > 0) {
    setPage(maxPages);
  }

  // vacanciesToShow = vacanciesToShow.slice( (page-1)* limit, (page) * limit  );
  // pagination end

  const pagination = () => {
    let buttons = [];
    for (let index = 1; index <= maxPages; index++) {
      buttons.push(
        <div
          className={`page ${page == index ? "current" : ""}`}
          key={index}
          onClick={() => {
            setPage(index);
          }}
        >
          {index}
        </div>
      );
    }
    return buttons;
  };

  useEffect(() => {
    if (window.innerWidth && window.innerWidth > 992) {
      const divFilters = document.getElementById("vacancies-filters");
      const divGrid = document.getElementById("vacancies-grid");
      const divGridTop = document.getElementById("vacancies-grid-top");
      const divGridTopY = divGridTop.getBoundingClientRect
        ? divGridTop.getBoundingClientRect().top
        : 0;

      if (
        divGrid &&
        divFilters &&
        divFilters.clientHeight > divGrid.clientHeight - 200 &&
        divGridTopY < 200
      ) {
        window.scrollTo(0, 394);
      }
    }

    window.addEventListener("scroll", scrollHandler);

    const cachedRef = refStickyBlock.current;
    const observer = new IntersectionObserver(
      ([e]) => setIsSticky(e.intersectionRatio < 1),
      { threshold: [1] }
    );

    observer.observe(cachedRef);
    return () => {
      observer.unobserve(cachedRef);
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [locationsState, rolesState, expertiseLevelState, isFilter, up]);

  let perksPart1 = [];

  let perksPart2 = [];

  if (perks) {
    perksPart1 = Array.from(perks).splice(0, Math.ceil(perks.length / 2));
    perksPart2 = Array.from(perks).splice(
      Math.ceil(perks.length / 2),
      perks.length
    );
  }

  return (
    <section className="career">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>{localization[lang].PAGE_TITLE_CAREERS}</h1>
          </div>
        </div>
        <div className="row">
          <ScrollAnimation
            className="col-lg-6"
            animateIn="animate__fadeInUp"
            animateOut="animate__fadeOuDown"
            animateOnce={true}
          >
            <ReactMarkdown
              className={`career-content`}
              source={careerContent}
              escapeHtml={false}
            />
          </ScrollAnimation>
          <ScrollAnimation
            className="col-lg-6"
            animateIn="animate__fadeInRight"
            animateOut="animate__fadeOutLeft"
            animateOnce={true}
            delay={500}
            duration={1}
          >
            <h3 className="perks-header">{perksTitle && perksTitle}</h3>
            <ul className="home-perks">
              <div>
                {perksPart1
                  ? perksPart1.map((perk, index) => (
                      <Perk
                        perk={perk}
                        index={index + 1}
                        key={index}
                        lang={lang}
                      />
                    ))
                  : ""}
              </div>
              <div>
                {perksPart2
                  ? perksPart2.map((perk, index) => (
                      <Perk
                        perk={perk}
                        index={index + 5}
                        key={index}
                        lang={lang}
                      />
                    ))
                  : ""}
              </div>
            </ul>
          </ScrollAnimation>
        </div>
        <div className="row">
          <div className="col-lg-6 vc-filters">
            <div className="vacancy-control ua">
              <div className="vacancy-search">
                <input
                  type="text"
                  placeholder={localization[lang].SEARCH_CAREERS_PLACEHOLDER}
                  value={search}
                  onChange={handleSearchInput}
                />
                <div
                  className={`button-clear ${search ? "true" : "false"}`}
                  onClick={clearHandler}
                ></div>
              </div>
              {/*
                            <Select options={locations} height={51} cb={handleChangeLocation} label={localization[lang].SELECT_LOCATION} />
                            <SelectRoles options={roles} height={51} cb={handleChangeRole} label={localization[lang].SELECT_ROLE} />
                            */}
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className={`col col-12 col-lg-3 sticky-blcok ${isSticky} show-${showFilter}`}
            ref={refStickyBlock}
          >
            <div
              className="jd-filters hide-mobile hide-tablet"
              id="vacancies-filters"
            >
              {locationsState ? (
                <div className="filter-group">
                  <h4>{localization[lang].SELECT_LOCATION}</h4>
                  {locationsState.map((element, index) => (
                    <Fade delay={index * 120} key={index}>
                      <Checkbox
                        checked={element.checked}
                        callback={() => handleLocation(index)}
                        disabled={disabledCheckboxes}
                        widthLimitation
                      >
                        {element.title} ({element.count})
                      </Checkbox>
                    </Fade>
                  ))}
                </div>
              ) : (
                ""
              )}
              {rolesState ? (
                <div className="filter-group">
                  <h4>{localization[lang].SELECT_ROLE}</h4>
                  {rolesState.map((element, index) => (
                    <Fade delay={index * 120} key={index}>
                      <Checkbox
                        checked={element.checked}
                        callback={() => handleRole(index)}
                        disabled={disabledCheckboxes}
                        widthLimitation
                      >
                        {element.title} ({element.count})
                      </Checkbox>
                    </Fade>
                  ))}
                </div>
              ) : (
                ""
              )}
              {expertiseLevelState && expertiseLevelState.length > 0 ? (
                <div className="filter-group">
                  <h4>{localization[lang].EXPERTISE_LEVEL}</h4>
                  {expertiseLevelState.map((element, index) => (
                    <Fade delay={index * 120} key={index}>
                      <Checkbox
                        checked={element.checked}
                        callback={() => handleExperienceLevel(index)}
                        disabled={disabledCheckboxes}
                        widthLimitation
                      >
                        {element.title} ({element.count})
                      </Checkbox>
                    </Fade>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className={`show-filters ${isFilter} up-${up}`}
              onClick={handleFilterShow}
            >
              {localization[lang].BUTTON_FILTERS}
            </div>
            <div className={`popup popup-filter ${showFilter}`}>
              <div className="popup-top">
                <div className="title">{localization[lang].BUTTON_FILTERS}</div>
                <div
                  className={`clear_all ${isFilter} `}
                  onClick={resetFilters}
                >
                  {localization[lang].BUTTON_FILTERS_CLEAR}
                </div>
                <div className="close" onClick={handleFilterShow}></div>
              </div>
              <div className={`jd-filters-popup popup-content`}>
                {locationsState ? (
                  <div className="filter-group">
                    <h4>{localization[lang].SELECT_LOCATION}</h4>
                    {locationsState.map((element, index) => (
                      <Fade delay={index * 120} key={index}>
                        <Checkbox
                          checked={element.checked}
                          callback={() => handleLocation(index)}
                          disabled={disabledCheckboxes}
                        >
                          {element.title} ({element.count})
                        </Checkbox>
                      </Fade>
                    ))}
                  </div>
                ) : (
                  ""
                )}
                {rolesState ? (
                  <div className="filter-group">
                    <h4>{localization[lang].SELECT_ROLE}</h4>
                    {rolesState.map((element, index) => (
                      <Fade delay={index * 120} key={index}>
                        <Checkbox
                          checked={element.checked}
                          callback={() => handleRole(index)}
                          disabled={disabledCheckboxes}
                        >
                          {element.title} ({element.count})
                        </Checkbox>
                      </Fade>
                    ))}
                  </div>
                ) : (
                  ""
                )}
                {expertiseLevelState ? (
                  <div className="filter-group">
                    <h4>{localization[lang].EXPERTISE_LEVEL}</h4>
                    {expertiseLevelState.map((element, index) => {
                      // console.log(element);
                      return (
                        <Fade delay={index * 120} key={index}>
                          <Checkbox
                            checked={element.checked}
                            callback={() => handleExperienceLevel(index)}
                            disabled={disabledCheckboxes}
                          >
                            {element.title} ({element.count})
                          </Checkbox>
                        </Fade>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="popup-bottom">
                <div
                  className="button"
                  style={{ width: "100%" }}
                  onClick={handleFilterShow}
                >
                  {localization[lang].BUTTON_FILTERS_APPLY}{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col col-12 col-lg-9 overflow-hidden">
            <Grid lang={lang} vacanciesToShow={vacanciesToShow} />
          </div>
        </div>
        <div className="pagination">
          {/* maxPages > 1 ? pagination() : "" */}
        </div>
      </div>
    </section>
  );
};

class Grid extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.vacanciesToShow.length !== this.props.vacanciesToShow.length
    );
  }
  render() {
    // console.log("vacanciesToShow.length: ", this.props.vacanciesToShow.length);
    return (
      <>
        <div id="vacancies-grid-top"></div>
        {this.props.vacanciesToShow.length ? (
          <div className="vacancies-grid" id="vacancies-grid">
            <FlipMove
              typeName={null}
              easing="ease"
              duration={500}
              delay={0}
              staggerDurationBy={0}
              staggerDelayBy={0}
              appearAnimation="accordionVertical"
              enterAnimation="fade"
              leaveAnimation="fade"
            >
              {this.props.vacanciesToShow.map((element, index) => {
                // console.log("ew"+element.id+"fr"+element.title)
                return (
                  <div key={"ew" + element.id + "fr" + element.title}>
                    <Vacancy lang={this.props.lang} element={element} />
                  </div>
                );
              })}
            </FlipMove>
          </div>
        ) : (
          <div className="vacancy-not-found" id="vacancies-grid">
            <div className="holder">
              {localization[this.props.lang].VACANCIES_NOT_FOUND}
            </div>
          </div>
        )}
      </>
    );
  }
}

// JD page
const VacancyPage = (props) => {
  // Single job description page
  const {
    vacancy,
    vacancies,
    opportunities,
    recrutingProcess,
    link_linkedin,
    lang,
    locationList,
    leverApiKey,
  } = props;

  const [locations, roles] = extractLocationsAndRoles(
    vacancies,
    lang,
    locationList
  );

  let [flocation, setFlocation] = useState(localization[lang].ALL);
  let [frole, setFrole] = useState(localization[lang].ALL);

  const handleChangeLocation = (location) => setFlocation(location);
  const handleChangeRole = (role) => setFrole(role);

  let vacanciesToShow = filterVacancies(vacancies, flocation, frole, "", lang);

  // SORTING BY opening_date
  vacanciesToShow = vacanciesToShow.sort(
    (value1, value2) =>
      "" + value2.opening_date.localeCompare(value1.opening_date)
  );

  vacanciesToShow.forEach((value, index, arr) => {
    if (value.jd_role.id == vacancy.jd_role.id) {
      vacanciesToShow.unshift(...arr.splice(index, 1));
    }
  });

  vacanciesToShow = vacanciesToShow.filter((value) => value.id !== vacancy.id);

  const shareUrl = window.location.href;

  const handleApplyButton = (e) => {
    /*
        window.ga('send', 'event', {
            eventCategory: 'Apply button',
            eventAction: 'click',
            eventLabel:  window.location.pathname
        });
        */
  };

  return (
    <React.Fragment>
      <Header />
      <section className="vacancy-page">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 first-column">
              <MetaTags>
                {vacancy.meta_title ? (
                  <>
                    <title>{translation(vacancy, "meta_title", lang)}</title>
                    <meta
                      id="meta-title"
                      name="title"
                      content={translation(vacancy, "meta_title", lang)}
                    />
                    <meta
                      id="og-title"
                      property="og:title"
                      content={
                        translation(vacancy, "meta_title", lang) + " - Join Us!"
                      }
                    />
                    <meta
                      id="twitter-title"
                      property="twitter:title"
                      content={
                        translation(vacancy, "meta_title", lang) + " - Join Us!"
                      }
                    />
                  </>
                ) : (
                  <>
                    <title>{translation(vacancy, "title", lang)}</title>
                    <meta
                      id="meta-title"
                      name="title"
                      content={translation(vacancy, "title", lang)}
                    />
                    <meta
                      id="og-title"
                      property="og:title"
                      content={
                        translation(vacancy, "title", lang) + " - Join Us!"
                      }
                    />
                    <meta
                      id="twitter-title"
                      property="twitter:title"
                      content={
                        translation(vacancy, "title", lang) + " - Join Us!"
                      }
                    />
                  </>
                )}
                {vacancy.meta_description ? (
                  <>
                    <meta
                      id="meta-description"
                      name="description"
                      content={translation(vacancy, "meta_description", lang)}
                    />
                    <meta
                      id="og-description"
                      property="og:description"
                      content={translation(vacancy, "meta_description", lang)}
                    />
                    <meta
                      id="twitter-description"
                      property="twitter:description"
                      content={translation(vacancy, "meta_description", lang)}
                    />
                  </>
                ) : (
                  <>
                    <meta
                      id="meta-description"
                      name="description"
                      content={translation(vacancy, "title", lang)}
                    />
                    <meta
                      property="og:description"
                      content={translation(vacancy, "title", lang)}
                    />
                    <meta
                      id="twitter-description"
                      property="twitter:description"
                      content={translation(vacancy, "title", lang)}
                    />
                  </>
                )}
                {vacancy.preview ? (
                  <>
                    <meta
                      id="og-image"
                      property="og:image"
                      content={
                        window.location.origin + "/api" + vacancy.preview.url
                      }
                    />
                    <meta
                      id="twitter-image"
                      property="twitter:image"
                      content={
                        window.location.origin + "/api" + vacancy.preview.url
                      }
                    />
                  </>
                ) : (
                  <>
                    <meta
                      id="og-image"
                      property="og:image"
                      content={
                        window.location.origin +
                        "/Gunzilla-Hiring-Share-Preview.jpg"
                      }
                    />
                    <meta
                      id="twitter-image"
                      property="twitter:image"
                      content={
                        window.location.origin +
                        "/Gunzilla-Hiring-Share-Preview.jpg"
                      }
                    />
                  </>
                )}
                <meta
                  id="og-url"
                  property="og:url"
                  content={window.location.href}
                />
                <meta
                  id="twitter-url"
                  property="twitter:url"
                  content={window.location.href}
                />
                <link rel="canonical" />
              </MetaTags>
              <h1>{translation(vacancy, "title", lang)}</h1>
              <div className="hide-desktop">
                <div className="fields">
                  {/*lang === "ua" ? 
                                        <div className="field date">{localization[lang].POSTED}: {moment(vacancy.opening_date).format("D.MM.YYYY")}</div>
                                    :
                                        <div className="field date">{localization[lang].POSTED}: {moment(vacancy.opening_date).format("D MMM, YYYY")}</div>
                                    */}
                  <div className="field location">
                    <ShowLocations
                      locations={vacancy.location_2s}
                      lang={lang}
                    />
                  </div>
                  <div className="field job-department">
                    {translation(vacancy.jd_role, "title", lang)}
                  </div>
                  <div className="field job-type">
                    {localization[lang].FULL_TIME}
                  </div>
                </div>
                <div
                  className="button link-button"
                  onClick={() => {
                    document.getElementById("resume-form").scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }}
                >
                  {localization[lang].APPLY_HERE}
                </div>
              </div>

              <ReactMarkdown
                className={`text`}
                source={translation(vacancy, "content", lang)}
                escapeHtml={false}
              />
              {/* vacancy.peopleforce_link && 
                                <a target="_blank" href={`mailto:job@gunzillagemes.com`} onClick={handleApplyButton}>
                                    <div className="button button2">{localization[lang].APPLY_HERE}</div>
                                </a>
                            */}
              <br />
              <div className="share-buttons">
                <div className="conteiner">
                  <div className="title">{localization[lang].TITLE_SHARE}:</div>
                  <div className="icons">
                    <FacebookShareButton url={shareUrl}>
                      <div className="facebook"></div>
                    </FacebookShareButton>

                    <TwitterShareButton url={shareUrl}>
                      <div className="twitter"></div>
                    </TwitterShareButton>

                    <a
                      className="react-share__ShareButton"
                      rel="noopener noreferrer"
                      target="_blanck"
                      href={
                        "https://www.linkedin.com/shareArticle?mini=true&url=" +
                        shareUrl
                      }
                    >
                      <div className="linkedin"></div>
                    </a>

                    <EmailShareButton url={shareUrl}>
                      <div className="email"></div>
                    </EmailShareButton>
                  </div>
                </div>
              </div>
              {/*
                            <NavLink to={`/${lang}/careers`} className="link mgb">{localization[lang].DISCOVER_MORE_VACANCIES}</NavLink>
                             */}
            </div>
            <div className="col-12 offset-lg-1 col-lg-3 right-column hide-tablet hide-mobile">
              <div
                className="button link-button"
                onClick={() => {
                  document.getElementById("resume-form").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
              >
                {localization[lang].APPLY_HERE}
              </div>

              <div className="share-buttons">
                <div className="conteiner">
                  <div className="title">
                    <div className="">{localization[lang].TITLE_SHARE}</div>
                  </div>
                  <div className="icons">
                    <FacebookShareButton url={shareUrl}>
                      <div className="facebook"></div>
                    </FacebookShareButton>

                    <TwitterShareButton url={shareUrl}>
                      <div className="twitter"></div>
                    </TwitterShareButton>

                    <LinkedinShareButton url={shareUrl}>
                      <div className="linkedin"></div>
                    </LinkedinShareButton>

                    <EmailShareButton url={shareUrl}>
                      <div className="email"></div>
                    </EmailShareButton>
                  </div>
                </div>
              </div>

              <div className="fields">
                {/*lang === "ua" ? 
                                    <div className="field date">{localization[lang].POSTED}: {moment(vacancy.opening_date).format("D.MM.YYYY")}</div>
                                :
                                    <div className="field date">{localization[lang].POSTED}: {moment(vacancy.opening_date).format("D MMM, YYYY")}</div>
                                */}
                <div className="field location">
                  <ShowLocations locations={vacancy.location_2s} lang={lang} />
                </div>
                <div className="field job-department">
                  {translation(vacancy.jd_role, "title", lang)}
                </div>
                <div className="field job-type">
                  {localization[lang].FULL_TIME}
                </div>
              </div>

              <div className="more-vacancies">
                <h3>{localization[lang].MORE_VACANCIES}</h3>
                <div className="list">
                  {/* 
                                    <Select options={locations} height={51} cb={handleChangeLocation} label={localization[lang].SELECT_LOCATION} lang={lang} />
                                    <SelectRoles options={roles} height={51} cb={handleChangeRole} label={localization[lang].SELECT_ROLE} lang={lang} />
                                    */}
                  <Slider cols={1} rows={3} gap={20}>
                    {vacanciesToShow.map((element, index) => {
                      return (
                        <Fade delay={index * 100} key={element.id}>
                          <Vacancy
                            lang={lang}
                            element={element}
                            index={index}
                            key={element.id}
                          />
                        </Fade>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div class="vacancy-additional"></div>
            </div>
          </div>

          <ApplyForm
            jdId={vacancy.lever_id}
            leverApiKey={leverApiKey}
            lang={lang}
          />

          <div className="row">
            <div className="col-md-12 hide-desktop">
              <div className="more-vacancies">
                <h3>{localization[lang].MORE_VACANCIES}</h3>
                <div className="list">
                  {/* 
                                    <Select options={locations} height={51} cb={handleChangeLocation} label={localization[lang].SELECT_LOCATION} lang={lang} />
                                    <SelectRoles options={roles} height={51} cb={handleChangeRole} label={localization[lang].SELECT_ROLE} lang={lang} />
                                    */}
                  <Slider cols={1} rows={3} gap={20}>
                    {vacanciesToShow.map((element, index) => {
                      return (
                        <Fade delay={index * 100} key={element.id}>
                          <Vacancy
                            lang={lang}
                            element={element}
                            index={index}
                            key={element.id}
                          />
                        </Fade>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="vacancy-additional"></div>
              <div className="col-12 col-lg-8 padding-side-zero">
                {opportunities ? (
                  <ReactMarkdown
                    className={`text`}
                    source={opportunities}
                    escapeHtml={false}
                  />
                ) : (
                  ""
                )}
                {recrutingProcess ? (
                  <ReactMarkdown
                    className={`text`}
                    source={recrutingProcess}
                    escapeHtml={false}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

const Perk = (props) => {
  const { perk, lang, index } = props;
  const toolTipRef = useRef();
  const fixPossition = (e) => {
    e.persist();
    if (
      e.target &&
      e.target.clientHeight &&
      e.target.classList.contains("perk")
    ) {
      toolTipRef.current.style.top =
        -toolTipRef.current.clientHeight - 15 + "px";
    }
  };
  return (
    <li
      className={`perk liperk${index}`}
      key={perk.id}
      onMouseEnter={fixPossition}
    >
      <div className="perk-tooltip" ref={toolTipRef}>
        {translation(perk, "description", lang)}
      </div>
      <div className="perk">{translation(perk, "title", lang)}</div>
      <div className="perk-hint" />
    </li>
  );
};

const Vacancy = forwardRef((props, ref) => {
  const { lang } = props;
  const { title, id, opening_date, location_2s, jd_role } = props.element;
  const newDays = 10;
  const isNew = -moment(opening_date).diff(new Date(), "days") < newDays;

  return (
    <div ref={ref} className="vacancy-card-holder flip-box">
      <div className="flip-box-inner">
        <NavLink
          to={`/${lang}/careers/` + id + titleToUrl(title)}
          className="vacancy-card flip-box-front"
        >
          <div className="title">
            {translation(props.element, "title", lang)}
          </div>
          <div className="location">
            <ShowLocations divider=" | " locations={location_2s} lang={lang} />
            {/*lang === "ua" ? 
                            <div className="date date-location">{moment(opening_date).format("D.MM.YYYY")}</div>
                        :
                            <div className="date date-location">{moment(opening_date).format("D MMM, YYYY")}</div>
                        */}
          </div>
          <div className="location">
            <span className="card-role">
              <span>{jd_role.title}</span>
            </span>
          </div>
          {isNew && (
            <div className="label-new">{localization[lang].LABEL_NEW}</div>
          )}
        </NavLink>
        <NavLink
          to={`/${lang}/careers/` + id + titleToUrl(title)}
          className="vacancy-card hover flip-box-back"
        >
          <div className="title">
            {translation(props.element, "title", lang)}
          </div>
          <div className="location">
            <ShowLocations divider=" | " locations={location_2s} lang={lang} />
            {/*lang === "ua" ? 
                            <div className="date date-location">{moment(opening_date).format("D.MM.YYYY")}</div>
                        :
                            <div className="date date-location">{moment(opening_date).format("D MMM, YYYY")}</div>
                        */}
          </div>
          <div className="location">
            <span className="card-role">
              <span>{jd_role.title}</span>
            </span>
          </div>
          {isNew && (
            <div className="label-new">{localization[lang].LABEL_NEW}</div>
          )}
        </NavLink>
      </div>
    </div>
  );
});

const ShowLocations = ({ locations, divider, lang }) => {
  divider = divider || "";
  return (
    <span className="card-location">
      {locations.map((loc) => {
        // console.log("loc: ", loc);
        return (
          <span className="loc" key={loc.id}>
            {translation(loc, "title_short", lang)}
            <span className="divider location-divider">{divider}</span>
          </span>
        );
      })}
    </span>
  );
};

const mapStateToProps = (state) => ({
  homePage: state.homePage,
  careersPage: state.careersPage,
  vacancies: state.vacancies,
  perks: state.homePage.career_perks,
  link_linkedin: state.homePage.link_linkedin,
  lang: state.lang,
  locationList: state.locations,
  lever_id: state.internshipPage.lever_id,
  fontsLoadingCheck: state.fontsLoadingCheck,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CareerPage);
